import './style.css';

import React, { Component } from 'react';
import Page from '../Page';
import Card from '../Card';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { DocsNav } from "../DocsNav";

class PageHome extends Component {

	render() {
		return (
			<React.Fragment>
				<div className="PageHomeAnim" />
				<DocsNav />
				<Page className="PageHome" cards={true}>
					<div style={{
						maxWidth: 600,
						margin: 'auto',
						position: 'relative',
					}}>
						<Card>
							<Typography variant="h4" gutterBottom={true}>
								Develop for Homey
							</Typography>
							<Typography variant="body2" gutterBottom={true}>
								{`Whether it's a Homey App or Web API integration that you're creating — this is the right place.`}<br /><br />

								<Button
									variant="outlined"
									href="https://homey.app"
									target="_blank"
									rel="noreferrer noopener"
								>
									Learn about Homey
									<ArrowRight />
								</Button>
							</Typography>
						</Card>

						<Grid container spacing={4}>
							<Grid item xs={12} sm={6}>
								<Card title="Apps SDK">
									<Typography variant="body2">
										Build beautiful apps that run on Homey.<br /><br />

										<Button
											variant="outlined"
											fullWidth={true}
											href="https://apps.developer.homey.app"
											target="_blank"
											rel="noreferrer noopener"
										>
											Create your first app
											<ArrowRight />
										</Button>
									</Typography>
									<a
										href="https://apps-sdk-v3.developer.homey.app/"
										target="_blank"
										rel="noreferrer noopener"
										className="ReferenceLink"
									>
										Apps SDK Reference ›
									</a>
								</Card>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Card title="Web API">
									<Typography variant="body2">
										Integrate cloud services that run elsewhere.<br /><br />

										<Button
											variant="outlined"
											fullWidth={true}
											href="https://api.developer.homey.app/"
											target="_blank"
											rel="noreferrer noopener"
										>
											Create an integration
											<ArrowRight />
										</Button>
									</Typography>
									<a
										href="https://api.developer.athom.com/"
										target="_blank"
										rel="noreferrer noopener"
										className="ReferenceLink"
									>
										Web API Reference ›
									</a>
								</Card>
							</Grid>
						</Grid>

						<Paper style={{
							backgroundColor: '#000',
							color: 'white',
							padding: 24,
							fontFamily: '"Roboto Mono", monospace',
							fontWeight: 'bold',
							fontSize: '1.1em',
							lineHeight: '1.4em',
						}} elevation={2}>
							<span style={{ color: '#666' }}># or install the command line tools:<br /></span>
							$ npm install -g homey<br />
							$ homey --help<br />
						</Paper>
					</div>
				</Page>
			</React.Fragment>
		);
	}
}

export default PageHome;
