import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import {
	withApi,
	withUser,
	withHomey,
	withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import Card from '../Card';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import semver from 'semver';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faWindows,
	faApple,
} from '@fortawesome/free-brands-svg-icons';

library.add(
	faWindows,
	faApple,
);

class PageToolsSettings extends Component {

	constructor(props) {
		super(props);

		this.state = {
			usbMode: '',
			updatesBetaEnabled: null,
			updatesBetaDialog: false,
			homeySoftwareVersion: this.props.homey.softwareVersion
				? this.props.homey.softwareVersion.split('-')[0]
				: null,
		};
	}

	componentDidMount() {
		if (this.props.homey.platformVersion === 1) {
			this.props.homey.devkit.getUsbMode().then(usbMode => {
				this.setState({ usbMode });
			}).catch(this.props.handleError);
		}

		this.props.homey.updates.getOptionChannel().then(({ value }) => {
			this.setState({
				updatesBetaEnabled: value === 'beta',
			});
		}).catch(this.props.handleError);
	}

	handleUsbMode = event => {
		const { value: usbMode } = event.target;
		this.props.homey.devkit.setUsbMode({ os: usbMode })
			.then(() => {
				this.setState({ usbMode });
				this.props.handleSuccess('USB Mode saved');
			})
			.catch(this.props.handleError);
	}

	handleOpen = () => {
		this.setState({
			updatesBetaDialog: true,
		});
	}

	handleClose = () => {
		this.setState({
			updatesBetaDialog: false,
		});
	}

	handleEnableExperimentalUpdates = () => {
		this.handleClose();

		this.props.homey.updates.setOptionChannel({
			value: 'beta'
		}).then(() => {
			this.setState({
				updatesBetaEnabled: true,
			});
		}).catch(this.props.handleError);
	}

	handleDisableExperimentalUpdates = () => {
		this.props.homey.updates.setOptionChannel({
			value: 'stable',
		}).then(() => {
			this.setState({
				updatesBetaEnabled: false,
			});
		}).catch(this.props.handleError);
	}

	render() {
		const {
			usbMode,
			updatesBetaEnabled,
			updatesBetaDialog,
			homeySoftwareVersion,
		} = this.state;

		const {
			homey,
		} = this.props;

		return (
			<Page cards={true}>
				{this.props.homey.platformVersion === 1 && (
					<Card title="USB Debugging">
						<FormControl
							margin="normal"
						>
							<Typography variant="body1">Homey can create a network interface over USB to increase development speed.<br />
								Select your Operating System below and reboot Homey afterwards.</Typography>

							<Select
								value={usbMode}
								onChange={this.handleUsbMode}
							>
								{usbMode === '' && <MenuItem value="" disabled>Loading...</MenuItem>}
								<MenuItem value="osx">
									<FontAwesomeIcon fixedWidth icon={['fab', 'apple']} />&nbsp;
									macOS & Linux
								</MenuItem>
								<MenuItem value="win">
									<FontAwesomeIcon fixedWidth icon={['fab', 'windows']} />&nbsp;
									Windows
								</MenuItem>
							</Select>
						</FormControl>
					</Card>
				)}

				<Card title="Experimental updates">
					<FormControl
						margin="normal"
					>
						<Typography variant="body1">Enable experimental updates to help test new versions of Homey before they are released to everyone.<br />
							These updates might be unstable, and after enabling you cannot revert without doing a factory reset.</Typography>

						{typeof updatesBetaEnabled === 'boolean' && <React.Fragment>

							<FormControlLabel
								control={<Switch
									onChange={updatesBetaEnabled ? this.handleDisableExperimentalUpdates : this.handleOpen}
									checked={updatesBetaEnabled}
									disabled={homeySoftwareVersion && semver.lt(homeySoftwareVersion, '4.0.0') && updatesBetaEnabled}
								/>}
								label={updatesBetaEnabled ? 'Experimental updates are enabled' : 'Enable experimental updates'}
							/>
						</React.Fragment>}

						<Dialog
							open={updatesBetaDialog}
						>
							<DialogTitle>Enable experimental updates</DialogTitle>
							<DialogContent>
								<Typography variant="body2">You are about to enable experimental updates on <strong>{homey.name}</strong>.</Typography>
								<Typography variant="body2">Enabling experimental updates might make Homey unstable, unresponsive or break existing features.<br /><br />
									{homeySoftwareVersion && semver.lt(homeySoftwareVersion, '4.0.0') && (
										<Fragment>
											{'A factory reset must be performed to return to the stable channel, which will result in loss of all data.'}
											<br />
											<br />
										</Fragment>
									)}
									Are you sure you want to continue?</Typography>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose}>
									Cancel
								</Button>
								<Button
									onClick={this.handleEnableExperimentalUpdates}
									color="primary"
								>
									Yes
								</Button>
							</DialogActions>
						</Dialog>
					</FormControl>
				</Card>
			</Page>
		);
	}
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsSettings, { version: '>=2.0.0', platforms: ['local'] })))));
