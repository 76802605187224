import './style.css';

import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import {
	withApi,
	withUser,
	withHomey,
	withMessages,
} from '../../services/AthomApi';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Page from '../Page';
import Simulator from '../Simulator';

class PageToolsAppSettings extends Component {

	constructor(props) {
		super(props);

		this.state = {
			ready: false,
			simulatorSrc: null,
			activeAppId: null,
			apps: {},
			title: 'Select an App'
		}
	}

	componentDidMount() {
		this.props.homey.apps
			.on('apps.create', this.getApps)
			.on('apps.update', this.getApps)
			.on('apps.delete', this.getApps)
			.connect()
			.catch(this.props.handleError);

		this.getApps();

		this.props.homey.baseUrl.then(baseUrl => {
			this.setState({
				baseUrl,
				apps: {}
			})
		});
	}

	componentWillUnmount() {
		if (this.props.homey) {
			this.props.homey.apps.destroy();
		}
	}

	getApps = () => {
		this.props.homey.apps.getApps().then(apps => {
			this.setState({ apps });
		}).catch(this.props.handleError);
	}

	render() {
		const { apps, activeAppId, title, simulatorSrc, ready, } = this.state;
		const HomeyIcon = (iconObj, { color } = {}) => {
			if (!iconObj) return;

			return (
				<div className="IconCircle" style={{
					backgroundColor: color,
				}}>
					<div className="IconMask" style={{
						WebkitMaskImage: 'url(' + this.state.baseUrl + iconObj.url + ')',
					}} />
				</div>
			);
		}

		const listItems = [];
		for (let appId in apps) {
			const app = apps[appId];
			if (app.settings !== true) continue;
			if (app.sdk < 2) continue;

			listItems.push(
				<ListItem
					button
					key={appId}
					onClick={(el) => app.enabled && this.loadApp(app)}
					disabled={!app.enabled}
					style={{
						opacity: app.enabled ? 1 : 0.5
					}}
				>
					<ListItemIcon>
						{HomeyIcon(app.iconObj, {
							color: app.color || app.brandColor,
						})}
					</ListItemIcon>
					<ListItemText primary={app.name} />
				</ListItem>
			);
		}

		let content;
		if (listItems.length) {
			content = <List>{listItems}</List>;
		} else {
			content = <p style={{ textAlign: 'center', margin: '1em', color: 'black' }}>No Apps with settings available.</p>
		}

		return (
			<Page className="PageToolsSettings" cards={true}>
				<Simulator
					ready={ready}
					backVisible={activeAppId !== null}
					appTitle={title}
					onBack={this.onSimulatorBack}
					onLoad={this.onSimulatorLoad}
					homeySoftwareVersion={this.props.homey.softwareVersion}
					homeyPlatform={this.props.homey.platform}
					src={simulatorSrc}
				>
					{content}
				</Simulator>
			</Page>
		);
	}

	loadApp = (app) => {

		let src = this.state.baseUrl + '/app/' + app.id + '/settings/';

		this.setState({
			simulatorSrc: src,
			activeAppId: app.id,
			title: app.name
		});
	}

	onSimulatorBack = () => {
		this.setState({
			ready: false,
			simulatorSrc: null,
			activeAppId: null,
			title: 'Select an App'
		});
	}

	onSimulatorLoad = (simulator) => {
		simulator
			.on('ready', (data, callback) => {
				this.setState({
					ready: true,
				});
			})
			.on('api', this.onSimulatorApi)
			.on('openURL', this.onSimulatorOpenURL)
			.on('registerRealtimeListener', this.onSimulatorRegisterRealtimeListener)
			.on('getSettings', this.onSimulatorGetSettings)
			.on('getSetting', this.onSimulatorGetSetting)
			.on('setSetting', this.onSimulatorSetSetting)
			.on('unsetSetting', this.onSimulatorUnsetSetting)
			.on('getAppLocales', this.onSimulatorGetAppLocales)
			.on('getLanguage', this.onSimulatorGetLanguage);
	}

	onSimulatorSetTitle = (title) => {
		this.setState({
			title: title
		})
	}

	onSimulatorRegisterRealtimeListener = (event, callback) => {
		this.props.homey.apps.getApp({
			id: this.state.activeAppId,
		}).then(app => {
			app.on(event, callback);
			app.connect().catch(this.props.handleError);
		}).catch(err => callback(err));
	}

	onSimulatorApi = (data, callback) => {
		while (data.path.indexOf('..') > -1) data.path = data.path.replace('..', '');
		while (data.path.indexOf('//') > -1) data.path = data.path.replace('/', '');

		data.method = data.method.toLowerCase();

		Promise.resolve().then(() => {
			const app = this.state.apps[this.state.activeAppId];
			if (!app)
				throw new Error('invalid_active_app');

			if (data.method === 'get') {
				return app.get({
					path: data.path,
				});
			} else if (data.method === 'post') {
				return app.post({
					path: data.path,
					body: data.body,
				});
			} else if (data.method === 'put') {
				return app.put({
					path: data.path,
					body: data.body,
				});
			} else if (data.method === 'delete') {
				return app.delete({
					path: data.path,
				});
			} else {
				throw new Error('invalid_method');
			}
		})
			.then(result => {
				callback(null, result);
			})
			.catch(err => {
				console.error('API Error:', data.method, data.path, err);
				let error = new Error();
				error.message = err.message;
				error.code = err.code;
				error.description = err.error_description;
				callback(error);
			})
	}

	onSimulatorOpenURL = (data, callback) => {
		window.open(data);
		callback();
	}

	onSimulatorGetSettings = (data, callback) => {
		this.props.homey.apps.getAppSettings({
			id: this.state.activeAppId
		}).then(settings => {
			callback(null, settings);
		}).catch(err => callback(err));
	}

	onSimulatorGetSetting = (data, callback) => {
		this.props.homey.apps.getAppSetting({
			id: this.state.activeAppId,
			name: data.key,
		}).then(settings => {
			callback(null, settings);
		}).catch(err => callback(err));
	}

	onSimulatorSetSetting = (data, callback) => {
		this.props.homey.apps.setAppSetting({
			id: this.state.activeAppId,
			name: data.key,
			value: data.value,
		}).then(settings => {
			callback(null, settings);
		}).catch(err => callback(err));

	}

	onSimulatorUnsetSetting = (data, callback) => {
		this.props.homey.apps.unsetAppSetting({
			id: this.state.activeAppId,
			name: data.key,
		}).then(settings => {
			callback(null, settings);
		}).catch(err => callback(err));
	}

	onSimulatorGetAppLocales = (data, callback) => {
		this.props.homey.apps.getAppLocales({
			id: this.state.activeAppId,
		}).then(strings => {
			callback(null, strings);
		}).catch(err => callback(err));
	}

	onSimulatorGetLanguage = (data, callback) => {
		this.props.homey.i18n.getOptionLanguage()
			.then(result => callback(null, result.value))
			.catch(err => callback(err));
	}
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsAppSettings, { version: '>=2.0.0', platforms: ['local'] })))));
