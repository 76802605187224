import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withApps,
  withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import PageCard from '../Card';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { compare } from 'semver';

import iconVerified from '../App/icons/verified.svg'

import './style.css';

const ROWS_PER_PAGE = 10;

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onPageChange(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onPageChange(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

class TableSortLabelActions extends Component {
  state = {
    sortBy: this.props.active,
    sortOrder: this.props.direction,
    sortDirection: 'asc',
    title: this.props.title,
    id: this.props.id
  }

  handleRequestSort = event => {
    const sortDirection = (String(this.state.sortDirection) === 'desc') ? 'asc' : 'desc';

    this.props.action(sortDirection, this.props.id);
    this.setState({ sortDirection });
  };

  render() {
    const { sortBy, sortOrder, title, id } = this.props;
    const { sortDirection } = this.state;

    return (
      <TableCell
        key={id}
        sortDirection={sortBy === id ? sortOrder : false}>
        <TableSortLabel
          active={sortBy === id}
          direction={sortDirection}
          onClick={this.handleRequestSort}
        >
          {title}
        </TableSortLabel>
      </TableCell>
    )
  };
}

TableSortLabelActions.propTypes = {
  active: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

const TableSortLabelActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TableSortLabelActions,
);

class PageAppsReview extends Component {
  state = {
    appsPage: 0,
    sortOrder: 'asc',
    sortBy: 'added'
  }

  handleSortApps = (sortOrder, sortBy) => {
    this.setState({ sortOrder });
    this.setState({ sortBy });
  };


  handleChangeAppsPage = (event, appsPage) => {
    this.setState({ appsPage });
  };

  handleRefreshReviewApps = () => {
    if (!this.props.user || !this.props.user.roleIds.includes('app_reviewer')) return;

    this.props.callApps('getReviewApps').then(apps => {

      apps = apps.map((d, i) => {
        return {
          new: this.isNewBuild(d) || false,
          id: d.id,
          brandColor: d.testBuild.brandColor,
          icon: d.testBuild.icon,
          name: d.testBuild.name ? d.testBuild.name.en : '-',
          author: d.author.name,
          verified: d.author.verified,
          version: d.testVersion,
          build: d.testBuild.id,
          added: d.testBuild.stateChangedAt
        }
      });

      this.setState({ apps });
    }).catch(this.props.handleError);
  }

  componentDidMount() {
    this.handleRefreshReviewApps();
  }

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  getComparator(order, orderBy) {
    switch (orderBy) {
      case 'version':
        // Use Semver sorting for build numbers.
        return order === 'desc'
          ? (a, b) => compare(a[orderBy], b[orderBy], true)
          : (a, b) => -compare(a[orderBy], b[orderBy], true);
      default:
        return order === 'desc'
          ? (a, b) => this.descendingComparator(a, b, orderBy)
          : (a, b) => -this.descendingComparator(a, b, orderBy);
    }
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }


  isNewBuild(app) {
    if (!app.liveBuild)
      return (<NewReleasesIcon key="new" title="New App" color="secondary" />);

    return null;
  }

  buildTimeAgo(stateChangedAt) {
    return [<TimeAgo key="time" date={new Date(stateChangedAt)} />];
  }

  tableHeader() {
    return [
      {
        id: 'new',
        title: 'New'
      },
      {
        id: 'icon',
        title: 'Icon'
      },
      {
        id: 'name',
        title: 'Name'
      },
      {
        id: 'author',
        title: 'Author'
      },
      {
        id: 'version',
        title: 'Version'
      },
      {
        id: 'build',
        title: 'Build'
      },
      {
        id: 'added',
        title: 'Added'
      }
    ];
  }


  render() {
    const {
      apps,
      appsPage,
      sortOrder,
      sortBy,
    } = this.state;

    return (
      <Page className="Apps" cards>
        {apps && (
          <PageCard title="Review Apps"
            subtitle={`${apps && apps.length} apps waiting for review.`}>
            <Fragment>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[ROWS_PER_PAGE]}
                      colSpan={9}
                      count={apps.length}
                      rowsPerPage={ROWS_PER_PAGE}
                      page={appsPage}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={this.handleChangeAppsPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                  <TableRow>
                    {this.tableHeader().map(cell => (
                      <TableSortLabelActions
                        active={sortBy === cell.id}
                        direction={sortOrder}
                        title={cell.title}
                        id={cell.id}
                        key={cell.id}
                        action={this.handleSortApps}
                        ActionsComponent={TableSortLabelActionsWrapped}
                      />
                    ))}
                    <TableCell>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.stableSort(apps, this.getComparator(sortOrder, sortBy))
                    .slice(appsPage * ROWS_PER_PAGE, appsPage * ROWS_PER_PAGE + ROWS_PER_PAGE)
                    .map(app => (
                      <TableRow
                        key={app.id}>
                        <TableCell>{app.new}</TableCell>
                        <TableCell>
                          <Link
                            style={{ textDecoration: 'none' }}
                            to={`/apps/app/${app.id}/build/${app.build}`}
                          >
                            <div className="HomeyIcon" style={{
                              backgroundColor: app.brandColor,
                            }}>
                              <div className="HomeyIconInner" style={{
                                maskImage: `url(${app.icon})`,
                                WebkitMaskImage: `url(${app.icon})`,
                                MozMaskImage: `url(${app.icon})`,
                              }}></div>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell>{app.name}</TableCell>
                        <TableCell>{app.author} {app.verified ? <img src={iconVerified} style={{ height: '1em', marginLeft: '5px', marginBottom: '-2px' }} /> : null}</TableCell>
                        <TableCell>{app.version}</TableCell>
                        <TableCell>#{app.build}</TableCell>
                        <TableCell>{this.buildTimeAgo(app.added)}</TableCell>
                        <TableCell>
                          <a
                            href={`/apps/app/${app.id}/build/${app.build}`}
                            style={{ textDecoration: 'none' }}
                            rel="noopener noreferrer"
                          >
                            <Button color="primary">
                              {'Review'}
                              <NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                            </Button>
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[ROWS_PER_PAGE]}
                      colSpan={9}
                      count={apps.length}
                      rowsPerPage={ROWS_PER_PAGE}
                      page={appsPage}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={this.handleChangeAppsPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Fragment>
          </PageCard>
        )}

        {!Array.isArray(apps) && <div>Loading...</div>}
      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withApps(PageAppsReview)))));
