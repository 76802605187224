import './style.css';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withSnackbar } from 'notistack';
import {
	withApi,
	withUser,
	withHomey,
	withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import TimeAgo from 'react-timeago';

class PageToolsSpeech extends Component {

	constructor(props) {
		super(props);

		const message = {
			author: 'homey',
			text: 'Hello! Type something to start chatting.',
			date: new Date(),
		};

		this.state = {
			type: 'output',
			history: ['What time is it?'],
			historyIndex: 1,
			activeSessionId: 'homey',
			sessions: {
				homey: [message],
				devsession: [message],
			}
		}
	}

	componentDidMount() {
		this.props.homey.speechOutput.connect().catch(this.props.handleError);
		this.props.homey.speechOutput.on('speech', this.onSpeech)
	}

	componentWillUnmount() {
		if (this.props.homey) {
			this.props.homey.speechOutput.destroy();
		}
	}

	componentDidUpdate() {
		const el = ReactDOM.findDOMNode(this._messagesContainer);
		if (el) {
			el.scrollTop = el.scrollHeight;
		}
	}

	onSpeech = speech => {
		const message = {
			text: speech.text,
			date: new Date(),
			author: 'homey',
			index: speech.index,
		};

		const { sessions } = this.state;

		sessions[speech.session] = sessions[speech.session] || [];
		sessions[speech.session].push(message);
		this.setState({ sessions });
	}

	handleTextFieldKeyDown = event => {
		const {
			key,
			target,
		} = event;

		const {
			value,
		} = target;

		const {
			activeSessionId,
			type,
			sessions,
			history,
		} = this.state;

		let {
			historyIndex,
		} = this.state;

		const activeSession = sessions[activeSessionId];

		if (key === 'ArrowUp') {

			let historyItem = history[historyIndex - 1];
			if (historyItem) {
				target.value = historyItem;
				historyIndex--;
			}

			this.setState({
				historyIndex,
			});

		} else if (key === 'ArrowDown') {

			let historyItem = history[historyIndex + 1];
			if (historyItem) {
				target.value = historyItem;
			} else {
				target.value = '';
			}

			historyIndex++;
			historyIndex = Math.min(historyIndex, history.length);

			this.setState({
				historyIndex,
			});

		} else if (key === 'Enter') {

			if (value.length < 1) return;
			target.value = '';

			if (history[history.length - 1] !== value) {
				history.push(value);
			}
			historyIndex = history.length;
			this.setState({
				historyIndex,
			});

			if (type === 'input') {

				const inputObj = {
					text: value,
					date: new Date(),
					state: 'loading',
					author: 'me'
				}
				activeSession.push(inputObj);
				this.forceUpdate();

				this.props.homey.speechInput.parseSpeech({
					transcript: value,
					session: activeSessionId,
				}).then(({ agentId } = {}) => {
					inputObj.state = 'success';
					inputObj.agentId = agentId;
					this.forceUpdate();
				}).catch(this.props.handleError)

			} else if (type === 'output') {

				this.props.homey.speechOutput.say({
					text: value,
					session: activeSessionId,
				}).catch(this.props.handleError);
			}
		}
	}

	handleTabChange = (event, value) => {
		this.setState({
			activeSessionId: value,
		});
	}

	handleTypeChange = (event) => {
		this.setState({
			type: event.target.value,
		});
	}

	render() {
		const {
			type,
			activeSessionId,
			sessions,
		} = this.state;

		const activeSession = sessions[activeSessionId];

		const ChatMessage = (props) => {
			return (
				<div className="ChatMessage" author={props.author}>
					<Avatar
						className="ChatMessageAvatar"
						src={props.author === 'homey' ? 'https://etc.athom.com/logo/transparent/64.png' : this.props.user.avatar.medium}
						size={32}
					/>
					<div className="ChatMessageContent">
						<Typography variant="body2" className="ChatMessageText">{props.text}</Typography>
						<Typography variant="body2" className="ChatMessageMeta">
							<TimeAgo date={props.date} />
							{props.agentId && ` — ${props.agentId}`}
						</Typography>
					</div>
				</div>
			);
		}

		const messages = activeSession.map((message, i) => {
			return (
				<ChatMessage
					key={i}
					author={message.author}
					state={message.state}
					text={message.text}
					date={message.date}
					agentId={message.agentId}
				/>
			);
		});

		const tabs = Object.keys(sessions).map(sessionId => {
			return (
				<Tab
					key={sessionId}
					label={sessionId}
					value={sessionId}
				/>
			);
		});

		return (
			<Page cards={true} className="PageToolsSpeech">
				<Paper elevation={2}>
					<AppBar position="static" color="default">
						<Tabs
							value={activeSessionId}
							onChange={this.handleTabChange}
							indicatorColor="primary"
							textColor="primary"
						>
							{tabs}
						</Tabs>
					</AppBar>

					<div
						className="ChatMessages"
						ref={(el) => { this._messagesContainer = el; }}
					>
						{messages}
					</div>

					<FormGroup
						row={true}
						style={{
							margin: 12,
						}}
					>
						<FormControl
							margin="normal"
						>
							<Select
								value={type}
								onChange={this.handleTypeChange}
							>
								<MenuItem value="output">
									Output
								</MenuItem>
							</Select>
						</FormControl>

						&nbsp;

						<FormControl
							margin="normal"
							style={{
								flexGrow: 1,
							}}
						>
							<TextField
								onKeyDown={this.handleTextFieldKeyDown}
								placeholder="Type something..."
							/>
						</FormControl>
					</FormGroup>
				</Paper>
			</Page>
		);
	}
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsSpeech, { platforms: ['local'] })))));
