import React, { Component } from 'react';
import Page from '../Page';
import Card from '../Card';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withMessages,
} from '../../services/AthomApi';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import MessageIcon from '@material-ui/icons/Message';
import LinkIcon from '@material-ui/icons/Link';
import PlayIcon from '@material-ui/icons/PlayArrow';

import AthomNotificationsAPI from 'homey-api/lib/AthomNotificationsAPI';

class PagePushNotifications extends Component {

  constructor(...props) {
    super(...props);

    this.state = {
      running: false,
      devices: '',
      devicesArray: [],
      message: '',
      url: '',
    };
  }

  async sendPushMessage() {
    const {
      devicesArray,
      message,
      url,
    } = this.state;

    const type = url.length ? 'url' : 'text';
    const data = {
      text: message,
    };
    if (type === 'url') data.externalUrl = url;

    if (!message || message.length < 10)
      throw new Error('Invalid Message. Minimum of 10 characters.');

    if (url && url.length && !(url.startsWith('https://') || url.startsWith('homey://')))
      throw new Error('Invalid URL. Must start with https:// or homey://');

    if (!devicesArray.length)
      throw new Error('No Devices');

    for (let i = 0; i < devicesArray.length; i = i + 500) {
      const batch = devicesArray.slice(i, i + 500);
      const token = await this.props.api.createDelegationToken({
        audience: 'notifications',
      });

      const api = new AthomNotificationsAPI();
      await api.push({
        batch,
        secret: token,
        data: { data, type },
      })
        .then(({
          sent,
          rejected,
          unknown,
        }) => {
          this.props.handleSuccess(`[Batch ${Math.ceil((i + 1) / 500)}/${(Math.ceil(devicesArray.length / 500))}] Sent: ${sent.length}, Rejected: ${rejected.length}, Unknown: ${unknown.length}`);
        })
        .catch(err => {
          this.props.handleError(`[Batch ${Math.ceil((i + 1) / 500)}/${(Math.ceil(devicesArray.length / 500))}] Error: ${err.message}`);
        });
    }
  }

  handleSendPushMessage = () => {
    this.setState({ running: true });

    // TODO: Confirm Dialog (?)

    this.sendPushMessage()
      .catch(this.props.handleError)
      .then(() => {
        this.setState({ running: false });
      })
  }

  handleMessageChange = e => {
    const message = e.target.value;
    this.setState({ message });
  }

  handleURLChange = e => {
    const url = e.target.value;
    this.setState({ url });
  }

  handleDevicesChange = e => {
    const devices = e.target.value;
    const devicesArray = devices.split('\n').filter(device => {
      return device.startsWith('android:') || device.startsWith('ios:');
    }).map(device => {
      // Split Once
      const [platform, token] = [device.slice(0, device.indexOf(':')), device.slice(device.indexOf(':') + 1)]
      return { platform, token };
    });
    this.setState({ devices, devicesArray });
  }

  render() {
    const {
      running,
      message,
      devices,
      devicesArray,
      url,
    } = this.state;

    return (
      <Page
        cards={true}
        className="PagePushNotifications"
      >
        <Card
          title="Send Push Notification"
          subtitle={<span>Broadcast a Push Notification to Homey app users.</span>}
          toolbar={(
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSendPushMessage}
              disabled={running}
            >
              {running && (
                <React.Fragment>
                  <CircularProgress
                    size={24}
                    style={{
                      color: 'white',
                    }}
                  />
                  &nbsp;Running
                </React.Fragment>
              )}
              {!running && (
                <React.Fragment>
                  <PlayIcon />
                  &nbsp;Run
                </React.Fragment>
              )}
            </Button>
          )}
        >

          <Box my={3}>
            <TextField
              label="Message"
              fullWidth={true}
              variant="outlined"
              placeholder="Hello World!"
              disabled={running}
              value={message}
              onChange={this.handleMessageChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MessageIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box my={3}>
            <TextField
              label="URL"
              fullWidth={true}
              variant="outlined"
              placeholder="https://homey.app/b/my-blog?utm_source=athom&utm_medium=push&utm_campaign=my-campaign&utm_content=push_tap"
              value={url}
              disabled={running}
              onChange={this.handleURLChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box my={3}>
            <TextField
              label="Devices"
              minRows={20}
              maxRows={20}
              multiline={true}
              fullWidth={true}
              disabled={running}
              variant="outlined"
              placeholder={`ios:AABBCC\nandroid:DDEEFF`}
              value={devices}
              onChange={this.handleDevicesChange}
              helperText={devicesArray.length + ' devices'}
            />
          </Box>

        </Card>
      </Page >
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withRouter(PagePushNotifications)))));
