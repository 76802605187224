import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withHomey,
  withMessages,
} from '../../services/AthomApi';

import semver from 'semver';

import PageToolsZigbeeShepherd from "./PageToolsZigbeeShepherd";
import PageToolsZigbeeNodeZigbee from "./PageToolsZigbeeNodeZigbee";

class PageToolsZigbee extends Component {

  _isLegacyHomey() {
    if (this.props.homey.platform === 'local') {
      return semver.lte(this.props.homey.softwareVersion, '4.2.0')
    }
    return false;
  }

  render() {
    return (
      <React.Fragment>
        {this._isLegacyHomey() ? <PageToolsZigbeeShepherd /> : <PageToolsZigbeeNodeZigbee />}
      </React.Fragment>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsZigbee)))));
