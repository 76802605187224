import './style.css';
import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

class PageToolsTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    return (
      <Page
        title="Web API Projects"
      >

        <Typography variant="body2" style={{ color: '#FF3300' }}>The Web API is not yet available for 3rd party developers.</Typography>
        <Typography variant="body1">In the meantime you can use these API credentials to test your application locally.</Typography>

        <p>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Client ID</TableCell>
                <TableCell className={`CapabilityType`}>5a8d4ca6eb9f7a2c9d6ccf6d</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Client Secret</TableCell>
                <TableCell className={`CapabilityType`}>e3ace394af9f615857ceaa61b053f966ddcfb12a</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Callback URL</TableCell>
                <TableCell className={`CapabilityType`}>http://localhost, http://localhost/oauth2/callback</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Scopes</TableCell>
                <TableCell className={`CapabilityType`}>homey</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </p>

        <Typography variant="body1">It is not allowed to use these credentials for commercial or non-development purposes.</Typography>

        <Typography variant="body1">Do you have a great idea for an app? <a href="https://homey.app/contact">Contact us</a> to request a Web API key.</Typography>

      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(PageToolsTemplate))));
