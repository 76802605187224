import React, { useRef } from 'react';

import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TimeAgo from 'react-timeago';

export function PageToolsDeviceCapability(props) {
  const ref = useRef();

  const capability = props.capability;
  const capabilityInstance = props.capabilityInstance;

  function setValue(overrideValue) {
    let value = overrideValue !== undefined ? overrideValue : ref.current.value;

    switch (capability.type) {
      case 'number':
        // So it's possible to reset value.
        const number = parseFloat(value);
        value = Number.isNaN(number) ? null : number;
        break;
      case 'boolean':
        value = value === 'true' ? true : value === 'false' ? false : null;
        break;
      default:
        // String and enum are set as string.
        break;
    }

    props.onSetCapabilityValue(value);
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setValue();
    }
  }

  let capabilityInput;
  if (capability.setable) {
    let step;
    if (typeof capability.step === 'number') {
      step = capability.step;
    } else if (typeof capability.decimals === 'number') {
      step = 10 ** -capability.decimals;
    }

    if (capability.type === 'number') {
      capabilityInput = <TextField
        inputRef={ref}
        defaultValue={capability.value}
        type="number"
        inputProps={{
          step,
          min: capability.min,
          max: capability.max,
        }}
        onKeyDown={handleKeyDown}
        fullWidth={true}
      />;
    } else if (capability.type === 'string') {
      capabilityInput = <TextField
        inputRef={ref}
        defaultValue={capability.value}
        onKeyDown={handleKeyDown}
        fullWidth={true}
      />;
    } else if (capability.type === 'boolean') {
      capabilityInput = <NativeSelect
        inputRef={ref}
        defaultValue={capability.value}
        onKeyDown={handleKeyDown}
        fullWidth={true}
      >
        <option value="true">true</option>
        <option value="false">false</option>
      </NativeSelect>;
    } else if (capability.type === 'enum') {
      capabilityInput = <NativeSelect
        inputRef={ref}
        defaultValue={capability.value}
        onKeyDown={handleKeyDown}
        fullWidth={true}
      >
        {capability.values.map(value => {
          return <option key={value.id} value={value.id}>{value.id}</option>;
        })}
      </NativeSelect>;
    }

    capabilityInput = <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    }}>
      <div style={{ width: 150 }}>{capabilityInput}</div>
      <Button onClick={() => setValue()}>Set</Button>
    </div>;
  }

  return (
    <TableRow>
      <TableCell>{capability.id}</TableCell>
      <TableCell>{capability.title}</TableCell>
      <TableCell>{capability.type}</TableCell>
      <TableCell
        className={`CapabilityType CapabilityType-${typeof capabilityInstance.value}`}>{String(capabilityInstance.value)}</TableCell>
      <TableCell>{capabilityInput}</TableCell>
      <TableCell>
        {(capabilityInstance.lastChanged instanceof Date) && (
          <TimeAgo date={capabilityInstance.lastChanged} />
        )}
      </TableCell>
    </TableRow>
  );
}
