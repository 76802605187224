import './style.css';
import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import {
	withApi,
	withUser,
	withHomey,
	withMessages,
} from '../../services/AthomApi';
import Page from '../Page';

class PageToolsLEDRing extends Component {

	constructor(props) {
		super(props);

		this.state = {
			animation: null,
		}
	}

	componentDidMount() {
		this.props.homey.ledring
			.on('animate', this.handleAnimate)
			.connect()
			.catch(this.props.handleError);

		this.props.homey.ledring.getState().then(state => {
			const { animation } = state;
			if (animation) {
				this.setState({ animation });
			}
		}).catch(this.props.handleError);
	}

	componentWillUnmount() {
		if (this.props.homey) {
			this.props.homey.ledring.destroy();
		}
	}

	handleAnimate = animation => {
		console.log('handleAnimate', JSON.stringify(animation, false, 2))
		this.setState({
			animation,
		});
	}

	render() {
		const { animation } = this.state;
		return (
			<Page
				className="PageToolsLEDRing"
				title="LED Ring"
				subtitle="This simulator is a live view of Homey's LED Ring."
			>
				<LEDRing animation={animation} />
			</Page>
		);
	}
}

class LEDRing extends React.Component {

	constructor(props) {
		super(props);

		const {
			animation,
			leds = 24,
		} = props;

		this.state = {
			leds,
			animation,
			currentFrameIndex: 0,
			targetFrameIndex: 0,
			rotation: 0,
			rotationProgress: 0,
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.animation !== prevProps.animation) {
			this.props.animation.options.tfps = 20;
			this.setState({
				animation: this.props.animation,
				currentFrameIndex: 0,
				targetFrameIndex: 0,
				rotation: 0,
				rotationProgress: 0,
			});

			setTimeout(() => {
				this.restart();
			}, 1);
		}
	}

	componentWillUnmount() {
		this.stop();
	}

	stop = () => {
		if (this.handleTickInterval)
			clearInterval(this.handleTickInterval);

		this._rotationPerTick = null;
		this._rotationProgressPerTick = null;
	}

	start = () => {
		const { animation, leds } = this.state;
		if (!animation) return;

		const { options } = animation;
		const { tfps, fps, rpm } = options;

		const ticksPerSecond = tfps / fps;

		const rps = rpm / 60;
		this._rotationPerTick = rps / ticksPerSecond;
		this._rotationProgressPerTick = this._rotationPerTick * leds;

		this.handleTickInterval = setInterval(this.handleTick, 1000 / ticksPerSecond);
		this.handleTick();

	}

	restart = () => {
		this.stop();
		this.start();
	}

	handleTick = () => {
		const {
			animation,
			targetFrameIndex,
			rotation,
			rotationProgress,
		} = this.state;
		const { options } = animation;
		const { tfps } = options;

		// tfps
		const newTargetFrameIndex = (targetFrameIndex + 1) % tfps;
		this.setState({ targetFrameIndex: newTargetFrameIndex });

		// fps
		if (newTargetFrameIndex === 0) {
			const { animation, currentFrameIndex } = this.state;
			if (!animation) return this.stop();

			const { frames } = animation;
			const newCurrentFrameIndex = (currentFrameIndex + 1) % frames.length;

			this.setState({ currentFrameIndex: newCurrentFrameIndex });
		}

		let newRotation = rotation + this._rotationPerTick;
		newRotation = newRotation % 1;

		let newRotationProgress = rotationProgress + this._rotationProgressPerTick;
		newRotationProgress = newRotationProgress % 1;

		this.setState({
			rotation: newRotation,
			rotationProgress: newRotationProgress,
		});
	}

	render() {
		const {
			leds,
			animation,
			rotation,
			rotationProgress,
			currentFrameIndex,
			targetFrameIndex,
		} = this.state;

		if (!animation) return <div />;

		const {
			frames,
			options,
		} = animation;

		const {
			fps,
			tfps,
			rpm,
		} = options;

		const progress = targetFrameIndex / tfps;
		const currentFrame = frames[currentFrameIndex];
		const targetFrame = frames[(currentFrameIndex + 1) % frames.length];

		// calculate colors
		const colors = [];
		for (let i = 0; i < leds; i++) {
			if (!currentFrame) continue;
			const colorCurrentFrame = currentFrame[i];
			const colorTargetFrame = targetFrame[i];

			const r = mix([colorCurrentFrame.r, colorTargetFrame.r], progress);
			const g = mix([colorCurrentFrame.g, colorTargetFrame.g], progress);
			const b = mix([colorCurrentFrame.b, colorTargetFrame.b], progress);

			colors[i] = { r, g, b };
		}

		// calculate leds
		const $leds = [];
		for (let i = 0; i < leds; i++) {

			const ledIndex = Math.floor(i + rotation * leds);
			const colorCurrentLED = colors[ledIndex % leds];
			const colorNextLED = colors[(ledIndex + 1) % leds];

			const r = mix([colorCurrentLED.r, colorNextLED.r], rotationProgress);
			const g = mix([colorCurrentLED.g, colorNextLED.g], rotationProgress);
			const b = mix([colorCurrentLED.b, colorNextLED.b], rotationProgress);

			$leds.push(
				<div
					key={i}
					className="Led"
					title={`LED #${i + 1}`}
					style={{
						backgroundColor: `rgb(${r}, ${g}, ${b})`,
						transform: `rotate(${(i / leds) * 360}deg) translateX(220px)`,
					}}
				/>
			);
		}


		return (
			<div>

				<div className="Ledring">
					{$leds}
					<p className="Info">
						Frame: {currentFrameIndex + 1}/{frames.length} ({fps} fps)<br />
						Interpolation: {targetFrameIndex + 1}/{tfps} ({tfps} tfps)<br />
						Rotation: {Math.floor(rotation * 100)}% ({rpm} rpm)<br />
					</p>
				</div>
			</div>
		);
	}

}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsLEDRing, { version: '>=2.0.0', platforms: ['local'], platformVersions: [1] })))));

function mix(colors, position) {
	const [a, b] = colors;
	return (a * (1 - position) + b * position);
}
