import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import HomeySignalFactory from 'homey-signal';

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  withApi,
  withUser,
  withHomey,
  withMessages
} from '../../services/AthomApi';
import Page from '../Page';

const DEFAULT_REPETITIONS = 5;

class PageToolsIr extends Component {

  constructor(props) {
    super(props);
    this.state = {
      payload: "",
      repetitions: undefined,
      sequences: undefined,
      errorMessage: "",
      transmitting: false,
    }
  }

  render() {
    const analyze = () => {
      this.setState({ errorMessage: "" });
      if (this.state.payload) {
        try {
          let signal = HomeySignalFactory.makeSignal({
            type: 'prontohex',
            cmds: {
              default: this.state.payload,
            },
          });
          this.setState({
            sequences: signal.getSequencePayloads('default', this.state.repetitions ? parseInt(this.state.repetitions) : DEFAULT_REPETITIONS)
          });
        } catch (err) {
          this.setState({ errorMessage: `something went wrong: ${err}` });
        }
      }
    }

    const sendProntoHex = () => {
      this.setState({ transmitting: true, errorMessage: "" });
      if (this.state.payload) {
        this.props.homey.rf.txInfraredProntohex({
          payload: this.state.payload,
          repetitions: this.state.repetitions ? parseInt(this.state.repetitions) : DEFAULT_REPETITIONS
        })
          .catch((err) => {
            this.setState({ errorMessage: `Something went wrong: ${err.message}` });
          })
          .finally(() => {
            this.setState({ transmitting: false });
          })
      }
    }

    const handlePayloadChange = (event) => {
      this.setState({ payload: event.target.value });
    }

    const handleRepetitionChange = (event) => {
      this.setState({ repetitions: event.target.value });
    }

    return (
      <Page className="PageToolsIR" cards={true}>
        <Card>
          <CardHeader title={<span> Infrared</span>} />
          <CardContent>
            <Typography> This tool allows you to enter a ProntoHex string and decode/analyze it. You can also send the
              Prontohex command to the selected Homey. </Typography>
            <TextField
              style={{ marginTop: 24 }}
              value={this.state.payload}
              onChange={handlePayloadChange}
              variant="outlined"
              label="Prontohex"
              minRows={3}
              maxRows={3}
              fullWidth
              multiline
            />
            <TextField
              style={{ marginTop: 24 }}
              value={this.state.repetitions}
              onChange={handleRepetitionChange}
              variant="outlined"
              placeholder={DEFAULT_REPETITIONS.toString()}
              label="Repetitions"
            />

            {this.state.errorMessage ? (
              <Typography style={{ color: 'red' }}> {this.state.errorMessage} </Typography>
            ) : null}

            {this.state.sequences ? (
              <TextField
                style={{ marginTop: 24 }}
                value={JSON.stringify(this.state.sequences, null, 4)}
                variant="filled"
                fullWidth
                multiline
              />
            ) : null}

          </CardContent>
          <CardContent>
            <Button variant="contained" color="primary" disabled={this.state.transmitting}
              onClick={sendProntoHex}> Send </Button>
            <Button variant="outlined" color="primary" onClick={analyze} style={{ marginLeft: 10 }}> Analyze </Button>
          </CardContent>
        </Card>
      </Page>
    )
  };
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsIr, { version: '>=7.0.0' })))));
