import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withHomey,
  withMessages,
} from '../../services/AthomApi';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Page from '../Page';
import Card from '../Card';
import Simulator from '../Simulator';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

class PageToolsPair extends Component {

  constructor(props) {
    super(props);

    this._session = null;
    this.state = {
      ready: false,
      type: 'pair',
    }
    this._titles = {
      pair: 'Select a driver to start pairing...',
      repair: 'Select a device to start repairing...',
      unpair: 'Select a device to start unpairing...',
    }

    this._registeredEventListeners = [];
    this._socketMessageQueue = [];
    this._addDeviceQueue = [];
  }

  componentDidMount() {
    this.props.homey.drivers
      .on('pair.message', this.onPairMessage)
      .on('driver.create', this.getDrivers)
      .on('driver.update', this.getDrivers)
      .on('driver.delete', this.getDrivers)
      .connect()
      .catch(console.error)
      .finally(() => this.getDrivers());

    this.props.homey.devices
      .on('device.create', this.getDevices)
      .on('device.update', this.getDevices)
      .on('device.delete', this.getDevices)
      .connect()
      .catch(console.error)
      .finally(() => this.getDevices());

    this.props.homey.baseUrl.then(baseUrl => {
      this.setState({
        drivers: {},
        devices: {},
        baseUrl: baseUrl,
        title: this._titles[this.state.type],
      })
    });
  }

  componentWillUnmount() {
    if (this.props.homey) {
      this.props.homey.drivers.destroy();
      this.props.homey.devices.destroy();
    }
  }

  onPair = ({ type, driverUri, driverId, deviceId }) => {

    if (this._session !== null)
      this.onSimulatorDone();

    this._pairOpts = {
      type,
      driverUri,
      driverId,
      deviceId,
      //zoneId: "..."
    }

    this.props.homey.drivers
      .connect()
      .then(() => {
        return this.props.homey.drivers.createPairSession({
          pairsession: this._pairOpts,
        })
      })
      .then(session => {
        this._session = session;
        this.emptySocketMessageQueue();

        if (this._heartbeatInterval)
          clearInterval(this._heartbeatInterval);

        this._heartbeatInterval = setInterval(() => {
          this.props.homey.drivers.emitPairingHeartbeat({
            id: this._session.id
          }).catch(err => {
            this.setState({
              session: null
            });
            this.props.handleError(err);
            this.onSimulatorDone();
          });
        }, 10000);

        let src = this.state.baseUrl + session.url;

        this.setState({
          simulatorSrc: src
        });
      })
      .catch(err => {
        this.props.handleError(err);
        if (this._heartbeatInterval) clearInterval(this._heartbeatInterval);
      })

  }

  onSimulatorLoad = (simulator) => {

    this._simulator = simulator;
    this._simulator
      .on('ready', (data, callback) => {
        this.setState({
          ready: true,
        });
        this._simulatorLoaded = true;
        this._simulator.emit('_start', this._session);
        this.onSimulatorSetTitle('Follow the instructions...');
      })
      .on('emit', this.onSimulatorEmit)
      .on('registerSocketEventListener', this.onSimulatorRegisterSocketEventListener)
      .on('createDevice', this.onSimulatorCreateDevice)
      .on('updateDevice', this.onSimulatorUpdateDevice)
      .on('deleteDevice', this.onSimulatorDeleteDevice)
      .on('done', this.onSimulatorDone)
      .on('setTitle', this.onSimulatorSetTitle)
      .on('getZwave', this.onSimulatorGetZwave)
      .on('getZigBee', this.onSimulatorGetZigBee)
      .on('getZone', this.onSimulatorGetZone)
      .on('getLanguage', this.onSimulatorGetLanguage);
  }

  onSimulatorEmit = ({ event, data }, callback) => {
    if (!this._simulatorLoaded) return;
    if (!this._session) return;

    this.props.homey.drivers.emitPairingEvent({
      // Give e.g. onPairListDevices time to discover something. Default of 5000
      // is way too tight.
      $timeout: 30000,
      event,
      data,
      id: this._session.id,
    }).then(data => {
      return callback(null, data);
    }).catch(err => {
      callback(err);
    });
  }

  onSimulatorRegisterSocketEventListener = (event) => {
    this._registeredEventListeners.push(event);
    this.emptySocketMessageQueue();
  }

  onPairMessage = (message) => {
    this._socketMessageQueue.push(message);
    this.emptySocketMessageQueue();

  }

  emptySocketMessageQueue = () => {
    if (!this._simulatorLoaded) return;
    if (!this._session) return;
    const sessionId = this._session.id;

    this._socketMessageQueue = this._socketMessageQueue
      .filter(message => {
        // check if the session matches
        if (message.sessionId !== sessionId) return true;

        // check if there's an event listener subscribed for this event
        const { event } = message;
        if (!this._registeredEventListeners.includes(event)) return true;

        this.proxyMessage(message);

        return false;
      })
  }

  proxyMessage = (message) => {
    this._simulator.emit('pair.message', message, function () {
      var data = Array.prototype.slice.call(arguments);
      this.props.homey.drivers.emitPairingCallback({
        $timeout: 30000,
        data,
        id: this._session.id,
        callbackId: message.callbackId,
      }).catch(this.props.handleError)
    }.bind(this));

  }

  onSimulatorGetLanguage = (data, callback) => {
		this.props.homey.i18n.getOptionLanguage()
			.then(result => callback(null, result.value))
			.catch(err => callback(err));
	}

  onSimulatorCreateDevice = (data, callback) => {
    if (this._pairOpts.type !== 'pair')
      return callback(new Error('not_allowed'));

    this.props.homey.drivers.createPairSessionDevice({
      id: this._session.id,
      device: {
        data: data.data,
        name: data.name,
        store: data.store,
        capabilities: data.capabilities,
        capabilitiesOptions: data.capabilitiesOptions,
        class: data.class,
        icon: data.icon,
        settings: data.settings,
        mobile: data.mobile,
        energy: data.energy,
      }
    })
      .then(device => {
        callback();
        this.props.handleSuccess(<React.Fragment>
          Added&nbsp;<Link
          style={{
            color: 'white',
          }}
          to={`/tools/devices?filter=${device.id}`}
        >{device.name}</Link>
        </React.Fragment>);
      })
      .catch(err => {
        callback(err);
      });
  }

  onSimulatorUpdateDevice = (device, callback) => {
    if (this._pairOpts.type !== 'repair')
      return callback(new Error('not_allowed'));

    this.props.homey.drivers.updatePairSessionDevice({
      id: this._session.id,
      device: device,
    })
      .then(result => {
        callback();
        this.props.handleSuccess(`Updated ${result.name}`);
      })
      .catch(err => {
        callback(err);
      })
  }

  onSimulatorDeleteDevice = (data, callback) => {
    if (this._pairOpts.type !== 'unpair')
      return callback(new Error('not_allowed'));

    this.props.homey.drivers.deletePairSessionDevice({
      id: this._session.id,
    })
      .then(result => {
        callback();
        this.props.handleSuccess(`Deleted ${result.name}`);
      })
      .catch(err => {
        callback(err);
      })
  }

  onSimulatorGetZwave = (data, callback) => {
    callback(null, this._session.zwave || {});
  }

  onSimulatorGetZigBee = (data, callback) => {
    callback(null, this._session.zigbee || {});
  }

  onSimulatorGetZone = (data, callback) => {
    callback(null, undefined);
  }

  onSimulatorSetTitle = (title) => {
    this.setState({
      title: title
    })
  }

  onSimulatorDone = () => {
    this._simulatorLoaded = false;
    this._pairOpts = {};
    if (this._heartbeatInterval) clearInterval(this._heartbeatInterval);

    if (this._session) {
      this.props.homey.drivers.deletePairSession({
        id: this._session.id
      }).catch(this.props.handleError);
    }

    this._session = null;
    this._registeredEventListeners = [];

    this.setState({
      ready: false,
      simulatorSrc: 'about:blank',
      title: 'Select a driver to start pairing...',
    });
  }

  getDrivers = () => {
    let driversObj = {};
    this.props.homey
      .drivers
      .getDrivers()
      .then(drivers => {
        Object.values(drivers).forEach(driver => {
          const driverKey = driver.ownerUri;
          driversObj[driverKey] = driversObj[driverKey] || {
            iconObj: driver.ownerIconObj,
            name: driver.ownerName,
            drivers: []
          }
          driversObj[driverKey].drivers.push(driver);
        });

        this.setState({
          drivers: drivers,
          driversObj: driversObj
        })
      })

  }

  getDevices = () => {
    this.props.homey
      .devices
      .getDevices()
      .then(devices => {
        this.setState({ devices });
      })

  }

  render() {
    const { ready } = this.state;

    const HomeyIcon = iconObj => {
      if (!iconObj) return;

      return <div className="IconMask" style={{
        WebkitMaskImage: 'url(' + this.state.baseUrl + iconObj.url + ')',
        width: 32,
        height: 32,
        background: '#000',
      }}/>
    }

    const lists = {
      pair: null,
      repair: null,
      unpair: null,
    };

    // type: pair
    (() => {
      const listItems = [];
      for (let driverUri in this.state.driversObj) {
        const driver = this.state.driversObj[driverUri];
        const open = (this.state.open === driverUri);

        listItems.push(
          <React.Fragment key={driverUri}>
            <ListItem button onClick={() => {
              if (this.state.open === driverUri) {
                this.setState({
                  open: null,
                });
              } else {
                this.setState({
                  open: driverUri,
                });
              }
            }}>
              <ListItemIcon>
                {HomeyIcon(driver.iconObj)}
              </ListItemIcon>
              <ListItemText primary={driver.name}/>
              {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {driver.drivers.filter(driver => {
                  return !!driver.pair;
                }).map(driver => {
                  return (
                    <ListItem key={driver.id}
                              button
                              onClick={(el) => {
                                driver.ready && this.onPair({
                                  driverId: driver.id,
                                  driverUri: driver.uri,
                                  type: 'pair'
                                })
                              }}
                              disabled={!driver.ready}
                              style={{
                                paddingLeft: 32,
                                color: '#000',
                                opacity: (driver.ready && !driver.deprecated) ? 1 : 0.5
                              }}
                    >
                      <ListItemIcon>
                        {HomeyIcon(driver.iconObj)}
                      </ListItemIcon>
                      <ListItemText primary={driver.name + (driver.deprecated ? ' (Deprecated)' : '')}/>
                      {!driver.ready && (
                        <ListItemIcon>
                          <CircularProgress size={16}/>
                        </ListItemIcon>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          </React.Fragment>
        );
      }
      lists['pair'] = listItems;
    })();

    // type: repair
    (() => {
      const listItems = [];
      for (const deviceId in this.state.devices) {
        const device = this.state.devices[deviceId];
        if (!device.repair) continue;

        listItems.push(
          <ListItem
            key={deviceId}
            button
            onClick={(el) => {
              device.ready && this.onPair({
                deviceId: deviceId,
                driverId: device.driverId,
                driverUri: device.driverUri,
                type: 'repair'
              })
            }}
            disabled={!device.ready}
            style={{
              opacity: device.ready ? 1 : 0.5
            }}
          >
            <ListItemIcon>
              {HomeyIcon(device.iconObj)}
            </ListItemIcon>
            <ListItemText primary={device.name}/>
            {!device.ready && (
              <ListItemIcon>
                <CircularProgress size={16}/>
              </ListItemIcon>
            )}
          </ListItem>
        );
      }
      lists['repair'] = listItems;
    })();

    // type: unpair
    (() => {
      const listItems = [];
      for (const deviceId in this.state.devices) {
        const device = this.state.devices[deviceId];
        if (!device.unpair) continue;

        listItems.push(
          <ListItem
            key={deviceId}
            button
            onClick={(el) => {
              device.ready && this.onPair({
                deviceId: deviceId,
                driverId: device.driverId,
                driverUri: device.driverUri,
                type: 'unpair'
              })
            }}
            disabled={!device.ready}
            style={{
              opacity: device.ready ? 1 : 0.5
            }}
          >
            <ListItemIcon>
              {HomeyIcon(device.iconObj)}
            </ListItemIcon>
            <ListItemText primary={device.name}/>
            {!device.ready && (
              <ListItemIcon>
                <CircularProgress size={16}/>
              </ListItemIcon>
            )}
          </ListItem>
        );
      }
      lists['unpair'] = listItems;
    })();

    let content;
    const listItems = lists[this.state.type];
    if (listItems === null) {
      content = <p style={{ margin: '1em', color: 'black' }}>Loading...</p>
    } else if (listItems.length === 0) {
      content =
        <p style={{ textAlign: 'center', margin: '1em', color: 'black' }}>No devices that can be {this.state.type}ed are
          available.</p>
    } else {
      content = <List>{listItems}</List>;
    }

    return (
      <Page className="PageToolsPair" cards={true}>
        <Simulator
          ready={ready}
          backVisible={!!this._session}
          title={this.state.title}
          onBack={this.onSimulatorDone}
          onLoad={this.onSimulatorLoad}
          src={this.state.simulatorSrc}
          resolution={this.state.resolution}
          homeySoftwareVersion={this.props.homey.softwareVersion}
          homeyPlatform={this.props.homey.platform}
          controls={(
            <Card title="Pair Type">
              <FormControl
                margin="normal"
                fullWidth={true}
              >
                <Select
                  value={this.state.type}
                  disabled={!!this._session}
                  onChange={event => {
                    this.setState({
                      type: event.target.value,
                      title: this._titles[event.target.value],
                    })
                  }}>
                  <MenuItem value="pair">Pair</MenuItem>
                  <MenuItem value="repair">Repair</MenuItem>
                  <MenuItem value="unpair">Unpair</MenuItem>
                </Select>
              </FormControl>
            </Card>
          )}
        >
          {content}
        </Simulator>
      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsPair, { version: '>=2.0.0' })))));
