import './style.css';
import React, { Component } from 'react';
import classNames from 'classnames';
import { BrowserRouter, Route, NavLink } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import {
  withApi,
} from '../../services/AthomApi';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountIcon from '@material-ui/icons/AccountCircle';

import PageHome from '../PageHome';
import PageMe from '../PageMe';
import PageWebhooks from '../PageWebhooks';
import PageToolsSettings from '../PageToolsSettings';
import PageToolsSignals from '../PageToolsSignals';
import PageToolsDevices from '../PageToolsDevices';
import PageToolsPair from '../PageToolsPair';
import PageToolsAppSettings from '../PageToolsAppSettings';
import PageToolsAppProfiling from '../PageToolsAppProfiling';
import PageToolsZwave from '../PageToolsZwave';
import PageToolsZigbee from '../PageToolsZigbee';
import PageToolsBLE from '../PageToolsBLE';
import PageToolsSpeech from '../PageToolsSpeech';
import PageToolsImages from '../PageToolsImages';
import PageToolsAPIPlayground from '../PageToolsAPIPlayground';
import PageToolsSystem from '../PageToolsSystem';
import PageToolsLEDRing from '../PageToolsLEDRing';
import PageToolsIR from '../PageToolsIR';
import PageAPIProjects from '../PageAPIProjects';
import PageApps from '../PageApps';
import PageAppsReview from '../PageAppsReview';
import PageAppsApp from '../PageAppsApp';
import PageAppsAppBuild from '../PageAppsAppBuild';
import PageAppsAppCrashes from '../PageAppsAppCrashes';
import PagePushNotifications from '../PagePushNotifications';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import iconVerified from './icons/verified.svg'
import { default as maskZigbee } from './icons/technology-zigbee.svg'
import { default as maskBluetooth } from './icons/technology-bluetooth.svg'
import { default as maskZWave } from './icons/technology-z-wave.svg'
import { default as maskInfrared } from './icons/technology-infrared.svg'
import { default as mask433mhz } from './icons/technology-433mhz.svg'
import { default as maskLogOut } from './icons/log-out.svg'
import { default as maskApps } from './icons/grid-view-medium.svg'

import {
  faHome,
  faToolbox,
  faTachometerAlt,
  faShapes,
  faCloud,
  faLink,
  faSignOutAlt,
  faUserCircle,
  faSignature,
  faThLarge,
  faWifi,
  faCog,
  faComments,
  faSlidersH,
  faImages,
  faMicrochip,
  faPlug,
  faPlusSquare,
  faHeartbeat,
  faCommentAlt,
  faCloudDownloadAlt,
  faBookOpen,
  faSeedling,
  faTasks,
  faCode,
  faCheckDouble,
} from '@fortawesome/free-solid-svg-icons'

import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faHome,
  faToolbox,
  faTachometerAlt,
  faShapes,
  faCloud,
  faLink,
  faSignOutAlt,
  faUserCircle,
  faSignature,
  faThLarge,
  faWifi,
  faCog,
  faComments,
  faSlidersH,
  faImages,
  faMicrochip,
  faPlug,
  faPlusSquare,
  faHeartbeat,
  faCommentAlt,
  faCloudDownloadAlt,
  faCircle,
  faBookOpen,
  faSeedling,
  faTasks,
);

const theme = createTheme({
  spacing: 10,
  palette: {
    type: 'dark',
    primary: {
      main: 'rgba(  0, 130, 250, 1.00)',
    },
    secondary: {
      main: 'rgb(250, 100, 0)',
    },
    grey: {},
    white: {
      main: '#',
    },
    background: {
      paper: '#222329',
      default: '#121319'
    }
  },
});

const drawerWidth = 270;
const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 20, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  activeUser: {
    margin: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  activeHomey: {
    margin: 20,
    marginTop: 0,
  },
  menuButton: {
    marginLeft: 20,
    marginRight: 30,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    border: 'none',
    boxShadow: `
    0 10px 44px  0 rgba(0,0,0, 0.14),
    0 2px  12px  0 rgba(0,0,0, 0.2),
    0 2px  4px   0 rgba(0,0,0, 0.08),
    0 0    1px   0 rgba(255,255,255, 0.4)
  `,
    borderRadius: '10px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'static',
    overflow: 'auto',
  },
  contentSpacer: {
    ...theme.mixins.toolbar,
    flexShrink: 0,
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  logoutLink: {
    color: '#999',
    '&:hover': {
      color: 'rgba(  0, 130, 250, 1.00)',
      cursor: 'pointer',
    }
  },
});

class ListItemNavLink extends Component {
  render() {
    return (
      <NavLink
        className="NavLink"
        activeClassName="Active"
        exact
        title={this.props.title}
        to={this.props.to}
      >
        <span className="NavLink__icon">
          {getIcon(this.props.icon)}
        </span>
        <span className="NavLink__text">{this.props.title}</span>
      </NavLink>
    );
  }
}

function getIcon(icon, size, color) {
  const masks = {
    'maskZigbee': maskZigbee,
    'maskBluetooth': maskBluetooth,
    'maskZWave': maskZWave,
    'maskInfrared': maskInfrared,
    'mask433mhz': mask433mhz,
    'log-out': maskLogOut,
    'apps': maskApps,
  }

  if (icon in masks) {
    return <span className={"mask-icon"}
      style={{ MaskImage: `url(${masks[icon]})`, WebkitMaskImage: `url(${masks[icon]})`, "--mask-size": size, "--mask-color": color }} />
  } else {
    return <FontAwesomeIcon style={{ "width": 20, "height": 20 }} fixedWidth icon={icon} />
  }
}

class TitleDivider extends Component {
  render() {
    return (
      <div className="TitleDivider"><span>{this.props.title}</span></div>
    )
  }
}

// class ListItemNavExternalLink extends Component {
//   render() {
//     return (
//       <a
//         className="NavLink"
//         target="_blank"
//         rel="noopener noreferrer"
//         href={this.props.to}
//       >
//         <span className="NavLink__icon">
//           <FontAwesomeIcon style={{ "width": 20, "height": 20 }} fixedWidth icon={this.props.icon}/>
//         </span>
//         <span className="NavLink__text">{this.props.title}</span>
//       </a>
//     );
//   }
// }

class UserAvatar extends Component {
  render() {
    return <Avatar
      className={"NavAvatar"}
      src={this.props.src}
      title={this.props.title}
    />
  }
}

class App extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      drawerOpen: !(window.localStorage && window.localStorage.getItem('drawerOpen') === '0'),
      activeHomeyId: '',
      listToolsOpen: (window.location.pathname.indexOf('/tools') === 0),
      listAppsOpen: (window.location.pathname.indexOf('/docs/apps') === 0 || window.location.pathname.indexOf('/apps') === 0),
      listDocsApiOpen: (window.location.pathname.indexOf('/docs/api') === 0),
      user: null,
    }
  }

  onHomeyChange = () => {
    this.setState({
      homey: null,
    });
    this._setHomey();
  }

  _setHomey = () => {
    this.props.api.homey.then(homey => {
      this.setState({ homey });
    }).catch(err => {
      console.log('_setHomeyError', err)
    })
  }

  componentDidMount() {
    this.props.api.user.then(user => {
      this.setState({ user });
    }).catch(err => {
      if (err.message === 'not_logged_in') {
        this.setState({
          user: false,
        })
      }
    });

    this._setHomey();
    this.props.api.registerActiveHomeyChangeListener(this.onHomeyChange)
    this.props.api.registerActiveHomeyChangeListener(this.activeHomeyChangeListener);
  }

  componentWillUnmount() {
    this.props.api.unregisterActiveHomeyChangeListener(this.activeHomeyChangeListener());
  }

  activeHomeyChangeListener = activeHomeyId => {
    this.setState({
      activeHomeyId,
    });
  };

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
    if (window.localStorage)
      window.localStorage.setItem('drawerOpen', '1');
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
    if (window.localStorage)
      window.localStorage.setItem('drawerOpen', '0');
  };

  handleDrawerToggle = () => {
    if (this.state.drawerOpen) {
      this.handleDrawerClose();
    } else {
      this.handleDrawerOpen();
    }
  }

  handleActiveHomeyChange = event => {
    this.setState({
      activeHomeyId: event.target.value,
    });
    this.props.api.setActiveHomey(event.target.value);
  }

  handleListToolsClick = () => {
    this.setState({
      listToolsOpen: !this.state.listToolsOpen,
    });
  }

  handleListDocsAppsClick = () => {
    this.setState({
      listAppsOpen: !this.state.listAppsOpen,
    });
  }

  handleListDocsApiClick = () => {
    this.setState({
      listDocsApiOpen: !this.state.listDocsApiOpen,
    });
  }

  handleLogin = () => {
    this.props.api.login();
  }

  handleLogout = () => {
    this.props.api.logout();
    window.location.reload().then(() => {
      window.location.reload();
    });
  }

  handleAccount = () => {
    this.props.api.createDelegationToken({}).then(token => {
      window.open(`https://accounts.athom.com/profile/${this.state.user._id}?user_token=${token}`);
    });
  }

  handleApps = () => {
    const { user } = this.state;
    window.open(`https://apps.athom.com/search?author=${user._id}`);
  }

  render() {
    const {
      drawerOpen,
      user,
    } = this.state;

    const {
      classes,
    } = this.props;

    let userElement = [];
    let userHomeys = [];
    if (user) {
      userHomeys = user.homeys
        .filter(homey => {
          return true;
        })
        .map(homey => {
          return (
            <MenuItem
              value={homey._id}
              key={homey._id}
            >
              {homey.name}
            </MenuItem>
          );
        });
      userElement.push(
        <FormControl className={classes.activeUser} key="logged_in">
          <UserAvatar src={user.avatar.medium} title={user.fullname} />
          <div className={"NavAvatarTitle"}>
            <Typography title={user.fullname} variant="subtitle1">
              {user.fullname}
              {user.roleIds.includes('app_developer_trusted')
                ? <img src={iconVerified} style={{
                  height: '1em',
                  marginLeft: '5px',
                  marginBottom: '-2px',
                }} />
                : null
              }</Typography>
            <button className="LogOutLink" onClick={this.handleLogout}>
              {getIcon('log-out', '16px')} Logout
            </button>
          </div>
        </FormControl>
      )

      userElement.push(
        <FormControl className={classes.activeHomey} key="homeys" style={{ overflow: 'hidden' }}>
          <Select
            value={this.state.activeHomeyId}
            onChange={this.handleActiveHomeyChange}
            inputProps={{
              name: 'homey',
              id: 'select-homey',
            }}

            displayEmpty
          >
            <MenuItem value="" disabled>{user === null ? 'Loading...' : 'Select a Homey'}</MenuItem>
            {userHomeys}
          </Select>
        </FormControl>
      )
    } else if (user === false) {
      userElement.push(
        <FormControl className={classes.activeUser} key="not_logged_in">
          {drawerOpen && (
            <Button
              variant={'contained'}
              color="primary"
              onClick={this.handleLogin}
              fullWidth={true}
              size="small"
            >
              <AccountIcon className={classes.leftIcon} />
              Log in
            </Button>
          )}
          {!drawerOpen && (
            <IconButton onClick={this.handleLogin} edge="start">
              <AccountIcon />
            </IconButton>
          )}
        </FormControl>
      );
    } else {
      userElement.push(
        <FormControl className={classes.activeUser} key="loading">
          <UserAvatar src="https://avatars.athom.com/default/medium.jpg" />
          <Typography variant="subtitle1">Loading...</Typography>
        </FormControl>
      )
    }

    const selectedHomeyIsCloud = this.state.homey && this.state.homey.platform && this.state.homey.platform === 'cloud';
    const selectedHomeyIsHomeyPro = this.state.homey && this.state.homey.platform === 'local';
    const selectedHomeyIsHomeyProV1 = this.state.homey && this.state.homey.platform === 'local' && this.state.homey.platformVersion === 1;
    const selectedHomeyIsHomeyProV2 = this.state.homey && this.state.homey.platform === 'local' && this.state.homey.platformVersion === 2;

    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            <div className={classes.root}>
              <Drawer
                className={classes.drawer}
                classes={{
                  paper: classNames(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose, !drawerOpen && 'is-nav-collapsed'),
                }}
                style={{ 'margin': '20px' }}
                open={drawerOpen}
                variant="permanent"
                anchor="left"
              >

                <div
                  style={{ display: 'flex', alignItems: 'center', padding: '20px', margin: 0, cursor: 'pointer', }}
                  onClick={this.handleDrawerToggle}
                >

                  <img className={"NavLogo"} alt="logo" src="https://etc.athom.com/logo/transparent/128.png" />

                  <Typography variant="subtitle1" style={{
                    marginLeft: 20,
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: 1,
                  }}>Developer Tools</Typography>

                </div>

                <Divider />

                {userElement}


                <List style={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <Divider />
                  <div className={"NavGroup"}>
                    <ListItemNavLink to="/" icon={"home"} title={"Home"} />
                    <ListItemNavLink to="/me" className={classes.nested} icon={"user-circle"} title={"My Account"} />
                    <ListItemNavLink to="/apps" className={classes.nested} icon={"shapes"} title={"My Apps"} />
                    <ListItemNavLink to="/webhooks" className={classes.nested} icon={"link"} title={"My Webhooks"} />
                  </div>

                  <TitleDivider title={"Tools"} />
                  <div className={"NavGroup"}>
                    <ListItemNavLink to="/tools/system" className={classes.nested} icon="microchip" title={"System"} />
                    <ListItemNavLink to="/tools/pair" className={classes.nested} icon="plus-square" title={"Pairing"} />
                    <ListItemNavLink to="/tools/devices" className={classes.nested} icon="plug" title={"Devices"} />
                    <ListItemNavLink to="/tools/images" className={classes.nested} icon={"images"} title={"Images"} />

                    {selectedHomeyIsHomeyPro && (
                      <ListItemNavLink to="/tools/app-settings" className={classes.nested} icon={"cog"} title={"App Settings"} />
                    )}

                    {selectedHomeyIsHomeyPro && (
                      <ListItemNavLink to="/tools/app-profiling" className={classes.nested} icon={"tachometer-alt"} title={"App Performance"} />
                    )}

                    {selectedHomeyIsHomeyProV1 && (
                      <ListItemNavLink to="/tools/ledring" className={classes.nested} icon={faCircle} title={"LED Ring"} />
                    )}

                    {selectedHomeyIsHomeyProV1 && (
                      <ListItemNavLink to="/tools/speech" className={classes.nested} icon={"comments"} title={"Speech"} />
                    )}

                    {selectedHomeyIsHomeyPro && (
                      <ListItemNavLink to="/tools/settings" className={classes.nested} icon={"sliders-h"} title={"Settings"} />
                    )}
                  </div>


                  <TitleDivider title={"Wireless"} />
                  <div className={"NavGroup"}>
                    <ListItemNavLink to="/tools/ble" className={classes.nested} icon={"maskBluetooth"}
                      title={"Bluetooth LE"} />
                    <ListItemNavLink to="/tools/ir" className={classes.nested} icon={"maskInfrared"}
                      title={"Infrared"} />
                    <ListItemNavLink to="/tools/signals" className={classes.nested} icon={"mask433mhz"}
                      title={"RF signals"} />
                    <ListItemNavLink to="/tools/zigbee" className={classes.nested} icon={"maskZigbee"}
                      title={"Zigbee"} />
                    <ListItemNavLink to="/tools/zwave" className={classes.nested} icon={"maskZWave"} title={"Z-Wave"} />
                  </div>

                  <TitleDivider title={"Web API"} />
                  <div className={"NavGroup"}>
                    <ListItemNavLink to="/tools/api-playground" className={classes.nested} icon={faCode}
                      title={"Web API Playground"} />
                  </div>

                  {user && (user.roleIds.includes('admin') || user.roleIds.includes('marketeer') || user.roleIds.includes('app_reviewer')) &&
                    <>
                      <TitleDivider title={"Employees"} />
                      <div className={"NavGroup"}>
                        {user && (user.roleIds.includes('admin') || user.roleIds.includes('marketeer')) &&
                          <ListItemNavLink to="/push-notifications" icon={"comment-alt"} title={"Push Notifications"} />
                        }
                        {user && user.roleIds.includes('app_reviewer') &&
                          <ListItemNavLink to="/apps/review" icon={faCheckDouble} title={"Review Apps"} />
                        }
                      </div>
                    </>
                  }

                  <div className="FlexGrow" />

                  <div className={classes.toolbarIcon}>
                    <IconButton onClick={this.handleDrawerToggle} edge="start">
                      {drawerOpen && <ChevronLeftIcon />}
                      {!drawerOpen && <ChevronRightIcon />}
                    </IconButton>
                  </div>

                </List>
              </Drawer>
              <main className={classes.content}>
                <Route exact path="/" component={PageHome} />
                <Route path="/me" component={PageMe} />
                <Route path="/webhooks" component={PageWebhooks} />
                <Route path="/apps" exact component={PageApps} />
                <Route path="/apps/review" exact component={PageAppsReview} />
                <Route path="/apps/app/:appId" exact component={PageAppsApp} />
                <Route path="/apps/app/:appId/build/:buildId" exact component={PageAppsAppBuild} />
                <Route path="/apps/app/:appId/build/:buildId/crashes" exact component={PageAppsAppCrashes} />
                <Route path="/tools/signals" component={PageToolsSignals} />
                <Route path="/tools/devices" component={PageToolsDevices} />
                <Route path="/tools/pair" component={PageToolsPair} />
                <Route path="/tools/app-settings" component={PageToolsAppSettings} />
                <Route path="/tools/app-profiling" component={PageToolsAppProfiling} />
                <Route path="/tools/zwave" component={PageToolsZwave} />
                <Route path="/tools/zigbee" component={PageToolsZigbee} />
                <Route path="/tools/ble" component={PageToolsBLE} />
                <Route path="/tools/ir" component={PageToolsIR} />
                <Route path="/tools/speech" component={PageToolsSpeech} />
                <Route path="/tools/images" component={PageToolsImages} />
                <Route path="/tools/api-playground" component={PageToolsAPIPlayground} />
                <Route path="/tools/system" component={PageToolsSystem} />
                <Route path="/tools/settings" component={PageToolsSettings} />
                <Route path="/tools/ledring" component={PageToolsLEDRing} />
                <Route path="/api/projects" component={PageAPIProjects} />
                <Route path="/push-notifications" component={PagePushNotifications} />
              </main>
            </div>
          </SnackbarProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default withApi(withStyles(styles, { withTheme: true })(App));
