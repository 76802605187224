import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import {
	withApi,
	withUser,
	withHomey,
	withMessages,
} from '../../services/AthomApi';
import Card from '../Card';
import Page from '../Page';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, Label } from 'recharts';
import qs from 'query-string';

const titleMap = {
	'mem_pss': 'Memory (PSS)',
	'mem_rss': 'Memory (RSS)',
	'cpu': 'CPU',
}
const tipsMap = {
	'mem_rss': `RSS (Resident Set Size) is the total memory size of the app, including memory shared with other apps.
— When the RSS is too high, the app might be killed by Homey Pro.`,
	'mem_pss': `PSS (Proportional Set Size) is the memory size of the app, excluding memory shared with other apps.
— An Homey App should not use more than 30MB of memory.
— Reduce the number of Node.js modules to use less memory.`,
	'cpu': `— An Homey App should not use more than 1% of CPU.
— Short peak performance is allowed.`,
}

class PageToolsProfiling extends Component {

	constructor(props) {
		super(props);

		let activeAppId = '';
		if (this.props.location.search) {
			const { app } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
			if (app) activeAppId = app;
		}

		this.state = {
			apps: {},
			menuOpen: !activeAppId,
			activeAppId,
			mem_rss: [],
			mem_pss: [],
			cpu: [],
		}
	}

	componentDidMount() {
		this._onTickInterval = setInterval(this._onTick, 1000);

		this.props.homey.apps
			.on('apps.create', this.getApps)
			.on('apps.update', this.getApps)
			.on('apps.delete', this.getApps)
			.connect()
			.catch(this.props.handleError)
			.finally(() => this.getApps());
	}

	componentWillUnmount() {
		if (this.props.homey) {
			this.props.homey.apps.destroy();
		}

		if (this._onTickInterval)
			clearInterval(this._onTickInterval);
	}

	getApps = () => {
		this.props.homey.apps.getApps().then(apps => {
			this.setState({ apps });
		}).catch(this.props.handleError)
	}

	_onTick = () => {
		if (!this.state.activeAppId) return;

		if (this.props.homey.apps.getAppUsage) {
			// Homey Pro (Early 2023)
			this.props.homey.apps.getAppUsage({
				id: this.state.activeAppId
			})
				.then(usage => {
					this._logDataPoint('mem_pss', usage.mem.pssTotal);
					this._logDataPoint('mem_rss', usage.mem.rss);
					this._logDataPoint('cpu', usage.cpu.percentage);
				})
				.catch(this.props.handleError)
		} else {
			// Homey Pro (2016 — 2019)
			this.props.homey.apps.getApp({
				$cache: false,
				id: this.state.activeAppId
			})
				.then(app => {
					if (app.usage) {
						this._logDataPoint('mem_pss', app.usage.mem);
						this._logDataPoint('cpu', app.usage.cpu);
					}
				})
				.catch(this.props.handleError)
		}
	}

	handleSelectChange = event => {
		this.setState({
			activeAppId: event.target.value,
			mem: [],
			cpu: [],
		});
	}

	handleSelectOpen = event => {
		this.setState({
			menuOpen: true,
		});
	}

	handleSelectClose = event => {
		this.setState({
			menuOpen: false,
		});
	}

	_logDataPoint = (type, value) => {
		let newValue = this.state[type].slice();
		newValue.push({
			name: this.state[type].length,
			Value: value
		});

		let newState = {};
		newState[type] = newValue;

		this.setState(newState)
	}

	_onFormatValue = (type, value) => {
		if (type === 'mem_rss')
			return humanFileSize(value, true);

		if (type === 'mem_pss')
			return humanFileSize(value, true);

		if (type === 'cpu')
			return value.toFixed(1) + '%';
	}

	_onFormatLabel = (type, value) => {
		return titleMap[type];
	}

	render() {
		const {
			activeAppId,
			apps,
		} = this.state;

		const menuItems = [];
		for (let appId in apps) {
			let app = apps[appId];

			menuItems.push((
				<MenuItem
					key={appId}
					value={appId}
				>
					{appId}@{app.version}
				</MenuItem>
			));
		}

		const charts = [];
		activeAppId && ['mem_rss', 'mem_pss', 'cpu'].forEach(type => {
			if (!this.state[type]) return;

			let chart;
			if (this.state[type].length === 0) {
				chart = <Typography variant="body2">No usage data available yet.</Typography>
			} else {
				chart = <div
					style={{ height: 250 }}
				>
					<ResponsiveContainer>
						<AreaChart data={this.state[type]}>
							<XAxis
								dataKey="name"
								label={
									<Label value="T minus (s)" style={{ textAnchor: 'middle', fontSize: '90%', fill: '#fff' }} />
								}
								minTickGap={50}
								stroke="#fff"
								tick={{ fontSize: 10 }} />
							<YAxis
								domain={[0, 100]}
								tickFormatter={this._onFormatValue.bind(this, type)}
								stroke="#fff"
								tick={{ fontSize: 10 }} />
							<Tooltip
								label="Label"
								// wrapperStyle={{ backgroundColor: "#AAA", border: 'none' }}
								labelStyle={{ color: "#000", fontWeight: '700' }}
								itemStyle={{ color: "#000" }}
								labelFormatter={this._onFormatLabel.bind(this, type)}
								formatter={this._onFormatValue.bind(this, type)}
								separator=": " />
							<Area
								isAnimationActive={false}
								type="monotone"
								dataKey="Value"
								fillOpacity={1}
								strokeWidth={2}
								stroke="rgba(0, 130, 250, 1.00)"
								fill="rgba(0, 130, 250, 0.1)"

							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			}

			charts.push((
				<Card key={type} title={titleMap[type]}>
					{chart}
					{this.state[type].length > 0 && (
						<React.Fragment>
							<Typography variant="body1" style={{ whiteSpace: 'pre' }}>{tipsMap[type]}</Typography>
						</React.Fragment>
					)}
				</Card>
			));
		});

		return (
			<Page className="PageToolsProfiling" cards={true}>
				<Card style={{ position: 'relative' }}>
					<Select
						open={this.state.menuOpen}
						value={activeAppId}
						onChange={this.handleSelectChange}
						onOpen={this.handleSelectOpen}
						onClose={this.handleSelectClose}
						fullWidth={true}
					>
						<MenuItem
							value=""
							disabled
						>
							Select an Homey App
						</MenuItem>
						{menuItems}
					</Select>
				</Card>

				{charts}
			</Page>
		);
	}
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsProfiling)))));

function humanFileSize(bytes, si) {
	var thresh = si ? 1000 : 1024;
	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}
	var units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	var u = -1;
	do {
		bytes /= thresh;
		++u;
	} while (Math.abs(bytes) >= thresh && u < units.length - 1);
	return bytes.toFixed(1) + ' ' + units[u];
}
