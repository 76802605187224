import './style.css';
import React, { Component } from 'react';
import {
  Divider,
  Card,
  CardHeader,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Button,
  CardContent,
  CircularProgress,
} from '@material-ui/core';
import { ChevronRight, Info } from '@material-ui/icons';

class ServiceView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDiscoveringCharacteristics: false,
    }
  }

  componentDidMount() {
    const { scrollRef } = this.props;
    scrollRef.scrollLeft = 9999;
  }

  render() {
    const { service, peripheral, runCommand, selectCharacteristic, selectedCharacteristicUuid } = this.props;
    const { characteristics } = service;

    const discoverCharacteristics = () => {
      this.setState({ isDiscoveringCharacteristics: true });
      runCommand("DISCOVER_CHARACTERISTICS", {
        peripheralUuid: peripheral.uuid,
        serviceUuid: service.uuid
      })
        .finally(() => {
          this.setState({ isDiscoveringCharacteristics: false });
        });
    }

    if (service) {
      return (
        <Card className="ble-card">
          <CardHeader title={<span> {service.name || "unknown"} </span>} subheader="Service" />
          <Divider />
          <CardContent>
            <Button
              fullWidth
              variant={"contained"}
              disabled={this.state.isDiscoveringCharacteristics}
              color="primary"
              onClick={discoverCharacteristics}>
              Discover Characteristics
              {(this.state.isDiscoveringCharacteristics) && <CircularProgress className="circularProgress" size={24} />}
            </Button>
          </CardContent>


          <Divider />

          {/* Services */}
          <List subheader={<ListSubheader>Characteristics</ListSubheader>}>

            {characteristics.length ? characteristics.map(characteristic => {
              return (
                <ListItem
                  button
                  key={peripheral.uuid + service.uuid + characteristic.uuid}
                  selected={characteristic.uuid === selectedCharacteristicUuid}
                  onClick={() => { selectCharacteristic(characteristic) }}
                >
                  <ListItemText
                    primary={<Typography> {characteristic.name || 'Vendor'} <code> {characteristic.uuid} </code> </Typography>}
                    secondary={<Typography color="primary" variant="overline"> {characteristic.subscribedApp ? `Subscribed by ${characteristic.subscribedApp}` : ''} </Typography>}
                  />
                  <ChevronRight />
                </ListItem>
              );
            }) : (<ListItem> <ListItemText primary={<Typography> No characteristics </Typography>} /> </ListItem>)
            }
          </List>
          <Divider />

          {/* Details */}
          <List component="div" disablePadding subheader={<ListSubheader>Details</ListSubheader>}>
            <ListItem>
              <ListItemText
                primary={<Typography> Uuid </Typography>}
                secondary={
                  service.uuidIsVendorSpecific ? (
                    <Typography>
                      <code>
                        {service.uuid}
                      </code>
                    </Typography>
                  ) : (
                    <Typography>
                      <code>0000</code>
                      <code style={{ color: "rgba(  0, 130, 250, 1.00)", fontWeight: 600 }}>{service.uuid}</code>
                      <code>00001000800000805f9b34fb</code>
                    </Typography>
                  )} />
              {!service.uuidIsVendorSpecific ? (
                <ListItemSecondaryAction>
                  <Tooltip title={<Typography> Use 128bit (long) uuids in your app. 16-bit and 32-bit uuids are deprecated and support for them will be removed in the future. </Typography>}>
                    <IconButton aria-label="delete">
                      <Info />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          </List>

        </Card>
      );
    }
    return null;
  }
}

export default ServiceView;
