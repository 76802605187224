import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export default class Card extends Component {
	render() {
		return (
			<Paper style={{
				padding: 20,
				marginBottom: 40,
				clear: 'both',
			}} elevation={2}>
				{(this.props.title || this.props.toolbar) && (
					<Grid
						container
						direction="row"
						justifyContent="space-between"
					>
						<Grid item>
							{this.props.title && (
								<Typography variant="h5" gutterBottom={true}>{this.props.title}</Typography>
							)}
							{this.props.subtitle && (
								<Typography variant="body2" gutterBottom={true}>{this.props.subtitle}</Typography>
							)}
						</Grid>
						<Grid item>
							{this.props.toolbar}
						</Grid>
					</Grid>
				)}
				{this.props.children}
			</Paper>
		);
	}
}
