import './style.css';
import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	withApi,
	withUser,
	withHomey,
	withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import Card from '../Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FileSaver from 'file-saver';
import Button from '@material-ui/core/Button';
import IconPlay from '@material-ui/icons/PlayArrow';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faDownload,
	faFile,
} from '@fortawesome/free-solid-svg-icons';

library.add(
	faDownload,
	faFile,
);

const StyledSelect = withStyles({
	root: {
		'&:before': {
			borderBottomColor: 'white',
		},
	},
	select: {
		color: 'white',
	},
	icon: {
		color: 'white',
	},
})(Select);

class PageToolsSignals extends Component {

	constructor(props) {
		super(props);

		this.state = {
			knobValue: 0,
			recording: false,
			sessionId: null,
			timeout: 5,
			recordFrequency: '433',
			result: null,

			emulateValue: '',
			emulateFrequency: '433',

			homeyPlayform: this.props.homey
				? this.props.homey.platform
				: null,
			homeyPlayformVersion: this.props.homey
				? this.props.homey.platformVersion
				: null,
		}
	}

	handleRecord = () => {
		if (this.state.recording) {
			this.stopRecording();
		} else {
			this.startRecording();
		}
	}

	startRecording = () => {

		let timeout = this.state.timeout;
		let stepSize = 0.05 / timeout;

		this.setState({
			recording: true,
			knobValue: 0,
			result: null
		});

		if (this.updateKnobInterval)
			clearInterval(this.updateKnobInterval);

		this.updateKnobInterval = setInterval(() => {
			this.setState({
				knobValue: this.state.knobValue + stepSize
			})
		}, 50);

		this.props.homey.rf.record({
			$timeout: 7000,
			timeout: 5,
			frequency: this.state.recordFrequency
		})
			.then(result => {
				this.setState({ result });
			})
			.catch(this.props.handleError)
			.then(() => {
				this.stopRecording();
			})

	}

	stopRecording = () => {

		this.setState({
			recording: false
		});

		if (this.updateKnobInterval)
			clearInterval(this.updateKnobInterval);

		this.setState({
			knobValue: 0,
			sessionId: null
		});

	}

	onKnobChange = () => {

	}

	handleRecordFrequencyChange = event => {
		this.setState({
			recordFrequency: event.target.value,
		})
	}

	handleEmulateFrequencyChange = event => {
		this.setState({
			emulateFrequency: event.target.value,
		})
	}

	handleEmulateRun = event => {
		const {
			emulateValue,
			emulateFrequency,
		} = this.state;

		const frequency = emulateFrequency;
		const data = emulateValue.split(',').map(part => {
			return parseInt(part.trim(), 10);
		}).filter(value => !isNaN(value));

		this.props.homey.rf.emulate({
			data,
			frequency,
		})
			.then(() => this.props.handleSuccess('OK'))
			.catch(this.props.handleError)
	}

	handleEmulateValue = event => {
		this.setState({
			emulateValue: event.target.value,
		});
	}

	recordingToVcd = (recordings) => {

		let output = [];
		output.push('$timescale 1 us $end');
		output.push('$scope module Homey $end');
		output.push('$var wire 1 ! Recording $end');
		output.push('$upscope $end');
		output.push('$enddefinitions $end');

		let delta = 0;
		recordings.forEach(recording => {
			let bit = false;
			Object.values(recording).forEach(change => {
				output.push('#' + delta);

				if (bit) {
					output.push('0!');
				} else {
					output.push('1!');
				}

				bit = !bit;
				delta += change;
			});
		});

		return output.join('\n');
	}

	onSaveClick = () => {
		let vcd = this.recordingToVcd(this.state.result)
		let blob = new Blob([vcd], { type: "text/plain;charset=us-ascii" });
		FileSaver.saveAs(blob, 'recording.vcd');
	}


	render() {

		let resultDiv;
		if (this.state.recording) {
			resultDiv = <Typography variant="body1" className="Result">
				{`Recording... press a button on your device's remote.`}
			</Typography>
		} else {
			if (this.state.result) {
				if (this.state.result.length) {
					resultDiv = <div className="Result">
						<FontAwesomeIcon icon="file" style={{
							width: '80px',
							height: '80px',
							margin: '10px',
							color: '#666'
						}} />
						<div style={{ marginBottom: '1em' }}>
							<Button
								variant="contained"
								color="primary"
								onClick={this.onSaveClick}
							>
								<FontAwesomeIcon fixedWidth icon="download" />
								Download as .vcd
							</Button>
						</div>
						<Typography variant="body1">
							Download <a href="http://gtkwave.sourceforge.net/" target="_blank" rel="noopener noreferrer">GTKWave</a> to open the recording file.
						</Typography>

						<TextField
							value={this.state.result.map(entry => {
								return Object.values(entry).join(',');
							}).join('\n')}
							minRows={10}
							maxRows={10}
							label="Raw timings"
							fullWidth
							multiline
						/>

					</div>
				} else {
					resultDiv = <Typography variant="body1" className="Result">
						{`Homey did not record any transmissions.`}
					</Typography>
				}
			}
		}

		return (
			<Page
				cards={true}
				className="PageToolsSignals"
			>
				<Card
					title="Record a Signal"
					subtitle="Select a frequency, then click the record button."
				>

					<div className="Recorder Flex FlexRow">
						<div
							className={classNames('Record', { 'Recording': this.state.recording })}
							onClick={this.handleRecord}
						>
							<CircularProgress
								variant="determinate"
								value={this.state.knobValue * 100}
								color="secondary"
								style={{
									width: 80,
									height: 80,
								}}
							/>
						</div>

						<StyledSelect
							value={this.state.recordFrequency}
							onChange={this.handleRecordFrequencyChange}
						>
							<MenuItem value="433">433 MHz</MenuItem>
							{this.state.homeyPlayform === 'local' && this.state.homeyPlayformVersion === 1 && <MenuItem value="868">868 MHz</MenuItem>}
							{this.state.homeyPlayform === 'local' && <MenuItem value="ir">Infrared</MenuItem>}
						</StyledSelect>
					</div>
					{resultDiv}
				</Card>
				<Card
					title="Emulate a Signal"
					subtitle="Paste a signal, select a frequency and then click the run button."
					toolbar={(
						<React.Fragment>
							<Select
								value={this.state.emulateFrequency}
								onChange={this.handleEmulateFrequencyChange}
							>
								<MenuItem value="433">433 MHz</MenuItem>
								{this.state.homeyPlayform === 'local' && this.state.homeyPlayformVersion === 1 && <MenuItem value="868">868 MHz</MenuItem>}
								{this.state.homeyPlayform === 'local' && <MenuItem value="ir">Infrared</MenuItem>}
							</Select>
							&nbsp;
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleEmulateRun}
							>
								<React.Fragment>
									<IconPlay />
									&nbsp;Emulate
								</React.Fragment>
							</Button>
						</React.Fragment>
					)}
				>

					<TextField
						value={this.state.emulateValue}
						onChange={this.handleEmulateValue}
						placeholder="100,300,100,..."
						minRows={10}
						maxRows={10}
						label="Raw timings"
						fullWidth
						multiline
					/>

				</Card>
			</Page>
		);
	}
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsSignals)))));
