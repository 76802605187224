import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withApps,
  withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import PageCard from '../Card';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControl from '@material-ui/core/FormControl';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Typography from '@material-ui/core/Typography';

import { compare } from 'semver';

import './style.css';
import CircularProgress from "@material-ui/core/CircularProgress";

const ROWS_PER_PAGE = 100;

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onPageChange(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onPageChange(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

class TableSortLabelActions extends Component {
  state = {
    sortBy: this.props.active,
    sortOrder: this.props.direction,
    sortDirection: 'asc',
    title: this.props.title,
    id: this.props.id
  }

  handleRequestSort = event => {
    const sortDirection = (String(this.state.sortDirection) === 'desc') ? 'asc' : 'desc';

    this.props.action(sortDirection, this.props.id);
    this.setState({ sortDirection });
  };

  render() {
    const { sortBy, sortOrder, title, id } = this.props;
    const { sortDirection } = this.state;

    return (
      <TableCell
        key={id}
        sortDirection={sortBy === id ? sortOrder : false}>
        <TableSortLabel
          active={sortBy === id}
          direction={sortDirection}
          onClick={this.handleRequestSort}
        >
          {title}
        </TableSortLabel>
      </TableCell>
    )
  };
}

TableSortLabelActions.propTypes = {
  active: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

const TableSortLabelActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TableSortLabelActions,
);

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class PageApps extends Component {
  state = {
    appsPage: 0,
    sortOrder: 'asc',
    sortBy: 'name',
    createAppDialogOpen: false
  }

  handleCreateAppDialogOpen = () => {
    this.setState({
      createAppDialogOpen: true,
    });
  }

  handleCreateAppDialogClose = () => {
    this.setState({
      createAppDialogOpen: false,
    });
  }

  handleRefreshMyApps = () => {
    this.props.callApps('getMyApps').then(apps => {
      apps = apps
        .map(app => {
          const build = app['liveBuild'] || app['testBuild'];
          if (!build) {
            return {
              id: app.id,
              name: app.id,
              icon: '',
              liveVersion: '0.0.0',
              testVersion: '0.0.0',
              crashes: '-',
              installs: '-',
              // rating: '-',
            };
          }

          return {
            id: app.id,
            brandColor: build.brandColor,
            icon: build.icon,
            name: build.name
              ? build.name.en
              : app.id,
            liveVersion: app['liveBuild']
              ? app.liveVersion
              : '0.0.0',
            testVersion: app['testBuild']
              ? app.testVersion
              : '0.0.0',
            installs: app.installs,
            crashes: app['liveBuild'] ? app['liveBuild'].crashes : app['testBuild'].crashes,
            // rating: Number(app.rating).toFixed(1),
            added: this.buildTimeAgo(build.stateChangedAt),
          }
        })
        .filter(app => !!app);

      this.setState({ apps });
    }).catch(this.props.handleError);
  }

  componentDidMount() {
    this.handleRefreshMyApps();
  }

  handleSortApps = (sortOrder, sortBy) => {
    this.setState({ sortOrder });
    this.setState({ sortBy });
  };

  handleChangeAppsPage = (event, appsPage) => {
    this.setState({ appsPage });
  };

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  getComparator(order, orderBy) {
    switch (orderBy) {
      case 'testVersion':
        // Use Semver sorting for version numbers.
        return order === 'desc'
          ? (a, b) => compare(a[orderBy], b[orderBy], true)
          : (a, b) => -compare(a[orderBy], b[orderBy], true);
      case 'liveVersion':
        // Use Semver sorting for version numbers.
        return order === 'desc'
          ? (a, b) => compare(a[orderBy], b[orderBy], true)
          : (a, b) => -compare(a[orderBy], b[orderBy], true);
      default:
        return order === 'desc'
          ? (a, b) => this.descendingComparator(a, b, orderBy)
          : (a, b) => -this.descendingComparator(a, b, orderBy);
    }
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  buildTimeAgo(stateChangedAt) {
    return [<TimeAgo key="time" date={new Date(stateChangedAt)} />];
  }

  tableHeader() {
    return [
      {
        id: 'icon',
        title: 'Icon'
      },
      {
        id: 'name',
        title: 'Name'
      },
      {
        id: 'liveVersion',
        title: 'Live Version'
      },
      {
        id: 'testVersion',
        title: 'Test Version'
      },
      {
        id: 'installs',
        title: 'Installs'
      },
      {
        id: 'crashes',
        title: 'Crashes'
      },
      // {
      //   id: 'rating',
      //   title: 'Rating'
      // }
    ];
  }

  render() {
    const {
      apps,
      appsPage,
      sortOrder,
      sortBy,
    } = this.state;

    const {
      classes
    } = this.props;

    const newApp = (
      <FormControl
        margin="normal"
      >
        <Button
          onClick={this.handleCreateAppDialogOpen}
          variant="contained"
          color="primary"
        >
          <AddBoxIcon className={classes.leftIcon} />
          New App
        </Button>
      </FormControl>
    );

    return (
      <Page className="Apps" cards>

        <PageCard title="My Apps" subtitle={apps ? (apps.length === 1 ? 'You manage 1 app.' : `You manage ${apps.length} apps.`) : 'Loading...'}>
          {Array.isArray(apps) && apps.length > 0 && (
            <Fragment>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[ROWS_PER_PAGE]}
                      colSpan={9}
                      count={apps.length}
                      rowsPerPage={ROWS_PER_PAGE}
                      page={appsPage}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={this.handleChangeAppsPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                  <TableRow>
                    {this.tableHeader().map(cell => (
                      <TableSortLabelActions
                        active={sortBy === cell.id}
                        direction={sortOrder}
                        title={cell.title}
                        id={cell.id}
                        key={cell.id}
                        action={this.handleSortApps}
                        ActionsComponent={TableSortLabelActionsWrapped}
                      />
                    ))}
                    <TableCell>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.stableSort(apps, this.getComparator(sortOrder, sortBy))
                    .slice(appsPage * ROWS_PER_PAGE, appsPage * ROWS_PER_PAGE + ROWS_PER_PAGE)
                    .map(app => (

                      <TableRow
                        key={app.id}>
                        <TableCell>
                          <Link
                            style={{ textDecoration: 'none' }}
                            to={`/apps/app/${app.id}`}
                          >
                            <div className="HomeyIcon" style={{
                              backgroundColor: app.brandColor,
                            }}>
                              <div className="HomeyIconInner" style={{
                                maskImage: `url(${app.icon})`,
                                WebkitMaskImage: `url(${app.icon})`,
                                MozMaskImage: `url(${app.icon})`,
                              }}></div>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell>{app.name}</TableCell>
                        <TableCell>{app.liveVersion !== '0.0.0' ? app.liveVersion : '-'}</TableCell>
                        <TableCell>{app.testVersion !== '0.0.0' ? app.testVersion : '-'}</TableCell>
                        <TableCell>{app.installs}</TableCell>
                        <TableCell>{app.crashes}</TableCell>
                        {/* <TableCell>{app.rating !== '0.0' ? app.rating : '-'}</TableCell> */}
                        <TableCell>
                          <a
                            href={`/apps/app/${app.id}`}
                            style={{ textDecoration: 'none' }}
                            rel="noopener noreferrer"
                          >
                            <Button color="primary">
                              {'Open'}
                              <NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                            </Button>
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[ROWS_PER_PAGE]}
                      colSpan={9}
                      count={apps.length}
                      rowsPerPage={ROWS_PER_PAGE}
                      page={appsPage}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={this.handleChangeAppsPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
              {newApp}
            </Fragment>
          )}

          <Fragment>
            {apps && apps.length === 0 ? (
              <center style={{
                marginTop: 20,
                marginBottom: 20,
              }}>
                <Typography variant="h6">{`Let's create your first app!`}</Typography>
                {newApp}
              </center>
            ) : apps && apps.length > 0 ? (<></>) : (
              <center style={{
                marginTop: 100,
              }}>
                <CircularProgress />
              </center>
            )}
          </Fragment>
        </PageCard>


        <Dialog
          open={this.state.createAppDialogOpen}
          onClose={this.handleCreateAppDialogClose}
        >
          <DialogTitle>Create a Homey App</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a Homey app, run <code>$ homey app publish</code> from the console.

              Read more about <a href="https://apps.developer.homey.app/" target="_blank" rel="noopener noreferrer">the Homey Apps SDK</a>.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCreateAppDialogClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withStyles(styles)(withApps(PageApps))))));
