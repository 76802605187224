import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withHomey,
  withMessages,
} from '../../services/AthomApi';

import Page from '../Page';
import Card from '../Card';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import IconRefresh from '@material-ui/icons/Refresh';

import ReactJson from 'react-json-view';
import _ from 'lodash';

class PageToolsZigbeeShepherd extends Component {

  constructor(props) {
    super(props);

    this._logContainer = null;
    this.state = {
      zigbee_state: null,
      hoverNodeIndex: null,
      devices: [],

      channel: '',
      panId: '',
      extPanId: '',
      ieeeAddr: '',
      currentCommand: '',
    }
  }

  componentDidMount() {
    this._getState();

    this.props.homey.zigbee
      .on('state', this._onZigbeeState)
      .connect()
      .catch(this.props.handleError);
  }

  componentWillUnmount() {
    if (this.props.homey) {
      this.props.homey.zigbee.destroy();
    }
  }

  _getState = () => {
    this.props.homey.zigbee
      .getState()
      .then(state => {
        this._onZigbeeState(state)

        if (!Array.isArray(state.zigbee_state.routes)) {
          this._generateMap();
        }
      })
  }

  _generateMap = () => {
    this.setState({ zigbee_map: [] });
    this.props.homey.zigbee
      .runCommand({
        command: 'generateMap'
      })
      .then(result => {
        if (Array.isArray(result)) { // <1.5.6-rc.4
          this.setState({
            zigbee_map: result,
          });
        }
      })
  }

  _onZigbeeState = state => {
    const { zigbee_state } = state;

    if (!this.state.zigbee_state)
      this.setState({ zigbee_state });

    if (zigbee_state.routes) {
      this.setState({
        zigbee_map: zigbee_state.routes,
      })
    }

    if (zigbee_state.devices) {
      this.setState({
        devices: zigbee_state.devices
      });
    }

    if (zigbee_state.net.channel) {
      this.setState({
        channel: zigbee_state.net.channel,
      });
    }

    if (zigbee_state.net.panId) {
      this.setState({
        panId: zigbee_state.net.panId,
      });
    }

    if (zigbee_state.net.extPanId) {
      this.setState({
        extPanId: zigbee_state.net.extPanId,
      });
    }

    if (zigbee_state.net.ieeeAddr) {
      this.setState({
        ieeeAddr: zigbee_state.net.ieeeAddr,
      });
    }

    if (zigbee_state.currentCommand) {
      this.setState({
        currentCommand: zigbee_state.currentCommand,
      });
    }
  }

  _getDeviceIndex = device => {
    const { devices } = this.state;
    return devices.findIndex(device_ => {
      return device_.ieeeAddr === device.ieeeAddr;
    });
  }

  _getDeviceColor = device => {
    const { devices } = this.state;
    let index = this._getDeviceIndex(device);
    return this._getDeviceColorByIndex(index / devices.length);
  }

  _getDeviceColorByIndex = index => {
    return `hsl(${Math.floor(index * 360)}, 100%, 75%)`;
  }

  // Collect all incoming and outgoing connections per node
  _collectNeighbours = deviceId => {
    const { zigbee_map, devices } = this.state;

    const neighbours = [];
    for (let i in zigbee_map) {
      const mapRoute = zigbee_map[i];

      if (mapRoute.from === deviceId) {

        const dev = _.find(devices, {
          ieeeAddr: mapRoute.to
        });
        if (dev) neighbours.push(dev)
      } else if (mapRoute.to === deviceId) {
        const dev = _.find(devices, {
          ieeeAddr: mapRoute.from
        });
        if (dev) neighbours.push(dev)
      }
    }

    return neighbours;
  }

  // Recursively search all neighbours until coordinator is found (route is detected)
  _findDirectRouteToCoordinator = (deviceId, routeMap) => {
    const { devices } = this.state;
    const neighbours = this._collectNeighbours(deviceId);

    // If direct route to coordinator only show device and coordinator route
    const coordinator = _.find(neighbours, { type: "Coordinator" });
    if (coordinator) {

      // Direct route was found, add the routing device and coordinator
      routeMap.push({
        device: _.find(devices, { ieeeAddr: deviceId }),
      });

      routeMap.push({
        device: coordinator
      });

      return routeMap
    } else {
      // No direct route was found
      // Loop over all neighbours and check if they have direct connection

      // Save current route to revert to when findDirectRouteToCoordinator does not succeed
      const routeMapBefore = routeMap.slice();
      for (let device of neighbours) {

        // Recursive call to find neighbours of the next node
        const directRoute = this._findDirectRouteToCoordinator(device.ieeeAddr, routeMap);

        // A route to coordinator was found
        if (directRoute) {
          return routeMap;
        } else {
          // No route to coordinator was found (dead-end, revert to prev state and search next node's neighbours
          routeMap = routeMapBefore;
        }
      }

      // No direct route found
      return null;
    }
  }


  render() {
    const {
      zigbee_map,
      zigbee_state,
      zigbee_topology,
      hoverNodeIndex,
    } = this.state;

    const nodesRows = [];

    const RouteNode = (props) => {
      const { device } = props;
      const index = this._getDeviceIndex(device);
      return <span
        key={index}
        className={'RouteNode'}
        style={{
          backgroundColor: this._getDeviceColor(device),
          transform: hoverNodeIndex === index ? 'scale(1.35)' : 'scale(1.0)',
        }}
        onMouseLeave={() => this.setState({ hoverNodeIndex: null })}
        onMouseEnter={() => this.setState({ hoverNodeIndex: index })}
      >{index}</span>
    }

    if (zigbee_state && Array.isArray(zigbee_state.devices)) {
      if (zigbee_topology !== null) {
        zigbee_state.devices.forEach((device, deviceIndex) => {
          let routeEls = [];
          let route = [];
          let map = zigbee_map
          if (map) {

            // Recursively search for the coordinator
            route = this._findDirectRouteToCoordinator(device.ieeeAddr, []) || [];

            if (route) {
              // Route to coordinator was found prepend the root device to complete the route
              route.unshift({ device });

              // Map to array of ieeeAdresses
              route = _.map(route, item => item.device.ieeeAddr);

              // Filter unique ieeeAddresses
              route = _.uniq(route, 'ieeeAddr');

              // Map back to array of device objects and reverse to match z-wave route starting at the root
              route = _.map(route, ieeeAddr => ({ device: _.find(this.state.zigbee_state.devices, { ieeeAddr }) })).reverse();
            }
          }

          route.forEach((routeItem, index) => {
            if (index !== 0)
              routeEls.push(<span key={`${index}-sep`} title={'Link Quality: ' + Math.round(routeItem.quality * 100) + '%'}>—</span>);

            routeEls.push(<RouteNode key={index} device={routeItem.device} />);
          })

          nodesRows.push((
            <TableRow key={device.ieeeAddr}>
              <TableCell>
                <RouteNode device={device} />
              </TableCell>
              <TableCell>{device.name || <em>Unknown</em>}</TableCell>
              <TableCell>{device.ieeeAddr}</TableCell>
              <TableCell>{device.type}</TableCell>
              <TableCell>{device.manufacturerName || <em>Unknown</em>}</TableCell>
              <TableCell>{device.productId}</TableCell>
              <TableCell>{device.profileId}</TableCell>
              <TableCell>{device.type !== 'Coordinator' && routeEls}</TableCell>
            </TableRow>
          ));
        });

      }
    }

    return (
      <Page className="PageToolsZigbee" cards={true}>
        <Card title="Nodes">
          <div style={{
            overflowX: 'auto'
          }}>
            <Table
              padding="none"
              style={{
                minWidth: 1000,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>NodeID</TableCell>
                  <TableCell>Device</TableCell>
                  <TableCell>IEEE Address</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>ProductID</TableCell>
                  <TableCell>ProfileID</TableCell>
                  <TableCell>Route</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nodesRows}
              </TableBody>
            </Table>
          </div>
        </Card>

        <Card title="System Information">
          <TextField
            value={this.state.channel}
            label="Channel"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={this.state.panId}
            label="Pan ID"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={this.state.extPanId}
            label="Extended PAN ID"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={this.state.ieeeAddr}
            label="IEEE Address"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={this.state.currentCommand}
            label="Current Command"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
        </Card>

        <Card title="State" toolbar={(
          <IconButton
            tooltip="Refresh the state"
            onClick={this._getState}>
            <IconRefresh />
          </IconButton>
        )}>
          <ReactJson
            theme="google"
            src={this.state.zigbee_state || {}}
            collapsed={true}
            name={null} />
        </Card>

      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsZigbeeShepherd, '<=4.2.0')))));
