import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import semver from 'semver';
import {
	withApi,
	withUser,
	withHomey,
	withMessages,
} from '../../services/AthomApi';
import Card from '../Card';
import Page from '../Page';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import IconRefresh from '@material-ui/icons/Refresh';

const STRATEGIES = {
	'cloud': 'Cloud (HTTPS)',
	'localSecure': 'Local Secure (HTTPS)',
	'local': 'Local Unsecure (HTTP)',
	'mdns': 'Local Unsecure (mDNS)',
	'remoteForwarded': 'Port Forwarded (HTTPS)',
}

class PageToolsSystem extends Component {

	constructor(props) {
		super(props);

		this.state = {
			info: {},
			creatingDiagnosticsReport: false,
			homeyPlatform: this.props.homey.platform,
			homeyPlatformVersion: this.props.homey.platformVersion,
			homeySoftwareVersion: this.props.homey.softwareVersion
				? this.props.homey.softwareVersion.split('-')[0]
				: null,
		}
	}

	getInfo = () => {
		this.props.homey.system.getInfo().then(info => {
			this.setState({ info });
		}).catch(this.props.handleError);
	}

	reboot = () => {
		this.props.homey.system.reboot().then(() => {
			this.props.handleSuccess(`${this.props.homey.name} will restart shortly.`);
		}).catch(this.props.handleError);
	}

	rebootRecovery = () => {
		this.props.homey.system.rebootRecovery().then(() => {
			this.props.handleSuccess(`${this.props.homey.name} will start recovery shortly.`);
		}).catch(this.props.handleError);
	}

	sendLog = () => {
		this.setState({
			creatingDiagnosticsReport: true,
		});

		this.props.homey.system.sendLog({
			$timeout: 90000,
		}).then(logId => {
			this.props.handleSuccess(`Report ID: ${logId}`);
			window.prompt('Your Diagnostics Report ID:', logId);
		}).catch(this.props.handleError).then(() => {
			this.setState({
				creatingDiagnosticsReport: false,
			});
		});
	}

	componentDidMount() {
		this.getInfoInterval = setInterval(this.getInfo, 5000);
		this.getInfo();
	}

	componentWillUnmount() {
		if (this.getInfoInterval)
			clearInterval(this.getInfoInterval);
	}

	render() {
		const {
			info,
			creatingDiagnosticsReport,
			homeyPlatform,
			homeyPlatformVersion,
			homeySoftwareVersion,
		} = this.state;

		return (
			<Page cards={true}>
				{this.props.homey.platform !== 'cloud' ? (
					<Card
						title="Diagnostics Report"
					>
						<p>When asked by an Athom employee, create a Diagnostics Report and return the unique code.</p>
						<Button
							variant="contained"
							color="primary"
							onClick={this.sendLog}
							disabled={creatingDiagnosticsReport}
						>
							{creatingDiagnosticsReport && (
								<React.Fragment>
									<CircularProgress color="inherit" size={20} />&nbsp;&nbsp;
									<span>Creating diagnostics report...</span>
								</React.Fragment>
							)}
							{!creatingDiagnosticsReport && (
								<span>Create Diagnostics Report</span>
							)}
						</Button>
					</Card>
				) : null}

				<Card
					title="Connection"
				>
					<Typography variant="body1">{STRATEGIES[this.props.homey.strategyId] || this.props.homey.strategyId}</Typography>
				</Card>

				<Card
					title="System Information"
					toolbar={
						<IconButton onClick={this.getInfo}>
							<IconRefresh />
						</IconButton>
					}
				>

					<div style={{
						overflowX: 'auto'
					}}>
						<Table padding="none">
							<TableHead>
								<TableRow>
									<TableCell>ID</TableCell>
									<TableCell>Value</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.keys(info).map(key => {
									const value = info[key];

									return (
										<TableRow key={key}>
											<TableCell>{key}</TableCell>
											<TableCell>{JSON.stringify(value)}</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</div>
				</Card>

				<Card
					title="Restart"
				>
					<p>This action will restart {this.props.homey.name}.</p>
					<Button
						variant="contained"
						color="secondary"
						onClick={this.reboot}
					>
						Restart
					</Button>
				</Card>
				{homeyPlatform === 'local' && homeyPlatformVersion === 1 && homeySoftwareVersion && semver.gte(homeySoftwareVersion, '7.3.0') && <Card
					title="Recovery Mode"
				>
					<p>This action will restart {this.props.homey.name} into recovery mode.</p>
					<Button
						variant="contained"
						color="secondary"
						onClick={this.rebootRecovery}
					>
						Restart into Recovery Mode
					</Button>
				</Card>
				}
			</Page>
		);
	}
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsSystem)))));
