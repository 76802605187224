import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withApps,
  withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import Card from '../Card';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import './style.css';

import IconButton from '@material-ui/core/IconButton';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconSearch from '@material-ui/icons/Search';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { curveLinear } from "d3-shape";
import { timeFormat } from "d3-time-format";
import { select } from 'd3-selection';

import MetricsGraphics from '../MetricsGraphics';

import 'metrics-graphics/dist/metricsgraphics.css';
import '../../metricsgraphics-dark.css';

const ROWS_PER_PAGE = 10;

class TableWrap extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Input
          fullWidth={true}
          placeholder="Search by Crash ID..."
          value={this.props.filter}
          onChange={e => this.props.onSearch(e.currentTarget.value)}
          startAdornment={
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          }
        />

        <div style={{
          height: 500,
          marginTop: 20,
          overflow: 'auto',
        }}>
          {this.props.children}
        </div>


      </React.Fragment>
    );
  }
}

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onPageChange(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onPageChange(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

class PageAppsAppCrashes extends Component {

  state = {
    app: null,
    build: null,
    crashes: null,
    crashFilter: '',
    crashesStats: [],
    crashesPage: 0,
    crash: null,
  }

  componentDidMount() {
    this.handleRefreshApps();
    this.handleRefreshBuild();
    this.handleRefreshCrashes();
    this.handleRefreshCrashesStats();
  }

  handleRefreshApps = () => {
    const { appId } = this.props.match.params;

    this.props.callApps('getApp', { appId }).then(app => {
      this.setState({ app });
    }).catch(this.props.handleError);
  }

  handleRefreshBuild = () => {
    const { appId, buildId } = this.props.match.params;

    this.props.callApps('getBuild', {
      appId,
      buildId,
    }).then(build => {
      this.setState({ build });
    }).catch(this.props.handleError);
  }

  handleRefreshCrashes = () => {
    const { appId, buildId } = this.props.match.params;
    this.props.callApps('getCrashes', {
      appId,
      buildId,
    }).then(crashes => {
      crashes.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      this.setState({ crashes })
    }).catch(this.props.handleError);
  }

  handleRefreshCrashesStats = () => {
    const { appId, buildId } = this.props.match.params;
    this.props.callApps('getCrashesStats', {
      appId,
      buildId,
    }).then(crashesStats => {
      crashesStats = crashesStats.map((d, i) => {
        d.value = d.value || 0.0;
        d.date = new Date(d.date);
        return d;
      });

      this.setState({ crashesStats });
    }).catch(this.props.handleError);
  }

  handleChangeCrashesPage = (event, crashesPage) => {
    this.setState({ crashesPage });
  }

  handleCrashShow = (crash) => {
    this.setState({ crash });
  };

  handleCrashHide = () => {
    this.setState({ crash: null });
  };

  render() {
    const {
      appId,
      buildId,
    } = this.props.match.params;

    const {
      app,
      build,
      crash,
      crashFilter,
      crashes,
      crashesStats,
      crashesPage,
    } = this.state;

    return (
      <Page className="AppsAppCrashes" cards>

        {app && (
          <Card>
            <Typography variant="h5" color="textPrimary">
              <Link className="AppsHeaderLink" to={`/apps`}>My Apps</Link>
              <NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
              <Link className="AppsHeaderLink" to={`/apps/app/${appId}`}>{app.id}</Link>
              <NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
              <Link className="AppsHeaderLink" to={`/apps/app/${appId}/build/${buildId}`}>Build {buildId} {build && `(v${build.version})`}</Link>
              <NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
              <Link className="AppsHeaderLink" to={`/apps/app/${appId}/build/${buildId}/crashes`}>Crashes</Link>
            </Typography>
          </Card>
        )}

        {app && (
          <Card title="Crashes">
            {!crashes && (
              <CircularProgress />
            )}

            {crashes && crashesStats.length === 0 && (
              <MetricsGraphics
                chart_type="missing-data"
                missing_text={"No crash data is currently available."}
                full_width={true}
                height={250}
              />
            )}

            {crashes && crashesStats.length > 1 && (
              <MetricsGraphics
                data={crashesStats}
                full_width={true}
                interpolate={curveLinear}
                y_extended_ticks={true}
                top={20}
                left={50}
                height={250}
                min_x={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                max_x={new Date()}
                point_size={3}
                xax_count={12}
                x_accessor="date"
                y_accessor="value"
                area={true}
                show_secondary_x_label={true}
                mouseover={function (d, i) {
                  var df = timeFormat('%b %d, %Y');
                  var date = df(d.date);
                  var y_val = (d.value === 0) ? 'no data' : parseFloat(d.value).toFixed(0);

                  select('.mg-active-datapoint')
                    .text(date + '   Crashes: ' + y_val);
                }}
              />
            )}

            {crashes && (
              <TableWrap
                filter={crashFilter}
                onSearch={crashFilter => {
                  this.setState({ crashFilter });
                }}
              >
                <Table padding="none">
                  <TableHead>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[ROWS_PER_PAGE]}
                        colSpan={7}
                        count={crashes.length}
                        rowsPerPage={ROWS_PER_PAGE}
                        page={crashesPage}
                        SelectProps={{
                          native: true,
                        }}
                        onPageChange={this.handleChangeCrashesPage}
                        ActionsComponent={TablePaginationActionsWrapped}
                      />
                    </TableRow>
                    <TableRow>
                      <TableCell>Homey Version</TableCell>
                      <TableCell>Count</TableCell>
                      <TableCell>Manual</TableCell>
                      <TableCell>First seen</TableCell>
                      <TableCell>Last seen</TableCell>
                      <TableCell>Stack</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {crashes.filter(crash => {
                      return crash.stack.toLowerCase().includes(crashFilter.toLowerCase());
                    }).slice(crashesPage * ROWS_PER_PAGE, crashesPage * ROWS_PER_PAGE + ROWS_PER_PAGE).map(crash => (
                      <TableRow
                        key={crash.createdAt}
                      >
                        <TableCell>{crash.homeyVersion}</TableCell>
                        <TableCell>{crash.count}</TableCell>
                        <TableCell>{crash.stack.includes('Note: this log has been manually submitted by a user.') ? '✓' : '⨯'}</TableCell>
                        <TableCell><TimeAgo date={crash.createdAt} /></TableCell>
                        <TableCell><TimeAgo date={crash.updatedAt} /></TableCell>
                        <TableCell>
                          <Button color="primary" onClick={() => this.handleCrashShow(crash)}>
                            View stack trace
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[ROWS_PER_PAGE]}
                        colSpan={7}
                        count={crashes.length}
                        rowsPerPage={ROWS_PER_PAGE}
                        page={crashesPage}
                        SelectProps={{
                          native: true,
                        }}
                        onPageChange={this.handleChangeCrashesPage}
                        ActionsComponent={TablePaginationActionsWrapped}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableWrap>
            )}
          </Card>
        )}

        <Dialog
          open={!!crash}
          onClose={this.handleCrashHide}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle>Stack trace</DialogTitle>
          <DialogContent>
            <pre>{crash && crash.stack}</pre>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCrashHide} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withApps(PageAppsAppCrashes)))));