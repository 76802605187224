import './style.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {
	withApi,
	withUser,
	withHomey,
	withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import Card from '../Card';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import IconSearch from '@material-ui/icons/Search';
import IconDelete from '@material-ui/icons/Delete';
import qs from 'query-string';

import { CircularProgress } from '@material-ui/core';
import { PageToolsDeviceCapability } from './PageToolsDeviceCapability';

class PageToolsDevices extends Component {

	constructor(props) {
		super(props);

		this.state = {
			filter: '',
			devices: null,
			baseUrl: null,
		}

		this._capabilityInstances = {};
	}

	componentDidMount() {
		const { filter } = qs.parse(window.location.search);
		if (filter) this.setState({ filter });

		this.props.homey.baseUrl.then(baseUrl => {
			this.setState({ baseUrl });
		});

		this.props.homey.devices
			.on('device.create', this.getDevices)
			.on('device.update', this.getDevices)
			.on('device.delete', this.getDevices)
			.connect()
			.catch(this.props.handleError)
			.finally(() => this.getDevices())

	}

	componentWillUnmount() {
		Object.values(this._capabilityInstances).forEach(device => {
			Object.values(device).forEach(capabilityInstance => {
				capabilityInstance.destroy();
			});
		});
		this._capabilityInstances = {};

		if (this.props.homey) {
			this.props.homey.devices.destroy();
		}
	}

	getDevices = () => {
		this.props.homey.devices
			.getDevices()
			.then(devices => {
				Object.keys(devices).forEach(deviceId => {
					const device = devices[deviceId];

					this._capabilityInstances[deviceId] = this._capabilityInstances[deviceId] || {};
					Object.keys(device.capabilitiesObj || {}).forEach(capabilityId => {
						if (this._capabilityInstances[deviceId][capabilityId]) return;

						try {
							this._capabilityInstances[deviceId][capabilityId] = device.makeCapabilityInstance(capabilityId, value => {
								console.info(deviceId, device.name, capabilityId, value);
								this.forceUpdate();
							});
						} catch (err) {
							console.error({
								device,
								capabilityId,
								deviceId,
							}, err);
						}
					});
				});
				this.setState({ devices });
				this.forceUpdate();
			}).catch(err => {
				console.error(err);
				this.props.handleError(err);
			});
	}

	_deleteDevice = device => {
		if (device.unpair) {
			this.props.handleError('Please unpair this device');
		} else {
			if (window.confirm(`Are you sure you want to delete ${device.name}?`)) {
				this.props.homey.devices.deleteDevice({
					id: device.id,
				})
					.then(() => {
						this.props.handleSuccess(`Deleted ${device.name}`);
					})
					.catch((err) => {
						console.error(err);
						this.props.handleError(err);
					})
			}
		}
	}

	_renameDevice = (device, name) => {
		const oldName = device.name;
		if (oldName === name) return;

		this.props.homey.devices.updateDevice({
			id: device.id,
			device: { name },
		})
			.then(() => {
				this.props.handleSuccess(`Renamed "${oldName}" to "${name}"`);
			})
			.catch((err) => {
				console.error(err);
				this.props.handleError(err);
			})
	}

	handleSearch = event => {
		this.setState({
			filter: event.target.value,
		});
	}

	render() {
		const {
			filter,
			devices,
			baseUrl,
		} = this.state;

		const HomeyIcon = iconObj => {
			if (!iconObj) return;

			return <div className="IconMask" style={{
				WebkitMaskImage: 'url(' + baseUrl + iconObj.url + ')',
				backgroundColor: 'white',
				width: 24,
				height: 24,
				display: 'inline-block',
				verticalAlign: 'middle',
			}} />
		}

		return (
			<Page cards={true} className="PageToolsDevices">

				<Card>
					<Input
						fullWidth={true}
						placeholder="Search..."
						value={filter}
						onChange={this.handleSearch}
						startAdornment={
							<InputAdornment position="start">
								<IconSearch />
							</InputAdornment>
						}
					/>
				</Card>

				{devices === null && <CircularProgress />}

				{devices !== null && Object.keys(devices).filter(deviceId => {
					const device = devices[deviceId];
					return JSON.stringify(device).toLowerCase().includes(filter.toLowerCase());
				}).map(deviceId => {
					const device = devices[deviceId];

					const capabilities = Object.keys(device.capabilitiesObj || {}).filter(capabilityId => {
						if (!this._capabilityInstances[deviceId]) return false;
						if (!this._capabilityInstances[deviceId][capabilityId]) return false;
						return true;
					}).map(capabilityId => {
						const capability = device.capabilitiesObj[capabilityId];
						const capabilityInstance = this._capabilityInstances[deviceId][capabilityId];

						const setCapabilityValue = (value) => {
							const start = new Date();
							this.props.homey.devices.setCapabilityValue({
								value,
								deviceId,
								capabilityId,
							}).then(() => {
								const end = new Date();
								const duration = end - start;
								return this.props.handleSuccess(`Set \`${capabilityId}\` to \`${value}\` (${duration}ms)`);
							}).catch(this.props.handleError);
						};

						return <PageToolsDeviceCapability
							key={capabilityId}
							capabilityInstance={capabilityInstance}
							capability={capability}
							capabilityId={capabilityId}
							onSetCapabilityValue={setCapabilityValue}
						/>;
					});

					return (
						<Card
							key={deviceId}
							toolbar={
								<React.Fragment>
									{HomeyIcon(device.iconObj)}
									{!device.unpair && !device.flags.includes('sticky') && (
										<IconButton
											tooltip="Delete this device"
											onClick={() => this._deleteDevice(device)}>
											<IconDelete />
										</IconButton>
									)}
								</React.Fragment>
							}
							title={(
								<input
									type="text"
									defaultValue={device.name}
									className="DeviceName"
									onBlur={e => this._renameDevice(device, e.target.value)}
									onKeyPress={e => e.key === 'Enter' && e.target.blur()}
								/>
							)}
						>

							<Table padding="none">
								<TableHead>
									<TableRow>
										<TableCell width="20%">Property</TableCell>
										<TableCell>Value</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell>{device.id}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>{device.name}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Class</TableCell>
										<TableCell>{device.class} {device.virtualClass ? `(Virtual: ${device.virtualClass})` : ''}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Driver</TableCell>
										<TableCell>{device.driverId}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Ready</TableCell>
										<TableCell>{device.ready ? 'Yes' : 'No'}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Available</TableCell>
										<TableCell>{device.available ? 'Yes' : `No (${device.unavailableMessage || 'No unavailable message'})`}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Warning</TableCell>
										<TableCell>{device.warningMessage ? `Yes (${device.warningMessage || 'No warning message'})` : 'No'}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Custom icon</TableCell>
										<TableCell>{device.icon ? 'Yes' : 'No'}</TableCell>
									</TableRow>
									{device.images && device.images.length > 0 && (
										<TableRow>
											<TableCell>Images</TableCell>
											<TableCell>{device.images.map((image, i) => {
												return (
													<Link
														key={i}
														className="Link"
														style={{
															display: 'block',
														}}
														to={`/tools/images?highlight=${image.imageObj.id}`}
													>
														{`${image.type} — ${image.id} — ${image.imageObj.id}`}
													</Link>
												);
											})}</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>

							{device.data && Object.keys(device.data).length > 0 && (
								<React.Fragment>
									<br />
									<Typography variant="subtitle1">Data</Typography>
									<Table
										padding="none"
										className="DataTable"
									>
										<TableHead>
											<TableRow>
												<TableCell width="20%">Key</TableCell>
												<TableCell>Value</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{Object.keys(device.data).map(key => {
												const value = device.data[key];

												return (
													<TableRow key={key}>
														<TableCell>{key}</TableCell>
														<TableCell className={`CapabilityType CapabilityType-${typeof value}`}>{JSON.stringify(value)}</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</React.Fragment>
							)}

							{device.settings && Object.keys(device.settings).length > 0 && (
								<React.Fragment>
									<br />
									<Typography variant="subtitle1">Settings</Typography>
									<Table
										padding="none"
										className="SettingsTable"
									>
										<TableHead>
											<TableRow>
												<TableCell width="20%">Key</TableCell>
												<TableCell>Value</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{Object.keys(device.settings).map(key => {
												const value = device.settings[key];

												return (
													<TableRow key={key}>
														<TableCell>{key}</TableCell>
														<TableCell className={`CapabilityType CapabilityType-${typeof value}`}>{JSON.stringify(value)}</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</React.Fragment>
							)}

							<br />
							<Typography variant="subtitle1">Capabilities</Typography>
							{
								capabilities.length > 0
									? (
										<Table
											padding="none"
											className="CapabilityTable"
										>
											<TableHead>
												<TableRow>
													<TableCell width="10%">ID</TableCell>
													<TableCell width="15%">Title</TableCell>
													<TableCell width="5%">Type</TableCell>
													<TableCell width="10%">Value</TableCell>
													<TableCell width="20%">Set Value</TableCell>
													<TableCell>Last Changed</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{capabilities}
											</TableBody>
										</Table>
									)
									: 'This device has no capabilities.'
							}
						</Card>
					);
				})}
			</Page>
		);
	}
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsDevices)))));
