import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import PageCard from '../Card';
import { Button, TextField } from '@material-ui/core';

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class PageMe extends Component {

  state = {
    personalAccessTokenApps: null,
  };

  handleShowPAT = () => {
    this.props.api.api.getPersonalAccessTokenApps()
      .then(({ accessToken }) => {
        this.setState({
          personalAccessTokenApps: accessToken,
        });
      })
      .catch(err => {
        this.props.handleError(err);
      });
  }

  handleResetPAT = () => {
    if (!window.confirm(`Are you sure you want to reset your Personal Access Token?`)) return;

    this.props.api.api.deletePersonalAccessTokenApps()
      .then(() => {
        this.handleShowPAT();
      })
      .catch(err => {
        this.props.handleError(err);
        this.setState({
          personalAccessTokenApps: null,
        });
      });
  }

  render() {
    const {
      user,
    } = this.props;

    const {
      personalAccessTokenApps,
    } = this.state;

    const isTrustedAppDeveloper = user.roleIds.includes('app_developer_trusted');

    return (
      <Page className="Me" cards>
        <PageCard title="My Account">

          <p>Your Account Name: {user.firstname} {user.lastname}</p>
          <p>Your Account ID: {user._id}</p>

          <Button
            href="https://homey.app/account"
            target="_blank"
            variant="contained"
            color="primary">Manage My Account</Button>

        </PageCard>
        <PageCard title="Verified Developer">

          {isTrustedAppDeveloper
            ? <Fragment>
              <p>Your account is verified.</p>
              <p>You're entitled to publish apps for Homey Pro and Homey Cloud.</p>
            </Fragment>
            : <Fragment>
              <p>Your account is <strong>not</strong> verified.</p>
              <p>You're entitled to publish apps for Homey Pro, but not for Homey Cloud.</p>
            </Fragment>
          }

          <Button
            href="https://homey.app/account/subscriptions"
            target="_blank"
            variant="contained"
            color="primary">Manage My Subscriptions</Button>

        </PageCard>
        <PageCard title="Personal Access Token">
          <p>You can use this Personal Access Token (PAT) to use Homey CLI inside a CI/CD environment, such as GitHub Actions.</p>

          <TextField value={personalAccessTokenApps ?? '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●'} style={{
            width: '600px',
            fontFamily: 'monospace',
          }} /> &nbsp;

          {!personalAccessTokenApps && (
            <Button variant="contained" color="primary" onClick={this.handleShowPAT}>Reveal</Button>
          )}

          {personalAccessTokenApps && (
            <Button variant="contained" color="secondary" onClick={this.handleResetPAT}>Reset</Button>
          )}
        </PageCard>
      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withStyles(styles)(PageMe)))));
