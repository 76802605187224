import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    margin: '20px 40px 20px 20px',
  },
});


class Page extends Component {
  render() {
    const { classes } = this.props;

    let content;
    if (this.props.noPadding) {
      content = this.props.children;
    } else if (this.props.cards) {
      content = <div className={classes.root} style={{
        padding: 0,
      }}>
        {this.props.children}
      </div>
    } else {
      content = <Paper className={classes.root} elevation={1} style={{
        borderRadius: 10,
        border: false,
        boxShadow: `
    0 10px 44px  0 rgba(0,0,0, 0.14),
    0 2px  12px  0 rgba(0,0,0, 0.2),
    0 2px  4px   0 rgba(0,0,0, 0.08),
    0 0    1px   0 rgba(255,255,255, 0.4)
  `
      }}>
        {(this.props.title || this.props.toolbar) && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid item>
              {this.props.title && (
                <Typography variant="h5" gutterBottom={true}>{this.props.title}</Typography>
              )}
              {this.props.subtitle && (
                <Typography variant="body2" gutterBottom={true} style={{
                  marginBottom: 24,
                }}>{this.props.subtitle}</Typography>
              )}
            </Grid>
            <Grid item>
              {this.props.toolbar}
            </Grid>
          </Grid>
        )}
        {this.props.children}
      </Paper>
    }

    return (
      <div className={"Page " + this.props.className} style={{
        height: '100%',
        overflow: 'auto',
      }}>
        {content}
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

export default withStyles(styles)(Page);
