import './style.css';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {
	withApi,
	withUser,
	withHomey,
	withMessages,
} from '../../services/AthomApi';
import Page from '../Page';

import { ImageList, ImageListItem } from "@material-ui/core";
import { ImageListItemBar } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import IconRefresh from '@material-ui/icons/Refresh';
import IconOpen from '@material-ui/icons/OpenInNew';
import qs from 'query-string';

class PageToolsImages extends Component {

	constructor(props) {
		super(props);

		this.state = {
			images: {},
			baseUrl: null,
		}
	}

	componentDidMount() {
		const { highlight } = qs.parse(window.location.search);
		if (highlight) this.setState({ highlight });

		this.props.homey.images
			.on('image.create', this.getImages)
			.on('image.update', ({ id }) => this.refreshImage(id))
			.on('image.delete', this.getImages)
			.connect()
			.catch(this.props.handleError)
			.finally(() => this.getImages());

		this.props.homey.baseUrl.then(baseUrl => {
			this.setState({ baseUrl });
		})
	}

	componentWillUnmount() {
		if (this.props.homey) {
			this.props.homey.images.destroy();
		}
	}

	getImages = () => {
		this.props.homey.images.getImages().then(images => {
			this.setState({ images });
			Object.keys(images).forEach(this.refreshImage);
		});
	}

	refreshImage = (imageId) => {
		const { images } = this.state;
		const image = images[imageId];
		if (image) {
			image.uniqueUrl = image.url + '?_r=' + Math.random();
			this.setState({ images });
		}

	}

	render() {
		const {
			images,
			baseUrl,
			highlight,
		} = this.state;

		return (
			<Page
				className="PageToolsImages"
				title="Images"
				subtitle={(
					<React.Fragment>
						<span>These images are registered with </span>
						<a href="https://apps.developer.homey.app/advanced/images">ManagerImages</a>
						<span>.</span>
					</React.Fragment>
				)}
			>
				<ImageList
					padding={20}
					cols={3}
				>
					{Object.values(images).map(image => {
						return (
							<ImageListItem
								key={image.id}
								cols={1}
							>
								<div
									className={image.id === highlight ? 'Highlight' : ''}
									style={{
										width: '100%',
										height: '100%',
										background: 'url(/img/transparent.png) repeat',
									}}
								>
									<div style={{
										width: '100%',
										height: '100%',
										backgroundImage: `url(${baseUrl}${image.uniqueUrl || image.url})`,
										backgroundSize: 'contain',
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center center',
									}} />
								</div>
								<ImageListItemBar
									title={image.ownerUri}
									subtitle={`${image.id}`}
									actionIcon={
										<div style={{
											display: 'flex',
											flexDirection: 'row',
										}}>
											<IconButton
												style={{ color: 'white' }}
												onClick={() => {
													this.refreshImage(image.id);
												}}
											>
												<IconRefresh />
											</IconButton>
											<IconButton
												style={{ color: 'white' }}
												onClick={() => {
													window.open(baseUrl + image.uniqueUrl);
												}}
											>
												<IconOpen />
											</IconButton>
										</div>
									}
								/>
							</ImageListItem>
						);
					})}
				</ImageList>
			</Page>
		);
	}
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(withRouter(PageToolsImages), { version: '>=2.0.0' })))));
