import React, { useState, useEffect, useRef } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import VisualDiff from '../../../VisualDiff/index';

import Card from '../../../Card';
import './style.css';

import filesize from 'filesize';

export default function BuildDiffImpl({ build, liveBuild }) {
  if (!build) return null;

  return (
    <Card>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Submitted (v{build.version})
          </Typography>
          <BuildSummary build={build} liveBuild={liveBuild} />
        </Grid>
      </Grid>
    </Card>
  );
}

function BuildSummary({ build = {}, liveBuild = {} }) {
  const drivers = recordFromArrayWithIds(build.drivers);
  const liveDrivers = recordFromArrayWithIds(liveBuild.drivers);
  const driverIds = new Set([
    ...Object.keys(drivers),
    ...Object.keys(liveDrivers),
  ]);
  const renderedDriverDiffs = Array.from(driverIds).map((driverId) => {
    return (
      <DriverDiff
        key={driverId}
        driver={drivers[driverId]}
        liveDriver={liveDrivers[driverId]}
      />
    );
  });

  const flowTriggers = recordFromArrayWithIds(build.flow?.triggers);
  const liveFlowTriggers = recordFromArrayWithIds(liveBuild.flow?.triggers);
  const flowTriggerIds = new Set([
    ...Object.keys(flowTriggers),
    ...Object.keys(liveFlowTriggers),
  ]);
  const renderedFlowTriggerDiffs = Array.from(flowTriggerIds).map((cardId) => {
    return (
      <FlowCardDiff
        key={cardId}
        type="Trigger"
        card={flowTriggers[cardId]}
        liveCard={liveFlowTriggers[cardId]}
      />
    );
  });

  const flowConditions = recordFromArrayWithIds(build.flow?.conditions);
  const liveFlowConditions = recordFromArrayWithIds(liveBuild.flow?.conditions);
  const flowConditionIds = new Set([
    ...Object.keys(flowConditions),
    ...Object.keys(liveFlowConditions),
  ]);
  const renderedFlowConditionDiffs = Array.from(flowConditionIds).map(
    (cardId) => {
      return (
        <FlowCardDiff
          key={cardId}
          type="Condition"
          card={flowConditions[cardId]}
          liveCard={liveFlowConditions[cardId]}
        />
      );
    }
  );

  const flowActions = recordFromArrayWithIds(build.flow?.actions);
  const liveFlowActions = recordFromArrayWithIds(liveBuild.flow?.actions);
  const flowActionIds = new Set([
    ...Object.keys(flowActions),
    ...Object.keys(liveFlowActions),
  ]);
  const renderedFlowActionDiffs = Array.from(flowActionIds).map((cardId) => {
    return (
      <FlowCardDiff
        key={cardId}
        type="Action"
        card={flowActions[cardId]}
        liveCard={liveFlowActions[cardId]}
      />
    );
  });

  return (
    <div className="BuildSummary">
      <BuildDiff build={build} liveBuild={liveBuild} />
      {renderedDriverDiffs}
      {renderedFlowTriggerDiffs}
      {renderedFlowConditionDiffs}
      {renderedFlowActionDiffs}
    </div>
  );
}

function recordFromArrayWithIds(input) {
  if (!Array.isArray(input)) {
    if (typeof input === 'object' && input !== null) {
      // input is already a record
      return input;
    }
    return {};
  }
  const entries = input.map((item) => [item.id, item]);
  return Object.fromEntries(entries);
}

function BuildDiff({ build = {}, liveBuild = {} }) {
  return (
    <>
      <DiffField
        label="Changelog"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => translationObjectToString(build.changelog)}
      />

      <DiffField
        label="Name"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => translationObjectToString(build.name)}
      />

      <DiffField
        label="Version"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.version}
      />

      <DiffField
        label="Compatibility"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.compatibility}
      />

      <DiffField
        label="SDK"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => typeof build.sdk === 'number' ? String(build.sdk) : undefined}
      />

      <DiffField
        label="Platforms"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.platforms?.join(', ')}
      />
      
      <DiffField
        label="Platform Local Required Features"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.platformLocalRequiredFeatures?.join(', ')}
      />

      <DiffField
        label="Size"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => typeof build.size === 'number' ? filesize(build.size) : undefined}
      />

      <DiffField
        label="Readme"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => translationObjectToString(build.readme)}
      />

      <DiffField
        label="Description"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => translationObjectToString(build.description)}
      />

      <DiffField
        label="Category"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.category}
      />

      <DiffField
        label="Permissions"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.permissions?.join('\n')}
      />

      <DiffField
        label="Environment"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => JSON.stringify(build.env, false, 2)}
      />

      <DiffField
        label="Brand Color"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.brandColor}
      >
        {(value) => <Swatch color={value} />}
      </DiffField>

      <DiffField
        label="Tags"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => translationObjectToString(build.tags)}
      />

      <DiffField
        label="Contributors — Developers"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.contributors?.developers?.map((d) => d.name).join(', ')}
      />

      <DiffField
        label="Contributors — Translators"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.contributors?.translators?.map((t) => t.name).join(', ')}
      />

      <DiffField
        label="Contributing"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.contributing?.donate?.paypal?.username}
      />

      <DiffField
        label="Bugs URL"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.bugs}
      >
        {(value) => <TabLink href={value}>{value}</TabLink>}
      </DiffField>

      <DiffField
        label="Homepage URL"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.homepage}
      >
        {(value) => <TabLink href={value}>{value}</TabLink>}
      </DiffField>

      <DiffField
        label="Support URL"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.support}
      >
        {(value) => <TabLink href={value}>{value}</TabLink>}
      </DiffField>

      <DiffField
        label="Source URL"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.source}
      >
        {(value) => <TabLink href={value}>{value}</TabLink>}
      </DiffField>

      <DiffField
        label="Homey Community Topic ID"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => String(build.homeyCommunityTopicId)}
      >
        {(value) => (
          <TabLink href={`https://community.athom.com/t/${value}`}>
            {value}
          </TabLink>
        )}
      </DiffField>

      <DiffField
        label="Icon"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.icon}
        noVisualDiff
      >
        {(value) => <ImageLink src={value} width={75} />}
      </DiffField>

      <DiffField
        label="Image small"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.imageSmall}
        noVisualDiff
      >
        {(value) => <ImageLink src={value} />}
      </DiffField>

      <DiffField
        label="Image large"
        currentValue={build}
        previousValue={liveBuild}
        select={(build) => build.imageLarge}
        noVisualDiff
      >
        {(value) => <ImageLink src={value} />}
      </DiffField>
    </>
  );
}

function DriverDiff({ driver = {}, liveDriver = {} }) {
  const detailsRef = useRef(null);
  const hasChanges = useChildDiffFieldHasChanges(detailsRef);

  return (
    <details ref={detailsRef} className="Field" open={hasChanges}>
      <summary className={`Label ${hasChanges ? 'Modified' : ''}`}>
        Driver {driver.id || liveDriver.id}
      </summary>

      <DiffField
        label="Platforms"
        currentValue={driver}
        previousValue={liveDriver}
        select={(driver) => driver.platforms?.join(', ')}
      />

      <DiffField
        label="Name"
        currentValue={driver}
        previousValue={liveDriver}
        select={(driver) => translationObjectToString(driver.name)}
      />

      <DiffField
        label="Class"
        currentValue={driver}
        previousValue={liveDriver}
        select={(driver) => driver.class}
      />

      <DiffField
        label="Capabilities"
        currentValue={driver}
        previousValue={liveDriver}
        select={(driver) => driver.capabilities?.join('\n')}
      />

      <DiffField
        label="Icon"
        currentValue={driver}
        previousValue={liveDriver}
        select={(driver) => driver.icon}
        noVisualDiff
      >
        {(value) => <ImageLink src={value} width={75} />}
      </DiffField>

      <DiffField
        label="Image small"
        currentValue={driver}
        previousValue={liveDriver}
        select={(driver) => driver.imageSmall}
        noVisualDiff
      >
        {(value) => <ImageLink src={value} width={75} />}
      </DiffField>

      <DiffField
        label="Image large"
        currentValue={driver}
        previousValue={liveDriver}
        select={(driver) => driver.imageLarge}
        noVisualDiff
      >
        {(value) => <ImageLink src={value} width={250} />}
      </DiffField>
    </details>
  );
}

function FlowCardDiff({ type, card = {}, liveCard = {} }) {
  const detailsRef = useRef(null);
  const hasChanges = useChildDiffFieldHasChanges(detailsRef);

  return (
    <details ref={detailsRef} className="Field" open={hasChanges}>
      <summary className={`Label ${hasChanges ? 'Modified' : ''}`}>
        Flow {type} {card.id || liveCard.id}
      </summary>

      <DiffField
        label="Platforms"
        currentValue={card}
        previousValue={liveCard}
        select={(card) => card.platforms?.join(', ')}
      />

      <DiffField
        label="Title"
        currentValue={card}
        previousValue={liveCard}
        select={(card) => translationObjectToString(card.title)}
      />

      <DiffField
        label="Formatted Title"
        currentValue={card}
        previousValue={liveCard}
        select={(card) => translationObjectToString(card.titleFormatted)}
      />

      <DiffField
        label="Hint"
        currentValue={card}
        previousValue={liveCard}
        select={(card) => translationObjectToString(card.hint)}
      />

      <DiffField
        label="Arguments"
        currentValue={card}
        previousValue={liveCard}
        select={(card) => card.args?.map((arg) => {
          return <span key={arg.name}><b>{`${arg.name} [${arg.type}]\n\n`}</b>{translationObjectToString(arg.title)}</span>
        })}
      />

      <DiffField
        label="Tokens"
        currentValue={card}
        previousValue={liveCard}
        select={(card) => card.tokens?.map((token) => {
          return <span key={token.name}><b>{`${token.name} [${token.type}]\n\n`}</b>{translationObjectToString(token.title)}</span>
        })}
      />

      {type === 'action' && (<DiffField
        label="Advanced"
        currentValue={card}
        previousValue={liveCard}
        select={(card) => {
          // A action card having tokens implies advanced but advanced can also
          // be set manually.

          if (card.advanced != null) {
            return String(card.advanced);
          }

          if (type === 'action' && Array.isArray(card.tokens) && card.tokens.length > 0) {
            return 'true';
          }

          return 'false';
        }}
      />)}

    </details>
  );
}

function TabLink({ href, children }) {
  return (
    <a
      className="Link"
      target="_blank"
      rel="nofollow noopener noreferrer"
      href={href}
    >
      {children}
    </a>
  );
}

function ImageLink({ src, width }) {
  return (
    <TabLink href={src}>
      <img style={{ width }} src={src} alt="" />
    </TabLink>
  );
}

function Swatch({ color }) {
  return (
    <>
      <span className="Color" style={{ backgroundColor: color }} />
      {color}
    </>
  );
}

const LanguageMap = {
  en: "🇬🇧",
  nl: "🇳🇱",
  de: "🇩🇪",
  fr: "🇫🇷",
  it: "🇮🇹",
  es: "🇪🇸",
  sv: "🇸🇪",
  no: "🇳🇴",
  da: "🇩🇰",
  ru: "🇷🇺",
  pl: "🇵🇱",
};

function translationObjectToString(translations) {
  if (translations === null) {
    return '[null]';
  }

  if (typeof translations === 'string') {
    return `${LanguageMap['en']} ${translations}`;
  }

  if (typeof translations === 'object') {
    const result = Object.entries(translations).map(([code, text]) => {
      return `${LanguageMap[code] || code} ${text}`;
    });
    return result.join('\n');
  }

  return undefined;
}

function DiffField({
  label,
  currentValue,
  previousValue,
  select,
  children = (x) => <>{x}</>,
  noVisualDiff = false,
}) {
  const selectedCurrentValue = select(currentValue);
  const renderedCurrentValue = children(selectedCurrentValue);

  const selectedPreviousValue = select(previousValue);
  const renderedPreviousValue = children(selectedPreviousValue);

  // NOTE: this works around a limitation of react-visual-diff
  // If we render an empty component on either side it will display the other side without changes.
  // So to show that items have been added or removed we do an additional diff here.
  if (noVisualDiff || selectedPreviousValue == null || selectedCurrentValue == null) {
    const isChanged = noVisualDiff === false && selectedPreviousValue !== selectedCurrentValue;
    const isPreviousEmpty = selectedPreviousValue == null;
    const isCurrentEmpty = selectedCurrentValue == null;

    return (
      <div className={`Field ${label}`}>
        <div className="Label">{label}</div>
        <div className="Value">
          <span className={`${isChanged && !isPreviousEmpty ? 'DiffRemoved' : ''}`}>
            {renderedPreviousValue}
          </span>
        </div>
        <div className="Value">
          <span className={`${isChanged && !isCurrentEmpty ? 'DiffAdded' : ''}`}>
            {renderedCurrentValue}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className={`Field ${label}`}>
      <div className="Label">{label}</div>
      <div className="Value">
        <VisualDiff
          left={renderedPreviousValue}
          right={renderedCurrentValue}
          renderChange={({ type, children }) => {
            return type === "added" ? (
              null
            ) : (
              <span className="DiffRemoved">{children}</span>
            );
          }}
        />
      </div>
      <div className="Value">
        <VisualDiff
          left={renderedPreviousValue}
          right={renderedCurrentValue}
          renderChange={({ type, children }) => {
            return type === "added" ? (
              <span className="DiffAdded">{children}</span>
            ) : (
              null
            );
          }}
        />
      </div>
    </div>
  );
}

function useChildDiffFieldHasChanges(ref) {
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const { current } = ref;
    if (!current) return;

    const diffs = current.querySelectorAll('.DiffAdded, .DiffRemoved');
    setHasChanges(Boolean(diffs.length));
  });

  return hasChanges;
}
