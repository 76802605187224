import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
unregister();

window.HYPopup = function (url, opts) {

	opts = opts || {}
	opts.width = opts.width || 400;
	opts.height = opts.height || 400;

	var left = (window.screen.width / 2) - (opts.width / 2);
	var top = (window.screen.height / 2) - (opts.height / 2);

	window.open(url, 'homey_dialog', 'width=' + opts.width + ', height=' + opts.height + ', left=' + left + ', top=' + top + ', menubar=no, status=no, toolbar=no');
}