import './style.css';
import _ from 'lodash';
import React, { Component, useState } from 'react';
import TimeAgo from 'react-timeago';
import { withSnackbar } from 'notistack';
import semver from 'semver'
import { withApi, withHomey, withMessages, withUser, } from '../../services/AthomApi';

import Page from '../Page';
import Card from '../Card';

import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import ReactJson from 'react-json-view';
import Button from "@material-ui/core/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconRefresh from '@material-ui/icons/Refresh';
import IconButton from "@material-ui/core/IconButton";
import IconCopy from "@material-ui/icons/FileCopy";
import CardHeader from "@material-ui/core/CardHeader";
import Select from "@material-ui/core/Select";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const MANAGER_ZIGBEE_COMMANDS = {
  EXTENDED_INTERVIEW: 'EXTENDED_INTERVIEW',
  EXTENDED_INTERVIEW_ABORT: 'EXTENDED_INTERVIEW_ABORT',
  INTERVIEW: 'INTERVIEW',
  UPDATE_NODES: 'UPDATE_NODES',
  CHANGE_CHANNEL: 'CHANGE_CHANNEL',
  ADD_DEVICE: 'ADD_DEVICE',
  RESET: 'reset',
  PING: 'PING'
};

const MANAGER_ZIGBEE_COMMAND_ERRORS = {
  CONTROLLER_START_FAILED: 'controller_start_failed',
  CONTROLLER_RESET_FAILED: 'controller_reset_failed',
  BUSY: 'busy',
  UNKNOWN: 'unknown'
}

/**
 * TableCell with hover action, by default shows TimeAgo, and on hover shows exact date.
 * @returns 
 */
const LastSeenHoverableTableCell = ({ lastSeen }) => {
  if (!lastSeen) return <TableCell>{'?'}</TableCell>
  const [isHovered, setIsHovered] = useState(false);

  const timeAgoContent = <TimeAgo key="time" date={new Date(lastSeen)} />;
  const dateContent = new Date(lastSeen).toISOString();

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <TableCell
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {isHovered ? dateContent : timeAgoContent}
    </TableCell>
  );
};

class PageToolsZigbeeNodeZigbee extends Component {

  constructor(props) {
    super(props);

    this.state = {
      controllerState: {
        channel: '',
        panId: '',
        extPanId: '',
        ieeeAddr: '',
        extendedPanId: '',
        IEEEAddress: '',
        networkKey: '',
        currentCommand: '',
        zstackVersion: '',
        routes: {},
      },
      nodes: {},
      hoverNodeIndex: null,
      nodeInterviewBasicResult: null,
      nodeInterviewEndpointsResult: null,
      interviewResultDialogOpen: false,
      changingChannel: false,
      resettingNetwork: false,
    };
  }

  componentDidMount() {
    // Get current state on loading screen
    this._getState();

    const debouncedUpdateNodes = _.debounce(this._updateNodes.bind(this), 1000, { maxWait: 5000 });
    this.props.homey.zigbee
      .on('state', this._onZigbeeState)
      .connect()
      .catch(this.props.handleError);

    // Listen for a zigbee device update then update nodes to show name changes. Not applicable for Homey Pro 2023
    if (this._isLocalHomeyPlatformVersion2() === false) {
      this.props.homey.devices.on('device.update', (device) => {
        if (device && typeof Array.isArray(device.flags) && device.flags.includes('zigbee')) {
          debouncedUpdateNodes(); 
        }
      })
      .connect()
      .catch(this.props.handleError);
    }
  }

  componentWillUnmount() {
    if (this.props.homey) {
      this.props.homey.zigbee.destroy();
      this.props.homey.devices.destroy();
    }
  }

  _getState = () => {
    this.props.homey.zigbee
      .getState()
      .then(this._onZigbeeState)
      .then(this._updateNodes)
      .catch(this.props.handleError)
  };

  _updateNodes = async ({ withLqi = false } = {}) => {
    if (this._isBusy() && withLqi) return this.props.handleError(new Error(MANAGER_ZIGBEE_COMMAND_ERRORS.BUSY));
    this.setState({ updatingNodes: true });
    return this.props.homey.zigbee
      .runCommand({ 
        $timeout: 60000,
        command: MANAGER_ZIGBEE_COMMANDS.UPDATE_NODES, 
        opts: { withLqi } 
      })
      .then(() => {
        this.setState({ updatingNodes: false });
      })
      .catch((err) => {
        this.setState({ updatingNodes: false });
        const errorMessage = this._translateRunCommandError(err);
        this.props.handleError(errorMessage);
      })
  };

  _onInterviewNode = (ieeeAddr) => {
    if (this._isBusy()) return this.props.handleError(new Error(MANAGER_ZIGBEE_COMMAND_ERRORS.BUSY));
    this.setState({ interviewing: ieeeAddr, nodeInterviewBasicResult: null, nodeInterviewEndpointsResult: null, extendedInterviewResult: null });

    if (!this.state.nodes[ieeeAddr]) {
      console.log(`IEEE address not found ${ieeeAddr}`)
      return;
    }

    // Display warning message when interviewing sleepy node
    const { receiveWhenIdle } = this.state.nodes[ieeeAddr];
    if (receiveWhenIdle === false) {
      this.props.handleWarning('Wake up sleepy node before interview. Interviewing a sleepy' +
        ' node may take a while.');
    }

    this.props.homey.zigbee
      .runCommand({ command: MANAGER_ZIGBEE_COMMANDS.EXTENDED_INTERVIEW, opts: { ieeeAddr } })
      .catch(err => {
        const errorMessage = this._translateRunCommandError(err);
        if (errorMessage && (errorMessage.includes('timeout') || errorMessage.includes('timed out'))) {
          return this.props.handleError(new Error('Device did not respond'));
        }
        console.log('Error: failed to perform extended interview', err);
        this.props.handleError(errorMessage);
      })
  };

  _onInterviewNodeAbort = () => {
    this.setState({ interviewing: null, nodeInterviewBasicResult: null, nodeInterviewEndpointsResult: null, extendedInterviewResult: null });

    this.props.homey.zigbee
      .runCommand({ command: MANAGER_ZIGBEE_COMMANDS.EXTENDED_INTERVIEW_ABORT})
      .catch(err => {
        const errorMessage = this._translateRunCommandError(err);
        if (errorMessage && (errorMessage.includes('timeout') || errorMessage.includes('timed out'))) {
          return this.props.handleError(new Error('Device did not respond'));
        }
        console.log('Error: could not start extended interview', err);
        this.props.handleError(errorMessage);
      })
  };

  /**
   * Pings a node for its online state. Uses a call to active endpoints to determine online state.
   * Only available on Homey Pro (Early 2023)
   * @param {IEEEAddress} ieeeAddr
   */
  _onPing = (ieeeAddr) => {
    if (this._isLocalHomeyPlatformVersion2() === false) return this.props.handleError(new Error('Not Supported'));
    if (this._isBusy()) return this.props.handleError(new Error(MANAGER_ZIGBEE_COMMAND_ERRORS.BUSY));

    if (!this.state.nodes[ieeeAddr]) {
      console.log(`IEEE address not found ${ieeeAddr}`)
      return;
    }

    // Show warning when pining sleepy device
    const { receiveWhenIdle } = this.state.nodes[ieeeAddr]
    if (receiveWhenIdle === false) {
      this.props.handleWarning('Wake up device when pinging, it cannot receive commands when asleep.');
    }
    
    this.props.homey.zigbee
      .runCommand({ command: MANAGER_ZIGBEE_COMMANDS.PING , opts: { ieeeAddr } })
      .then(() => this.props.handleSuccess(`Device responded to ping`))
      .catch((err) => {
        this.props.handleError(err.message);
      })
  };
  /**
   * Parse an error that resulted from `zigbee.runCommand`. Check if the error is known if so
   * replace it with a human readable form. Otherwise pass it on.
   * @param {Error|string|undefined} err
   * @returns {string|*}
   * @private
   */
  _translateRunCommandError(err) {
    let errorMessage = err instanceof Error ? err.message : err;

    // TODO: remove someday if ManagerZigbee no longer returns these 'error.' formats (this is removed as of Homey v5.0.0-rc.45)
    if (errorMessage && errorMessage.includes('error.')) errorMessage = errorMessage.replace('error.', '');
    console.log('run command failed', err);
    switch (errorMessage) {
      case MANAGER_ZIGBEE_COMMAND_ERRORS.CONTROLLER_START_FAILED: return 'Zigbee could not be started'
      case MANAGER_ZIGBEE_COMMAND_ERRORS.CONTROLLER_RESET_FAILED: return 'Zigbee could not be started'
      case MANAGER_ZIGBEE_COMMAND_ERRORS.BUSY: return 'Zigbee is busy, try again later'
      case MANAGER_ZIGBEE_COMMAND_ERRORS.UNKNOWN: return 'Unknown error occurred'
      default: return errorMessage;
    }
  }

  _onZigbeeState = state => {
    const controllerState = { ...this.state.controllerState };
    if (state && state.controllerState && state.controllerState.routes) {
      controllerState.routes = state.controllerState.routes;
    }
    if (state && state.controllerState && typeof state.controllerState.channel === 'number') {
      controllerState.channel = state.controllerState.channel;
    }
    if (state && state.controllerState && typeof state.controllerState.panId === 'number') {
      controllerState.panId = state.controllerState.panId.toString(16).toUpperCase();
    }
    if (state && state.controllerState && typeof state.controllerState.extendedPanId === 'string') {
      controllerState.extendedPanId = state.controllerState.extendedPanId;
    }
    if (state && state.controllerState && typeof state.controllerState.IEEEAddress === 'string') {
      controllerState.IEEEAddress = state.controllerState.IEEEAddress;
    }
    if (state && state.controllerState && typeof state.controllerState.zstackVersion === 'string') {
      controllerState.zstackVersion = state.controllerState.zstackVersion;
    }
    if (state && state.controllerState && typeof state.controllerState.networkKey === 'string') {
      controllerState.networkKey = state.controllerState.networkKey;
    }
    if (state && state.controllerState && typeof state.controllerState.networkAddress === 'number') {
      controllerState.networkAddress = state.controllerState.networkAddress.toString(16).toUpperCase();
    }
    if (state && state.zigbee_state && typeof state.zigbee_state.currentCommand === 'string') {
      controllerState.currentCommand = state.zigbee_state.currentCommand;
    }

    // Handle a new extended interview result
    if (this.state.interviewing && state && state.extendedInterviewResult && !this.state.extendedInterviewResult) {
      if (state.extendedInterviewResult.success === false) {
        this.props.handleError(`Interview failed (error: ${state.extendedInterviewResult.error})`);
        this.setState({
          interviewing: false,
          interviewResultDialogOpen: false,
          nodeInterviewBasicResult: null,
          extendedInterviewResult: new Error(state.extendedInterviewResult.error)
        });
      } else if (this.state.nodes[this.state.interviewing]) {
        this.props.handleSuccess(`Interview done`);
        const { modelId, manufacturerName } = this.state.nodes[this.state.interviewing];
        this.setState({
          interviewing: false,
          interviewResultDialogOpen: true,
          nodeInterviewBasicResult: {
            modelId,
            manufacturerName,
          },
          extendedInterviewResult: state.extendedInterviewResult.data
        });
      }
    }

    this.setState({
      controllerState,
      nodes: state.nodes ? state.nodes : {},
    })
  };

  _getDeviceIndex = ieeeAddr => {
    if (ieeeAddr === this.state.controllerState.IEEEAddress) return 0;
    const index = Object.keys(this.state.nodes).findIndex(key => ieeeAddr === key);
    if (index >= 0) return index + 1;
    return index;
  };

  _getDeviceColor = ieeeAddr => {
    let index = this._getDeviceIndex(ieeeAddr);
    return `hsl(${Math.floor((Math.max(0, index) / (Object.keys(this.state.nodes).length + 1)) * 360)}, 100%, 75%)`;
  };

  _isBusy() {
    if (this.state.controllerState.currentCommand === MANAGER_ZIGBEE_COMMANDS.RESET
      || this.state.controllerState.currentCommand === MANAGER_ZIGBEE_COMMANDS.CHANGE_CHANNEL
      || this.state.controllerState.currentCommand === MANAGER_ZIGBEE_COMMANDS.ADD_DEVICE
      || this.state.controllerState.currentCommand === MANAGER_ZIGBEE_COMMANDS.INTERVIEW
      || this.state.controllerState.currentCommand === MANAGER_ZIGBEE_COMMANDS.EXTENDED_INTERVIEW
    ) {
      return true
    }
    return false;
  }

  _isLocalHomeyPlatformVersion2() {
    return this.props.homey.platformVersion === 2 && this.props.homey.platform === 'local';
  }

  onCloseInterviewDialog = () => {
    this.setState({ interviewResultDialogOpen: false })
  };

  onClickResetNetwork = () => {
    this.setState({ resettingNetwork: true });
  }

  resetNetwork = () => {
    this.handleResetNetworkDialogClose();
    this.props.homey.zigbee
      .runCommand({ command: MANAGER_ZIGBEE_COMMANDS.RESET })
      .then(() => this.props.handleSuccess(`Resetting Zigbee network, this might take some time.`))
      .catch(err => {
        const errorMessage = this._translateRunCommandError(err);
        return this.props.handleError(errorMessage);
      })
  }

  handleResetNetworkDialogClose() {
    this.setState({ resettingNetwork: false });
  }

  handleChannelChangeRequest(event) {
    const selectedChannel = Number(event.target.value);
    if (selectedChannel !== this.state.controllerState.channel) {
      this.setState({ changingChannel: Number(event.target.value) });
    }
  }

  changeChannel() {
    const channel = this.state.changingChannel;
    this.handleChannelChangeDialogClose();
    if (this._isBusy()) return this.props.handleError(new Error(MANAGER_ZIGBEE_COMMAND_ERRORS.BUSY));
    this.props.homey.zigbee
      .runCommand({ command: MANAGER_ZIGBEE_COMMANDS.CHANGE_CHANNEL, opts: { channel } })
      .then(() => this.props.handleSuccess(`Channel changing to ${channel}`))
      .catch(err => {
        const errorMessage = this._translateRunCommandError(err);
        return this.props.handleError(errorMessage);
      })
  }

  handleChannelChangeDialogClose() {
    this.setState({
      changingChannel: false,
    });
  }

  render() {
    const { menuAnchorEl } = this.state;

    const RouteNode = ({ index, ieeeAddr }) => {
      const index_ = typeof index === 'number' ? index : this._getDeviceIndex(ieeeAddr);
      return <span
        key={index_}
        className={'RouteNode'}
        style={{
          backgroundColor: typeof ieeeAddr === 'string' ? this._getDeviceColor(ieeeAddr) : 'hsl(0, 0%, 86%)',
          transform: this.state.hoverNodeIndex === index_ ? 'scale(1.35)' : 'scale(1.0)',
        }}
        onMouseLeave={() => this.setState({ hoverNodeIndex: null })}
        onMouseEnter={() => this.setState({ hoverNodeIndex: index_ })}
      >{typeof ieeeAddr === 'string' ? Math.max(0, index_) : '?'}</span>
    };

    const InterviewButton = ({ ieeeAddr }) => {
      if (this._isLocalHomeyPlatformVersion2() === true) {
        return (
          <MenuItem button 
            disabled={(this._isBusy() && this.state.interviewing !== ieeeAddr)} 
            onClick={() => {this.state.interviewing === ieeeAddr ? this._onInterviewNodeAbort() : this._onInterviewNode(ieeeAddr)}}
            style={this.state.interviewing === ieeeAddr ? { color: 'red' } : {}}
          >
            { this.state.interviewing === ieeeAddr ? 'Stop interviewing' : 'Interview' }
          </MenuItem>
        )
      }

      return (
        <MenuItem button 
          disabled={(this._isBusy() || typeof this.state.interviewing === 'string')} 
          onClick={() => this._onInterviewNode(ieeeAddr)}
        >
          { this.state.interviewing === ieeeAddr ? 'Interviewing' : 'Interview' }
        </MenuItem>
      )
    };

    // Start counting down from -2 for unknown routes (-1 is coordinator)
    let unknownRouteIndex = -2;

    const _getIEEEAddrByNwkAddr = (nwkAddr) => {
      for (const [ieeeAddr, node] of Object.entries(this.state.nodes)) {
        if (Number(node.nwkAddr) === Number(nwkAddr)) return ieeeAddr;
      }
      return null;
    };

    const _getRouteNodes = ({ ieeeAddr, nwkAddr, parent, type }) => {
      let routeToNode = [];
      // If node is end device with parent use parent's route
      if (typeof parent === 'number' && type === 'EndDevice') {

        // If parent is controller, push controller to the route
        if (parent === 0) routeToNode.push(<RouteNode ieeeAddr={this.state.controllerState.IEEEAddress} />);
        else {
          // If parent is not controller check if we have a route the parent if not, return unknown route node
          if (!this.state.controllerState.routes[parent]) return [<RouteNode index={unknownRouteIndex--} />];

          // Add parent route to this route
          routeToNode = _getRouteNodes({ ieeeAddr: _getIEEEAddrByNwkAddr(parent), nwkAddr: parent, type });
        }
      } else {

        // If we have no route to the nwkAddr, return unknown route node
        if (!this.state.controllerState.routes[nwkAddr]) {
          return [<RouteNode index={unknownRouteIndex--} />];
        }

        // First push the coordinator as starting point for route
        routeToNode.push(<RouteNode ieeeAddr={this.state.controllerState.IEEEAddress} />);

        // Iterate array backwards (since routes are provided backwards)
        for (let i = this.state.controllerState.routes[nwkAddr].length - 1; i >= 0; i--) {
          routeToNode.push(<span>→</span>);
          // Set an index if ieeeAddr is not found, in theory it might be possible that a node routes via node that has no connection to a Homey device
          routeToNode.push(<RouteNode
            index={typeof this.state.controllerState.routes[nwkAddr][i] === 'number' ? null : unknownRouteIndex--}
            ieeeAddr={_getIEEEAddrByNwkAddr(this.state.controllerState.routes[nwkAddr][i])} />);
        }
      }

      // Finally push destination node (self)
      routeToNode.push(<span>→</span>);
      routeToNode.push(<RouteNode ieeeAddr={ieeeAddr} />);
      return routeToNode;
    };

    return (
      <Page className="PageToolsZigbee" cards={true}>
        <Card>
          <CardHeader
            title="Nodes"
            action={
              this._isLocalHomeyPlatformVersion2() === false && // Refreshing routes not possible on Homey Pro 2023
              <IconButton tooltip="Refresh routes"
                disabled={this.state.updatingNodes
                  || this._isBusy()
                  || typeof this.state.interviewing === 'string'}
                classes={{ root: this.state.updatingNodes ? 'rotate' : '' }}
                onClick={() => this._updateNodes({ withLqi: true })}>
                <IconRefresh />
              </IconButton>
            }
            style={{ padding: 0 }}
          />

          <div style={{
            overflowX: 'auto'
          }}>
            <Table
              padding="none"
              style={{
                minWidth: 1000,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>NodeID</TableCell>
                  <TableCell>Device</TableCell>
                  <TableCell>IEEE Address</TableCell>
                  <TableCell>Network Address</TableCell>
                  <TableCell>Type</TableCell>
                  { this._isLocalHomeyPlatformVersion2() === false ? <TableCell>Online</TableCell> : <TableCell>Last Seen</TableCell> }
                  <TableCell>Receive When Idle</TableCell>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>ModelID</TableCell>
                  <TableCell>Action</TableCell>
                  { this._isLocalHomeyPlatformVersion2() === false && // Routes not available on Homey Pro 2023
                  <TableCell>Route</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={this.state.controllerState.IEEEAddress}>
                  <TableCell>
                    <RouteNode ieeeAddr={this.state.controllerState.IEEEAddress} />
                  </TableCell>
                  <TableCell>{this.props.homey.name}</TableCell>
                  <TableCell>{this.state.controllerState.IEEEAddress}</TableCell>
                  <TableCell>{0}</TableCell>
                  <TableCell>{'Coordinator'}</TableCell>
                  <TableCell>{'-'}</TableCell>
                  <TableCell>{''}</TableCell>
                  <TableCell>{'Athom B.V.'}</TableCell>
                  <TableCell>{'Homey'}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {Object.entries(this.state.nodes).map(([ieeeAddr, { manufacturerName, modelId, name, type, parent, nwkAddr, receiveWhenIdle, online, lastSeen }]) => (
                  <TableRow key={ieeeAddr}>
                    <TableCell>
                      <RouteNode ieeeAddr={ieeeAddr} />
                    </TableCell>
                    <TableCell>{name || <em>Unknown</em>}</TableCell>
                    <TableCell>{ieeeAddr}</TableCell>
                    <TableCell>{typeof nwkAddr === 'undefined' ? '?': `${nwkAddr} (${(nwkAddr || '').toString(16).toUpperCase()})` }</TableCell>
                    <TableCell>{type}</TableCell>
                    { this._isLocalHomeyPlatformVersion2() === false ? 
                      <TableCell>{online === true ? '✓' : online === false ? 'x' : '?'}</TableCell> :
                      <LastSeenHoverableTableCell lastSeen={lastSeen} />
                    }
                    <TableCell>{`${typeof receiveWhenIdle === 'boolean' ? receiveWhenIdle ? '✓' : 'x' : ''}`}</TableCell>
                    <TableCell>{manufacturerName || <em>Unknown</em>}</TableCell>
                    <TableCell>{modelId}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={event => {
                          this.setState({
                            menuAnchorEl: event.currentTarget,
                            menuOpenIeeeAddr: ieeeAddr,
                          });
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={menuAnchorEl}
                        open={this.state.menuOpenIeeeAddr === ieeeAddr}
                        onClose={() => {
                          this.setState({
                            menuAnchorEl: null,
                            menuOpenIeeeAddr: null,
                          })
                        }}
                      >
                        <InterviewButton ieeeAddr={ieeeAddr} />
                        { this._isLocalHomeyPlatformVersion2() && // Homey Pro 2023 exclusive feature
                          <MenuItem button  disabled={this._isBusy()} onClick={() => this._onPing(ieeeAddr)}>Ping</MenuItem>
                        }
                      </Menu>
                    </TableCell>
                    { this._isLocalHomeyPlatformVersion2() === false && // Routes not available on Homey Pro 2023
                     <TableCell>{_getRouteNodes({ ieeeAddr, nwkAddr, parent, type })}</TableCell>
                    } 
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>
        <Card>
          <CardHeader
            title="System Information"
            action={
              <Button onClick={this.onClickResetNetwork} color="primary">
                Reset Network
              </Button>
            }
            style={{ padding: 0 }}
          />
          { (this.props.homey.platformVersion === 1 && this.props.homey.platform === 'local') // Changing Zigbee channel only available on Homey Pro 2016 - 2019
            ? (
            <span>
              <br />
              <FormControl>
                <InputLabel>Channel</InputLabel>
                <Select
                    label="Channel"
                    margin="none"
                    disabled={this._isBusy()}
                    onChange={this.handleChannelChangeRequest.bind(this)}
                    style={{ width: 400 }}
                    value={this.state.controllerState.channel}
                    displayEmpty
                    >
                      <MenuItem value={"11"}>11</MenuItem>
                      <MenuItem value={"12"}>12</MenuItem>
                      <MenuItem value={"13"}>13</MenuItem>
                      <MenuItem value={"14"}>14</MenuItem>
                      <MenuItem value={"15"}>15</MenuItem>
                      <MenuItem value={"16"}>16</MenuItem>
                      <MenuItem value={"17"}>17</MenuItem>
                      <MenuItem value={"18"}>18</MenuItem>
                      <MenuItem value={"19"}>19</MenuItem>
                      <MenuItem value={"20"}>20</MenuItem>
                      <MenuItem value={"21"}>21</MenuItem>
                  </Select>
              </FormControl>
            </span>
            )
            : <TextField
              value={this.state.controllerState.channel}
              label="Channel"
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              style={{ width: 400 }}
             />
            } 
          <br />
          <TextField
            value={this.state.controllerState.panId}
            label="Pan ID"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={this.state.controllerState.extendedPanId}
            label="Extended PAN ID"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={this.state.controllerState.IEEEAddress}
            label="IEEE Address"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={this.state.controllerState.networkKey}
            label="Network Key"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={this.state.controllerState.currentCommand}
            label="Current Command"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
          <br />
          <TextField
            value={`${this.state.controllerState.zstackVersion}`}
            label="Firmware Version"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            style={{ width: 400 }}
          />
        </Card>

        <Dialog open={this.state.interviewResultDialogOpen} onClose={this.onCloseInterviewDialog}>
          <div>
            <DialogTitle className="dialogTitle">
              Interview result
              <CopyToClipboard
                text={JSON.stringify({
                  ids: this.state.nodeInterviewBasicResult,
                  endpoints: this.state.extendedInterviewResult
                }, null, 2).substr(1).slice(0, -1)}
                onCopy={() => this.props.handleSuccess('Interview result copied to clipboard')}>
                <IconButton tooltip="Copy to Clipboard"
                  style={{ right: '24px', top: '16px', position: 'absolute' }}>
                  <IconCopy />
                </IconButton>
              </CopyToClipboard>
            </DialogTitle>
            <DialogContent>
              <ReactJson
                theme="google"
                src={{ ...(this.state.nodeInterviewBasicResult || {}), ...(this.state.extendedInterviewResult || {}) }}
                collapsed={1}
                name={'node'}
                enableClipboard={false}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onCloseInterviewDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          open={this.state.resettingNetwork}
        >
          <DialogTitle>⚠ Reset Network</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
            {
              this.props.homey.platform === "local" && semver.gt(this.props.homey.softwareVersion, "7.4.1") === false
                ? "You are about to reset the Zigbee network. This will remove all your Zigbee devices from Homey."
                : this.props.homey.platformVersion === 2
                  ? "You are about to reset the Zigbee network. This will make all your Zigbee and Thread devices unavailable."
                  : "You are about to reset the Zigbee network. This will make all your Zigbee devices unavailable."
            }
            </Typography>
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleResetNetworkDialogClose.bind(this)} color="default">
              Cancel
            </Button>
            <Button onClick={this.resetNetwork.bind(this)} color="primary">
              Reset
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.changingChannel}
        >
          <DialogTitle>⚠ Changing Zigbee channel</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              You are about to change the channel of your Zigbee
              network.</Typography>
            <br />
            <Typography variant="body1">
              This should only be necessary if you are certain the network is
              suffering from severe interference on it's current channel.</Typography>
            <br />
            <Typography variant="body1">
              It will cause the network to become unstable until all nodes managed
              to switch to the new channel. Additionally, some devices, which do not follow the Zigbee
              specification (e.g. Xiaomi), might not switch at all, requiring a re-pair.</Typography>
            <br />
            <Typography variant="body1">
              Are you sure you want to continue? If so, make sure all nodes
              connected to the network are powered on and within reach of the controller. It is required
              that at least one router is on the network. If you have no Zigbee devices paired to Homey it
              is best to let the Zigbee chip select a quiet channel by resetting the network. Changing
              channels might take a few minutes.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleChannelChangeDialogClose.bind(this)} color="default">
              Cancel
            </Button>
            <Button
              onClick={this.changeChannel.bind(this)}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsZigbeeNodeZigbee, '>4.2.0')))));
