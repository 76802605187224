import './style.css';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {
  withApi,
  withUser,
  withHomey,
  withMessages,
} from '../../services/AthomApi';
import Page from '../Page';
import Card from '../Card';
import Editor from 'react-simple-code-editor';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconPlay from '@material-ui/icons/PlayArrow';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import '../../prism-a11y-dark.css';

import ReactJson from 'react-json-view';

class PageToolsAPIPlayground extends Component {

  constructor(props) {
    super(props);

    this.state = {
      running: false,
      code: `Homey.users.getUserMe();

// Examples:
// Homey.devices.getDevices({ online: true });
// Homey.flow.getFlows();
// Homey.apps.getApps();`,
      result: undefined,
    }
  }

  handleRun = () => {
    const { running, code } = this.state;
    if (running) return;

    this.setState({
      result: undefined,
      running: true,
    });

    // eslint-disable-next-line
    const Homey = this.props.homey;

    // eslint-disable-next-line
    const AthomCloudAPI = this.props.api;

    const start = Date.now();
    Promise.all([
      (function () {
        return Promise.resolve().then(() => {
          // eslint-disable-next-line
          return eval(code);
        })
      })().then(result => {
        console.log('Response:', result);

        const stop = Date.now();
        this.props.handleSuccess(`Request took ${stop - start}ms`);
        this.setState({
          result,
        });
      }).catch(err => {
        console.error('Error:', err);
        this.setState({
          result: {
            error: err.message || err.toString(),
          },
        });
      }),
      new Promise(resolve => {
        setTimeout(resolve, 300);
      }),
    ])
      .then(() => {
        this.setState({
          running: false,
        });
      });
  }

  render() {
    const {
      running,
      code,
      result,
    } = this.state;

    return (
      <Page
        cards={true}
        className="PageToolsAPIPlayground"
      >

        <Card
          title="Web API Playground"
          subtitle={<span>All requests will be executed on <strong>{this.props.homey.name}</strong>.</span>}
        />

        <Card
          title="Request"
          subtitle={
            <React.Fragment>
              <span>Visit the </span>
              <a target="_blank" rel="noopener noreferrer" href="https://athombv.github.io/node-homey-api/">Web API documentation</a>
              <span>.</span>
            </React.Fragment>
          }
          toolbar={(
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleRun}
              disabled={running}
            >
              {running && (
                <React.Fragment>
                  <CircularProgress
                    size={24}
                    style={{
                      color: 'white',
                    }}
                  />
                  &nbsp;Running
                </React.Fragment>
              )}
              {!running && (
                <React.Fragment>
                  <IconPlay />
                  &nbsp;Run
                </React.Fragment>

              )}
            </Button>
          )}
        >

          <div style={{
            border: '1px solid #ccc',
            padding: 12,
            marginTop: 24,
          }}
          >
            <Editor
              value={code}
              onValueChange={(code) => {
                this.setState({ code });
              }}
              highlight={code => highlight(code, languages.js)}
              style={{
                fontFamily: '"Roboto Mono", monospace',
                fontWeight: 300,
                fontSize: 14,
                outline: 'none',
                minHeight: 100,
              }}
            />
          </div>

        </Card>

        {typeof result !== 'undefined' && (
          <Card
            title="Response"
          >
            {this.renderResult()}
          </Card>
        )}

      </Page>
    );
  }

  renderResult() {
    const { result } = this.state;

    if (result === null)
      return <pre>NULL</pre>;

    if (typeof result === 'boolean')
      return <pre style={{
        fontSize: 14,
        color: 'rgb(174, 129, 255)',
      }}>{result ? 'true' : 'false'}</pre>;

    if (typeof result === 'number')
      return <pre style={{
        fontSize: 14,
        color: 'rgb(166, 226, 46)',
      }}>{result}</pre>;

    if (typeof result === 'string')
      return <pre style={{
        fontSize: 14,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        color: 'rgb(253, 151, 31)',
      }}>"{result}"</pre>;

    return (
      <ReactJson
        theme='monokai'
        src={result}
        collapsed={false}
        style={{
          fontSize: 14,
          backgroundColor: 'transparent',
        }}
      />
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(withRouter(PageToolsAPIPlayground))))));
