import './style.css';
import React, { Component } from 'react';
import {
  Button,
  TextField,
  Divider,
  Card,
  CardHeader,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
} from '@material-ui/core';

class DescriptorView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      writeValue: "[]",
      readValue: {},
      isReading: false,
      isWriting: false,
    }
  }

  componentDidMount() {
    const { scrollRef } = this.props;
    scrollRef.scrollLeft = 9999;
  }

  render() {
    const { peripheral, service, runCommand, characteristic, descriptor } = this.props;

    const readDescriptor = async () => {
      this.setState({ isReading: true });
      const result = await runCommand('READ_DESCRIPTOR', {
        peripheralUuid: peripheral.uuid,
        serviceUuid: service.uuid,
        characteristicUuid: characteristic.uuid,
        descriptorUuid: descriptor.uuid,
      }).finally(() => {
        this.setState({ isReading: false });
      });
      if (result) this.setState({ readValue: result, readModalOpen: true });
    }

    const writeDescriptor = (data) => {
      this.setState({ isWriting: true });
      runCommand('WRITE_DESCRIPTOR', {
        peripheralUuid: peripheral.uuid,
        serviceUuid: service.uuid,
        characteristicUuid: characteristic.uuid,
        descriptorUuid: descriptor.uuid,
        data
      })
        .finally(() => {
          this.setState({ isWriting: false })
        });
    }

    const handleChange = (event) => {
      this.setState({ writeValue: event.target.value });
    }

    const closeReadModal = () => {
      this.setState({ readModalOpen: false });
    }

    const closeWriteModal = () => {
      this.setState({ writeModalOpen: false });
    }

    if (descriptor) {

      return (
        <Card className="ble-card">
          <CardHeader title={<span> {descriptor.name || "unknown"} </span>} subheader="Descriptor" />
          <Divider />
          <List>
            {/* Show a switch for subscribing/unsubscribing */}
            <ListItem>


              <div className="horizontal_buttons">

                {/* Discover Services*/}
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => {
                    this.setState({ writeModalOpen: true })
                  }}>
                  Write
                </Button>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  disabled={this.state.isReading}
                  onClick={readDescriptor}>
                  Read
                  {(this.state.isReading) && <CircularProgress className="circularProgress" size={24} />}
                </Button>
              </div>
            </ListItem>
          </List>
          <Divider />

          {/* Details */}
          <List subheader={<ListSubheader>Details</ListSubheader>}>
            <ListItem>
              <ListItemText
                primary={<Typography> Uuid </Typography>}
                secondary={<Typography><code>{descriptor.uuid}</code></Typography>} />
            </ListItem>
          </List>
          <Divider />

          <Dialog open={this.state.readModalOpen} onClose={closeReadModal} maxWidth={'xl'}>
            <DialogTitle>{"Read results"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Read results from characteristic {characteristic.uuid}.
              </DialogContentText>
              <Table>
                <TableHead>
                  <TableCell>Raw</TableCell>
                  <TableCell>Hex</TableCell>
                  <TableCell>String</TableCell>
                </TableHead>
                <TableBody>
                  <TableCell>
                    <code> {JSON.stringify(this.state.readValue.data)} </code>
                  </TableCell>
                  <TableCell>
                    <code> {(this.state.readValue && this.state.readValue.data) ? Buffer.from(this.state.readValue.data).toString('hex') : ''} </code>
                  </TableCell>
                  <TableCell>
                    <code> {(this.state.readValue && this.state.readValue.data) ? Buffer.from(this.state.readValue.data).toString() : ''} </code>
                  </TableCell>
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeReadModal} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog open={this.state.writeModalOpen} onClose={closeWriteModal}>
            <DialogTitle>{"Writing data"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography> Use this to write data to Descriptor {descriptor.uuid}.
                  The expected format is <code>[value, value, ... ]</code> where <code> value </code> is a decimal
                  between 0 and 255. If you don't know what to write, then it may be useful to first read from the
                  characteristic to deduce an expected format. </Typography>
              </DialogContentText>
              <TextField id="standard-basic" label="Standard" value={this.state.writeValue} onChange={handleChange} />
              <Button variant="contained"
                color="primary"
                onClick={() => {
                  writeDescriptor(JSON.parse(this.state.writeValue))
                }}
                disabled={this.state.isWriting}
              > Write
                {(this.state.isWriting) && <CircularProgress className="circularProgress" size={24} />}

              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeWriteModal} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      );
    }
    return null;
  }
}

export default DescriptorView;
