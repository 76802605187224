import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AccountIcon from '@material-ui/icons/AccountCircle';
import ErrorIcon from '@material-ui/icons/Error';
import semver from 'semver';
import qs from 'query-string';
import findIndex from 'lodash/findIndex';

import {
	ATHOM_DEVELOPER_API_CLIENT_ID,
	ATHOM_DEVELOPER_API_CLIENT_SECRET,
} from '../../config';

import AthomCloudAPI from 'homey-api/lib/AthomCloudAPI';
import AthomAppsAPI from 'homey-api/lib/AthomAppsAPI';

class AthomApi {

	constructor() {
		this.user = null;
		this.api = null;
		this.homey = null;
		this._activeHomeyChangeListeners = [];
		let baseUrl;
		if (window.location.hostname.endsWith('.dev')) {
			baseUrl = 'https://api.athom.dev';
		}

		this.api = new AthomCloudAPI({
			clientId: ATHOM_DEVELOPER_API_CLIENT_ID,
			clientSecret: ATHOM_DEVELOPER_API_CLIENT_SECRET,
			baseUrl,
			redirectUrl: window.location.protocol + '//' + window.location.host,
			debug: window.location.host.includes('localhost'),
		});

		this.user = Promise.resolve().then(async () => {
			if (this.api.hasAuthorizationCode()) {
				await this.api.authenticateWithAuthorizationCode();
				const { state } = qs.parse(window.location.search);
				const { origin } = qs.parse(state);
				window.location.href = origin;
				return;
			}

			if (!await this.api.isLoggedIn())
				throw new Error('not_logged_in');

			return this.api.getAuthenticatedUser();
		});

		this.user.catch(async err => {
			// Happens on password change or 2FA change.
			if (err.statusCode === 400 && err.message === 'Invalid refresh token'){
				await this.api.logout().catch(console.error);
				window.location.reload();
				return;
			}

			if (err && err.message !== 'not_logged_in')
				console.error(err);
		});

		this.homey = Promise.resolve().then(async () => {
			const user = await this.user;

			const { homey } = qs.parse(window.location.search);
			if (homey) {
				this.setActiveHomeyId(homey);
			}

			if (window.localStorage) {
				const activeHomeyId = this.getActiveHomeyId();
				if (activeHomeyId) {
					const homey = user.getHomeyById(activeHomeyId);
					if (homey) {
						return this.setActiveHomey(activeHomeyId);
					} else {
						window.localStorage.removeItem('activeHomeyId')
					}
				}
			}

			const firstHomey = user.getFirstHomey();
			if (!firstHomey)
				throw new Error('no_homeys');

			return this.setActiveHomey(firstHomey._id);
		});
		this.homey.catch(err => {
			if (err && err.message !== 'not_logged_in')
				console.error(err);
		});
	}

	login() {
		window.location.href = this.api.getLoginUrl() + '&state=' + encodeURIComponent(`origin=${window.location.pathname}`);
	}

	logout() {
		return this.api.logout();
	}

	createDelegationToken(...props) {
		return this.api.createDelegationToken(...props);
	}

	setActiveHomey(homeyId) {
		const homeyApi = this.user.then(async user => {
			const homey = user.getHomeyById(homeyId);
			if (!homey)
				throw new Error('invalid_homey');

			const homeyApi = await homey.authenticate();
			homeyApi.name = homey.name;
			homeyApi.softwareVersion = homey.softwareVersion;
			return homeyApi;
		});

		this.homey = homeyApi;

		this.setActiveHomeyId(homeyId);
		this._activeHomeyChangeListeners.forEach(fn => fn(homeyId));

		return homeyApi;
	}

	getActiveHomeyId() {
		if (window.localStorage)
			return window.localStorage.getItem('activeHomeyId');
	}

	setActiveHomeyId(homeyId) {
		if (window.localStorage)
			return window.localStorage.setItem('activeHomeyId', homeyId);
	}

	registerActiveHomeyChangeListener(fn) {
		this._activeHomeyChangeListeners.push(fn);
	}

	unregisterActiveHomeyChangeListener(fn) {
		const index = findIndex(this._activeHomeyChangeListeners, fn)
		if (index !== -1) this._activeHomeyChangeListeners.splice(index, 1);
	}

}

const api = new AthomApi();
export default api;

export function withApi(WrappedComponent) {
	return class extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				api,
				...this.state,
			}
		}

		render() {
			const { api } = this.state;
			return <WrappedComponent
				api={api}
				{...this.props}
			/>
		}
	}
}

export function withUser(WrappedComponent) {
	return class extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				...this.state,
				user: null,
				error: null,
			}
		}

		componentDidMount() {
			this.props.api.user.then(user => {
				this.setState({ user });
			}).catch(error => {
				if (error.message === 'not_logged_in')
					return this.setState({ user: false });

				this.setState({ error });
			})
		}

		render() {
			const { user, error } = this.state;

			if (error)
				return <ErrorMessage error={error} />

			if (user)
				return <WrappedComponent
					user={user}
					{...this.props}
				/>

			if (user === false)
				return <AnyMessage
					message="Please log in to view this page."
					button={(
						<Button
							variant={'contained'}
							color="primary"
							onClick={() => this.props.api.login()}
							fullWidth={true}
							size="small"
						>
							<AccountIcon style={{
								marginRight: 10,
							}} />
							Log in
						</Button>
					)}
				>
					<AccountIcon style={{
						width: 48,
						height: 48,
					}} />
				</AnyMessage>

			return <LoadingMessage message="Authenticating User..." />
		}
	}
}

export function withHomey(WrappedComponent, { version, platforms = ['local', 'cloud'], platformVersions = [1, 2] } = {}) {
	return class extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				...this.state,
				homey: null,
				homeySoftwareVersion: null,
				error: null,
			}

			this.props.api.registerActiveHomeyChangeListener(this.onHomeyChange);
		}

		onHomeyChange = () => {
			this.setState({
				homey: null,
				error: null,
			});
			this._setHomey();
		}

		_setHomey = () => {
			this.props.api.homey.then(homey => {
				this.setState({
					homey,
					homeySoftwareVersion: homey.softwareVersion
						? homey.softwareVersion.split('-')[0]
						: null,
				});
			}).catch(error => {
				this.setState({ error });
			})
		}

		componentDidMount() {
			this._setHomey();
		}

		render() {
			const {
				homey,
				homeySoftwareVersion,
				error,
			} = this.state;

			if (error)
				return <ErrorMessage error={error} />

			if (homey) {
				if (version) {
					if (homeySoftwareVersion && !semver.satisfies(homeySoftwareVersion, version))
						return <ErrorMessage error={`This page requires that the selected Homey matches firmware version ${version}.\nThe selected Homey \`${homey.name}\` is version ${homey.softwareVersion}.`} />
				}

				if (!platforms.includes(homey.platform)) {
					// Pretty print the platforms array using comma's and 'or' for the last element.
					const platformsString = platforms.reduce((str, platform, index) => {
						if (index === platforms.length - 1 && platforms.length > 1) return `${str} or \`${platform}\``;
						if (index > 0) return `${str}, \`${platform}\``;
						else return `\`${platform}\``;
					}, '');
					return <ErrorMessage error={`This page requires that the selected Homey platform matches ${platformsString}.\nThe selected Homey \`${homey.name}\` has platform \`${homey.platform}\`.`} />
				}

				if (!platformVersions.includes(homey.platformVersion)) {
					// Pretty print the platforms array using comma's and 'or' for the last element.
					const platformVersionsString = platformVersions.reduce((str, platformVersion, index) => {
						if (index === platformVersions.length - 1 && platformVersions.length > 1) return `${str} or \`${platformVersion}\``;
						if (index > 0) return `${str}, \`${platformVersion}\``;
						else return `\`${platformVersion}\``;
					}, '');
					return <ErrorMessage error={`This page requires that the selected Homey platform matches ${platformVersionsString}.\nThe selected Homey \`${homey.name}\` has platform \`${homey.platformVersion}\`.`} />
				}

				return <WrappedComponent
					homey={homey}
					{...this.props}
				/>
			}

			return <LoadingMessage message="Loading Homey..." />
		}
	}
}

export function withApps(WrappedComponent) {
	return class extends React.Component {

		callApps = (method, opts) => {
			return Promise.resolve().then(async () => {
				const bearer = await this.props.api.createDelegationToken({
					audience: 'apps',
				});
				const api = new AthomAppsAPI();
				return api[method]({
					...opts,
					$headers: {
						Authorization: `Bearer ${bearer}`,
					}
				});
			});
		}

		render() {
			return <WrappedComponent
				callApps={this.callApps}
				{...this.props}
			/>
		}
	}
}

export function withMessages(WrappedComponent) {
	return class extends React.Component {

		handleWarning = warning => {
			if (this.props.enqueueSnackbar) {
				this.props.enqueueSnackbar(warning, {
					variant: 'warning',
				})
			} else {
				console.warn(warning);
			}
		}

		handleSuccess = message => {
			if (this.props.enqueueSnackbar) {
				this.props.enqueueSnackbar(message, {
					variant: 'success',
				})
			} else {
				console.log(message);
			}
		}

		handleError = err => {
			console.error('handleError', err);
			if (this.props.enqueueSnackbar) {
				this.props.enqueueSnackbar((err.cause && err.cause.error) || err.message || err.toString(), {
					variant: 'error',
				})
			}
		}

		render() {
			return <WrappedComponent
				handleSuccess={this.handleSuccess}
				handleError={this.handleError}
				handleWarning={this.handleWarning}
				{...this.props}
			/>
		}
	}
}

class AnyMessage extends React.Component {
	render() {
		return <div style={{
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 24,
			textAlign: 'center',
		}}>
			{this.props.children}<br />
			<Typography
				variant="subtitle1"
				color={this.props.color}
				style={{
					whiteSpace: 'pre',
				}}
			>{this.props.message}</Typography>

			{this.props.button && (
				<React.Fragment>
					<p></p>
					<p>
						{this.props.button}
					</p>
				</React.Fragment>
			)}
		</div>
	}
}

class ErrorMessage extends React.Component {
	render() {
		const {
			error,
		} = this.props;

		return <AnyMessage message={error.message || error.toString()} color="secondary">
			<ErrorIcon color="secondary" style={{
				width: 48,
				height: 48,
			}} />
		</AnyMessage>
	}
}

class LoadingMessage extends React.Component {
	render() {
		return <AnyMessage {...this.props}>
			<CircularProgress />
		</AnyMessage>
	}
}
