import './style.css';
import React, { Component } from 'react';
import {
  Button,
  TextField,
  Divider,
  Card,
  CardHeader,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  CircularProgress,
  IconButton, CardContent
} from '@material-ui/core';
import { ChevronRight, Info } from '@material-ui/icons';

class CharacteristicView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      writeValue: "[]",
      readValue: {},
      isDiscoveringDescriptors: false,
      isReading: false,
      isReadingDescriptor: false,
      isWriting: false,
      isSubscribing: false,
      isUnsubscribing: false,
    }
  }

  componentDidMount() {
    const { scrollRef } = this.props;
    scrollRef.scrollLeft = 9999;
  }

  render() {
    const { peripheral, service, runCommand, characteristic, selectDescriptor, selectedDescriptorUuid } = this.props;
    const { descriptors } = characteristic;

    const read = async () => {
      this.setState({ isReading: true });
      const result = await runCommand('READ', {
        peripheralUuid: peripheral.uuid,
        serviceUuid: service.uuid,
        characteristicUuid: characteristic.uuid
      })
        .catch(err => this.props.handleError(err))
        .finally(() => {
          this.setState({ isReading: false });
        });
      if (result) this.setState({ readValue: result, readModalOpen: true });
    }

    const write = data => {
      this.setState({ isWriting: true });
      runCommand('WRITE', {
        peripheralUuid: peripheral.uuid,
        serviceUuid: service.uuid,
        characteristicUuid: characteristic.uuid,
        data
      })
        .catch(err => this.props.handleError(err))
        .finally(() => {
          this.setState({ isWriting: false })
        });
    }

    const subscribe = () => {
      this.setState({ isSubscribing: true })

      runCommand('SUBSCRIBE', {
        peripheralUuid: peripheral.uuid,
        serviceUuid: service.uuid,
        characteristicUuid: characteristic.uuid
      })
        .catch(err => this.props.handleError(err))
        .finally(() => {
          this.setState({ isSubscribing: false })
        });

    }

    const unsubscribe = () => {
      this.setState({ isUnsubscribing: true })

      runCommand('UNSUBSCRIBE', {
        peripheralUuid: peripheral.uuid,
        serviceUuid: service.uuid,
        characteristicUuid: characteristic.uuid
      })
        .catch(err => this.props.handleError(err))
        .finally(() => {
          this.setState({ isUnsubscribing: false })
        });
    }

    const discoverDescriptors = () => {
      this.setState({ isDiscoveringDescriptors: true });
      runCommand('DISCOVER_DESCRIPTORS', {
        peripheralUuid: peripheral.uuid,
        serviceUuid: service.uuid,
        characteristicUuid: characteristic.uuid
      })
        .catch(err => this.props.handleError(err))
        .finally(() => {
          this.setState({ isDiscoveringDescriptors: false });
        });
    }

    const handleChange = (event) => {
      this.setState({ writeValue: event.target.value });
    }

    const closeReadModal = () => {
      this.setState({ readModalOpen: false });
    }

    const closeNotifyModal = () => {
      this.setState({ notificationModalOpen: false });
    }

    const closeWriteModal = () => {
      this.setState({ writeModalOpen: false });
    }

    if (characteristic) {
      const hasNotify = characteristic.properties.includes('notify') || characteristic.properties.includes('indicate');
      const hasRead = characteristic.properties.includes('read');
      const hasWrite = characteristic.properties.includes('write') || characteristic.properties.includes('writeWithoutResponse');

      return (
        <Card className="ble-card">
          <CardHeader title={<span> {characteristic.name || "unknown"} </span>} subheader="Characteristic" />
          <Divider />

          <CardContent>

            {/* Show a switch for subscribing/unsubscribing */}


            <div className="horizontal_buttons">
              <Button
                color={'primary'}
                variant={'contained'}
                disabled={this.state.isSubscribing || !hasNotify || characteristic.subscribedApp}
                onClick={subscribe}>
                {characteristic.subscribedApp ? 'Subscribed' : 'Subscribe'}
                {(this.state.isSubscribing) && <CircularProgress className="circularProgress" size={24} />}
              </Button>
              <Button

                color={'primary'}
                variant={'contained'}
                disabled={this.state.isSubscribing || !hasNotify || !characteristic.subscribedApp}
                onClick={unsubscribe}>
                {characteristic.subscribedApp ? 'Unsubscribe' : 'Unsubscribed'}
                {(this.state.isUnsubscribing) && <CircularProgress className="circularProgress" size={24} />}
              </Button>
            </div>


            {/* View notifications */}
            <Button
              variant={'contained'}
              style={{ marginBottom: 12 }}
              color={'primary'}
              fullWidth
              disabled={!hasNotify}
              onClick={() => {
                this.setState({ notificationModalOpen: true })
              }}>
              View notification stream
            </Button>

            <div className="horizontal_buttons">
              {/* Read */}
              <Button
                variant={'contained'}
                color={'primary'}
                disabled={!hasRead || this.state.isReading}
                button
                onClick={read}>
                Read
                {(this.state.isReading) && <CircularProgress className="circularProgress" size={24} />}
              </Button>

              {/* Write*/}
              <Button
                variant={'contained'}
                color={'primary'}
                disabled={!hasWrite}
                button
                onClick={() => {
                  this.setState({ writeModalOpen: true })
                }}>
                Write
              </Button>
            </div>

            <Button
              fullWidth
              variant={"contained"}
              disabled={this.state.isDiscoveringDescriptors}
              color="primary"
              onClick={discoverDescriptors}>
              Discover Descriptors
              {(this.state.isDiscoveringDescriptors) && <CircularProgress className="circularProgress" size={24} />}
            </Button>

          </CardContent>
          <Divider />

          <List subheader={<ListSubheader>Descriptors</ListSubheader>}>

            {descriptors.length ? descriptors.map(descriptor => {
              return (
                <ListItem
                  button
                  key={peripheral.uuid + service.uuid + characteristic.uuid + descriptor.uuid}
                  selected={descriptor.uuid === selectedDescriptorUuid}
                  onClick={() => {
                    selectDescriptor(descriptor)
                  }}
                >
                  <ListItemText
                    primary={<Typography> {descriptor.name || 'Vendor'}</Typography>}
                    secondary={<Typography> <code> {descriptor.uuid} </code> </Typography>}
                  />
                  <ChevronRight />
                </ListItem>
              );
            }) : (<ListItem> <ListItemText primary={<Typography> No descriptors </Typography>} /> </ListItem>)
            }
          </List>
          <Divider />

          {/* Details */}
          <List subheader={<ListSubheader>Details</ListSubheader>}>
            <ListItem>
              <ListItemText
                primary={<Typography> Uuid </Typography>}
                secondary={
                  characteristic.uuidIsVendorSpecific ? (
                    <Typography>
                      <code>
                        {characteristic.uuid}
                      </code>
                    </Typography>
                  ) : (
                    <Typography>
                      <code>0000</code>
                      <code style={{ color: "rgba(  0, 130, 250, 1.00)", fontWeight: 600 }}>{characteristic.uuid}</code>
                      <code>00001000800000805f9b34fb</code>
                    </Typography>
                  )} />
              {!characteristic.uuidIsVendorSpecific ? (
                <ListItemSecondaryAction>
                  <Tooltip
                    title={<Typography> Use 128bit (long) uuids in your app. 16-bit and
                      32-bit uuids are deprecated and support for them will be removed in the future. </Typography>}>
                    <IconButton aria-label="delete">
                      <Info />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          </List>

          <Dialog open={this.state.readModalOpen} onClose={closeReadModal} maxWidth={'xl'}>
            <DialogTitle>{"Read results"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Read results from characteristic {characteristic.uuid}.
              </DialogContentText>
              <Table>
                <TableHead>
                  <TableCell>Raw</TableCell>
                  <TableCell>Hex</TableCell>
                  <TableCell>String</TableCell>
                </TableHead>
                <TableBody>
                  <TableCell>
                    <code> {JSON.stringify(this.state.readValue.data)} </code>
                  </TableCell>
                  <TableCell>
                    <code> {(this.state.readValue && this.state.readValue.data) ? Buffer.from(this.state.readValue.data).toString('hex') : ''} </code>
                  </TableCell>
                  <TableCell>
                    <code> {(this.state.readValue && this.state.readValue.data) ? Buffer.from(this.state.readValue.data).toString() : ''} </code>
                  </TableCell>
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeReadModal} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.notificationModalOpen} onClose={closeNotifyModal} maxWidth={'xl'}>
            <DialogTitle>{"Notifications"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The stream will update automatically. Only a limited amount of notications will be displayed.
              </DialogContentText>
              {characteristic.notifications.length ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Timestamp</TableCell>
                      <TableCell>Decimal</TableCell>
                      <TableCell>Hexadecimal</TableCell>
                      <TableCell>String</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {characteristic.notifications.map(notification => {
                      const data = JSON.parse(notification.data);
                      const buffer = Buffer.from(data);
                      const bufferArray = [...buffer];
                      return (
                        <TableRow key={notification.timestamp}>
                          <TableCell>
                            <code> {new Date(notification.timestamp).toLocaleTimeString()} </code>
                          </TableCell>
                          <TableCell>
                            <code> {bufferArray.join(', ')} </code>
                          </TableCell>
                          <TableCell>
                            <code> {bufferArray.map(val => parseInt(val).toString(16)).join(' ')} </code>
                          </TableCell>
                          <TableCell>
                            <code> {buffer.toString()} </code>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Typography> No notifications have been received by this characteristic yet. </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeNotifyModal} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.writeModalOpen} onClose={closeWriteModal}>
            <DialogTitle>{"Writing data"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography> Use this to write data to Characteristic {characteristic.uuid}.
                  The expected format is <code>[value, value, ... ]</code> where <code> value </code> is a decimal
                  between 0 and 255. If you don't know what to write, then it may be useful to first read from the
                  characteristic to deduce an expected format. </Typography>
              </DialogContentText>
              <TextField id="standard-basic" label="Standard" value={this.state.writeValue} onChange={handleChange} />
              <Button variant="contained" color="primary"
                onClick={() => {
                  write(JSON.parse(this.state.writeValue))
                }}
                disabled={this.state.isWriting}
              > Write
                {(this.state.isWriting) && <CircularProgress className="circularProgress" size={24} />}
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeWriteModal} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      );
    }
    return null;
  }
}

export default CharacteristicView;
