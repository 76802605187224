import React, { Component } from 'react';
import CrossFrame from 'crossframe';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Card from '../Card';

import './style.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons'
import semver from "semver";

library.add(
  faMobileAlt
)

const RESOLUTIONS = [
  {
    name: 'iPhone 5/SE',
    width: 640,
    height: 1136,
    scale: 2,
  },
  {
    name: 'iPhone 6/7/8',
    width: 750,
    height: 1334,
    scale: 2,
  },
  {
    name: 'iPhone 6/7/8 Plus',
    width: 1080,
    height: 1920,
    scale: 3,
  },
  {
    name: 'iPhone X',
    width: 1125,
    height: 2436,
    scale: 3,
  },
  {
    name: 'Pixel 3',
    width: 1080,
    height: 2160,
    scale: 3,
  },
]

class Simulator extends Component {

  constructor(props) {
    super(props);

    this.state = {
      resolution: 0,
    }

    this._iframe = null;
    this._isSeamless = (props.homeySoftwareVersion && semver.gte(props.homeySoftwareVersion, '8.1.0')) || (props.homeyPlatform && props.homeyPlatform === 'cloud');
  }

  componentDidUpdate(prevProps) {
    if (this._iframe) {
      let newSrc = this.props.src || 'about:blank';

      if (newSrc !== prevProps.src) {
        this._iframe.removeEventListener('load', this.onIframeLoad);
        this._iframe.addEventListener('load', this.onIframeLoad);
        this._iframe.src = newSrc;
        this._iframeWrap.classList.toggle('Active', newSrc !== 'about:blank');
      }
    }
  }

  componentWillUnmount() {
    this._iframe = null;

    if (this._cf instanceof CrossFrame)
      this._cf.destroy();
  }

  render() {
    const { ready } = this.props;
    const { resolution } = this.state;
    const { width, height, scale } = RESOLUTIONS[resolution];

    return (
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Card
            title="Simulator"
            toolbar={(
              <Select
                value={this.state.resolution}
                onChange={event => {
                  this.setState({
                    resolution: event.target.value,
                  })
                }}>
                {RESOLUTIONS.map((resolution, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={i}
                    >
                      <FontAwesomeIcon icon="mobile-alt" size="1x" />
                      &nbsp;&nbsp;&nbsp;
                      {resolution.name} ({resolution.width}x{resolution.height})
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          >
          </Card>
          {this.props.controls}
        </Grid>

        <Grid item xs={8}>
          <div className={`Simulator ${ready ? 'Ready' : ''}`}>
            <div className="SimulatorSpeaker" />
            <div className="SimulatorInner" style={{
              width: parseInt(width / scale, 10),
              height: parseInt(height / scale, 10),
            }}>
              <div className="SimulatorHeader">
                <div className="SimulatorBack" onClick={this.onArrowBackClick}>
                  {this.props.backVisible && <CloseIcon
                    classes={{ root: "Icon" }}
                  />}
                </div>
                {this.props.appTitle &&
                  <div className="SimulatorAppTitle">
                    {this.props.appTitle}
                  </div>
                }
              </div>
              {!this._isSeamless &&
                <div className="SimulatorTitle">
                  <span className="Text">{this.props.title}</span>
                </div>
              }
              <div className="SimulatorIframeWrap"
                ref={(el) => {
                  this._iframeWrap = el;
                }}>
                <iframe
                  sandbox="allow-scripts allow-forms"
                  className={`SimulatorIframe ${this._isSeamless ? 'is-seamless' : ''}`}
                  title="pair"
                  src="about:blank"
                  ref={(el) => {
                    this._iframe = el;
                  }}
                ></iframe>
              </div>
              <div className="SimulatorContent">
                {this.props.children}
              </div>
            </div>
            <div className="SimulatorHomeButton" onClick={() => {
              alert('Homey is where the heart is ❤')
            }}></div>
          </div>
        </Grid>
      </Grid>
    )
  }

  onArrowBackClick = () => {
    this.props.onBack && this.props.onBack();
  }

  onIframeLoad = () => {

    if (this._cf instanceof CrossFrame)
      this._cf.destroy();

    this._cf = new CrossFrame(this._iframe);
    this._cf
      .on('alert', (data, callback) => {
        console.log(`Alert: ${data.text}`);
        window.alert(data.text);
        callback();
      })
      .on('confirm', (data, callback) => {
        let result = window.confirm(data.text);
        callback(null, result);
      })
      .on('popup', (data, callback) => {
        window.HYPopup(data.url, data.opts);
        callback(null);
      })
      .on('getDevmode', (data, callback) => {
        callback(null, true);
      })

    this.props.onLoad && this.props.onLoad(this._cf);

  }
}

export default Simulator;

