import React, {Component} from 'react';
import './style.css';


export class DocsNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState(prevState => ({
        menu: false
      }));
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }

  render() {
    return (
      <>
        <div ref={this.wrapperRef}  className={`DocsNav ${this.state.menu ? "is-DocsNav-active" : ""}`}>
          <button onClick={(event) => {
            this.toggleMenu(event);
          }} className="DocsNav__button" title="All documentation"><span className="DocsNav__button-icon"/></button>
          <nav className="DocsNav__menu">
            <div className="DocsNav__banner trim">
              <h2>Homey Developers</h2>
            </div>
            <div className="DocsNav__columns">
              <div className="DocsNav__column trim">
                <h3>Homey Apps SDK</h3>
                <ul className="DocsNav__list">
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://apps.developer.homey.app/">Documentation</a></li>
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://apps-sdk-v3.developer.homey.app/">Reference</a></li>
                </ul>
                <h3>Homey Web API</h3>
                <ul className="DocsNav__list">
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://api.developer.homey.app/">Documentation</a></li>
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://athombv.github.io/node-homey-api/">Reference</a></li>
                </ul>
                <h3>Tools</h3>
                <ul className="DocsNav__list">
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://tools.developer.homey.app/">Developer
                    Tools</a></li>
                </ul>
              </div>
              <div className="DocsNav__column trim">
                <h3>Node.js Modules</h3>
                <ul className="DocsNav__list">
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://athombv.github.io/node-homey-zigbeedriver/">Homey
                    ZigbeeDriver</a>
                  </li>
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://athombv.github.io/node-homey-zwavedriver/">Homey
                    ZWaveDriver</a>
                  </li>
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://athombv.github.io/node-homey-rfdriver/">Homey
                    RFDriver</a>
                  </li>
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://athombv.github.io/node-homey-log/">Homey
                    Log</a></li>
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://athombv.github.io/node-zigbee-clusters/">Zigbee
                    Clusters</a>
                  </li>
                  <li className="DocsNav__item"><a target="_blank" rel="noopener noreferrer"
                                                   href="https://athombv.github.io/node-homey-oauth2app/">Homey
                    OAuth2</a></li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </>
    );
  }
}
