import './style.css';
import React, { Component } from 'react';
import Page from '../Page';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
	withApi,
	withUser,
	withMessages,
} from '../../services/AthomApi';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import WarningIcon from '@material-ui/icons/Warning';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import AthomWebhooksAPI from 'homey-api/lib/AthomWebhooksAPI';
import TimeAgo from 'react-timeago';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import '../../prism-a11y-dark.css';

const styles = theme => ({
	leftIcon: {
		marginRight: theme.spacing(1),
	},
});

class PageWebhooks extends Component {

	constructor(...props) {
		super(...props);

		this.state = {
			webhooks: null,
			code: '',
			page: 0,
			rowsPerPage: 10,
			updateWebhook: null,
			matchingStrategy: 'queryParameter',
			isLegacy: false,
			lastRequestWebhook: null,
		};
	}

	componentDidMount() {
		this.refresh();
	}

	refresh = () => {
		this.call('getWebhooks').then(webhooks => {
			this.setState({ webhooks });
		}).catch(this.props.handleError);
	}

	async call(method, args = {}) {
		return this.props.api.createDelegationToken({
			audience: 'webhooks',
		}).then(async token => {
			const api = new AthomWebhooksAPI();
			return api[method]({
				...args,
				token,
			});
		});
	}

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	handleCreateWebhook = () => {
		this.call('createWebhook').then(webhook => {
			this.handleUpdateWebhookDialogOpen(webhook);
			this.props.handleSuccess('Webhook created');
			this.refresh();
		}).catch(this.props.handleError)
	}

	handleUpdateWebhookDialogOpen = webhook => {
		if (webhook.keyPath) {
			this.setState({ matchingStrategy: 'keyPath', isLegacy: false });
		}
		else if (webhook.fn) {
			this.setState({
				matchingStrategy: 'cloudFunction',
				isLegacy: true
			});
		}
		else {
			this.setState({ matchingStrategy: 'queryParameter', isLegacy: false });
		}

		this.setState({ updateWebhook: webhook });
	};

	handleUpdateWebhookDialogClose = () => {
		this.setState({ updateWebhook: null });
	};

	handleUpdateWebhook = updateWebhook => {
		const { webhooks } = this.state;
		const webhook = webhooks.find(webhook => {
			return webhook.id === updateWebhook.id;
		});

		switch (this.state.matchingStrategy) {
			case 'queryParameter':
				updateWebhook.keyPath = null;
				updateWebhook.fn = null;
				break;
			case 'cloudFunction':
				updateWebhook.keyPath = null;
				break;
			case 'keyPath':
				updateWebhook.fn = null;
				break;
			default:
				updateWebhook.keyPath = null;
				updateWebhook.fn = null;
		}

		if (webhook) {
			this.call('updateWebhook', {
				$validate: false,
				webhookId: updateWebhook.id,
				name: updateWebhook.name,
				fn: updateWebhook.fn,
				keyPath: updateWebhook.keyPath,
			}).then(() => {
				this.props.handleSuccess('Webhook saved');
				this.refresh();
			}).catch(this.props.handleError)
		}
		this.handleUpdateWebhookDialogClose();
	}

	handleDeleteWebhookDialogOpen = webhook => {
		this.setState({
			deleteWebhook: webhook,
		});
	}

	handleDeleteWebhookDialogClose = webhook => {
		this.setState({
			deleteWebhook: null,
		});
	}

	handleDeleteWebhook = deleteWebhook => {
		const { webhooks } = this.state;
		const webhook = webhooks.find(webhook => {
			return webhook.id === deleteWebhook.id;
		});

		if (webhook) {
			this.call('deleteWebhook', {
				webhookId: deleteWebhook.id,
			}).then(() => {
				this.props.handleSuccess('Webhook deleted');
				this.refresh();
			}).catch(this.props.handleError);
		}

		this.handleDeleteWebhookDialogClose();

	}

	handleWebhookLastRequestDialogOpen = webhook => {
		this.setState({
			lastRequestWebhook: webhook,
		});
	}

	handleWebhookLastRequestDialogClose = webhook => {
		this.setState({
			lastRequestWebhook: null,
		});
	}

	handleWebhookMatchingStrategyChange = event => {
		this.setState({
			matchingStrategy: event.target.value,
		})
	}

	render() {

		const {
			webhooks,
			page,
			rowsPerPage,
			updateWebhook,
			deleteWebhook,
			matchingStrategy,
			isLegacy,
			lastRequestWebhook,
		} = this.state;

		const {
			classes
		} = this.props;

		const rows = (webhooks || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(webhook => {
			const url = `https://webhooks.athom.com/webhook/${webhook.id}`;

			return (
				<TableRow key={webhook.id}>
					<TableCell>
						{webhook.enabled === false && (
							<WarningIcon
								style={{
									color: 'red',
									verticalAlign: 'middle',
									marginRight: 10,
								}}
							/>
						)}
						{webhook.name}
					</TableCell>
					<TableCell>
						<a target="_blank" rel="noopener noreferrer" href={url} style={{
							color: '#CCC',
						}}>{url}</a>
					</TableCell>
					<TableCell>{webhook.fired}</TableCell>
					<TableCell>
						{webhook.lastRequest ? (
							<div
								className="Link"
								onClick={() => this.handleWebhookLastRequestDialogOpen(webhook)}
							>
								<TimeAgo date={webhook.lastFired} />
							</div>
						) : (
							<TimeAgo date={webhook.lastFired} />
						)}
					</TableCell>
					<TableCell>
						<IconButton onClick={() => this.handleUpdateWebhookDialogOpen(webhook)}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={() => this.handleDeleteWebhookDialogOpen(webhook)}>
							<DeleteIcon />
						</IconButton>
					</TableCell>
				</TableRow>
			);
		});

		const webhookStyle = {
			fontFamily: '"Roboto Mono", monospace',
			fontSize: 12,
			outline: 'none',
		};

		const newWebhook = (
			<FormControl
				margin="normal"
			>
				<Button
					onClick={this.handleCreateWebhook}
					variant="contained"
					color="primary"
				>
					<AddBoxIcon className={classes.leftIcon} />
					New Webhook
				</Button>
			</FormControl>
		);

		return (
			<Page className="PageWebhooks" title="Webhooks">
				<Typography variant="body2">Homey Apps that integrate with a service that uses webhooks to communicate live events can use this service.<br />
					<a target="_blank" rel="noopener noreferrer" href="https://apps.developer.homey.app/cloud/webhooks">Read the documentation</a> on how to use them.</Typography>

				<Dialog
					open={!!lastRequestWebhook}
				>
					{lastRequestWebhook && (
						<React.Fragment>
							<DialogTitle>Last trigger</DialogTitle>
							<DialogContent>
								<Editor
									value={lastRequestWebhook.lastRequest}
									highlight={code => highlight(code, languages.js)}
									style={{
										...webhookStyle,
									}}
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleWebhookLastRequestDialogClose}>
									Close
								</Button>
							</DialogActions>
						</React.Fragment>
					)}
					{!lastRequestWebhook && <div />}
				</Dialog>

				<Dialog
					open={!!deleteWebhook}
				>
					{deleteWebhook && (
						<React.Fragment>
							<DialogTitle>Delete webhook</DialogTitle>
							<DialogContent>
								<Typography variant="body2">You are about to delete <strong>{deleteWebhook.name}</strong>.</Typography>
								<Typography variant="body1">If there are Homey Apps using this webhook, they might stop working.</Typography>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleDeleteWebhookDialogClose}>
									Cancel
								</Button>
								<Button onClick={() => {
									const deleteWebhook = this.state.deleteWebhook;
									this.handleDeleteWebhook(deleteWebhook);
								}} color="secondary">
									Delete
								</Button>
							</DialogActions>
						</React.Fragment>
					)}
					{!deleteWebhook && <div />}
				</Dialog>

				<Dialog
					open={!!updateWebhook}
					onClose={this.handleEditDialogClose}
				>
					{updateWebhook && (
						<React.Fragment>
							<DialogTitle>Edit webhook</DialogTitle>
							<DialogContent>

								{updateWebhook.enabled === false && (
									<div style={{
										textAlign: 'center',
									}}>
										<WarningIcon
											style={{
												color: 'red',
												verticalAlign: 'middle',
												marginRight: 10,
											}}
										/>
										<Typography style={{
											color: 'red',
										}} variant="body1">This webhook is disabled because it timed-out.<br />Edit your function to remove any loops and save it.</Typography>
									</div>
								)}

								<FormControl
									fullWidth={true}
									margin="normal"
								>
									<TextField
										label="Name"
										value={updateWebhook.name}
										onChange={(event) => {
											const updateWebhook = { ...this.state.updateWebhook };
											updateWebhook.name = event.target.value;
											this.setState({ updateWebhook });
										}}
										fullWidth
									/>
								</FormControl>

								<Grid container spacing={4}>
									<Grid item xs={6}>
										<FormControl
											fullWidth={true}
											margin="normal"
										>
											<TextField
												label="ID"
												value={updateWebhook.id}
												disabled
												fullWidth
											/>
										</FormControl>
									</Grid>

									<Grid item xs={6}>
										<FormControl
											fullWidth={true}
											margin="normal"
										>
											<TextField
												label="Secret"
												value={updateWebhook.secret}
												disabled
												fullWidth
											/>
										</FormControl>
									</Grid>
								</Grid>

								<FormControl
									component="fieldset"
									fullWidth={true}
									margin="normal">
									<FormLabel component="legend">Homey Matching Strategy</FormLabel>
									<RadioGroup aria-label="matchingStrategy" name="matchingStrategy" value={matchingStrategy} onChange={this.handleWebhookMatchingStrategyChange}>
										<FormControlLabel value="queryParameter" control={<Radio />} label="Query Parameter" />
										{matchingStrategy === 'queryParameter' &&
											<FormControl
												fullWidth={true}
												margin="dense">

												<Typography variant="body2">Register <strong>https://webhooks.athom.com/webhook/{updateWebhook.id}/?homey=HomeyID</strong> as webhook URL with your remote API. <a
													href={'https://apps.developer.homey.app/cloud/webhooks#option-1-dynamic-webhooks-using-query-parameters'}
													style={{ textDecoration: 'none' }}
													target="_blank"
													rel="noopener noreferrer">
													<Button
														color="primary"
														size="small">
														Documentation
														<NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
													</Button>
												</a>
												</Typography>
											</FormControl>
										}

										<FormControlLabel value="keyPath" control={<Radio />} label="Key Path" />
										{matchingStrategy === 'keyPath' &&
											<FormControl
												fullWidth={true}
												margin="dense">

												<Typography
													variant="body2"
													style={{ width: 500 }}><strong>$key</strong> in your app should match the Key Path of the webhook. <a
														href={'https://apps.developer.homey.app/cloud/webhooks#option-2-webhooks-using-key-path-properties'}
														style={{ textDecoration: 'none' }}
														target="_blank"
														rel="noopener noreferrer">
														<Button
															color="primary"
															size="small">
															Documentation
															<NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
														</Button>
													</a>
												</Typography>

												<Grid container spacing={4}>
													<Grid item xs={6}>
														<TextField
															label="Key Path"
															inputProps={{ style: { fontFamily: '"Roboto Mono", monospace', } }}
															value={updateWebhook.keyPath}
															onChange={(event) => {
																const updateWebhook = { ...this.state.updateWebhook };
																updateWebhook.keyPath = event.target.value;
																this.setState({ updateWebhook });
															}}
															placeholder="headers['x-user-id']"
															fullWidth />
													</Grid>
												</Grid>
											</FormControl>
										}

										<FormControlLabel value="cloudFunction" disabled={!isLegacy} control={<Radio />} label="Cloud Function (Legacy)" />

										{matchingStrategy === 'cloudFunction' &&
											<FormControl
												fullWidth={true}
												margin="dense">
												<Typography variant="body2">Cloud Functions are now considered legacy and have been made "read-only", consider changing to Query Parameter or Key Path based webhooks. <a
													href={'https://apps.developer.homey.app/cloud/webhooks#option-2-the-webhook-url-must-be-static'}
													style={{ textDecoration: 'none' }}
													target="_blank"
													rel="noopener noreferrer">
													<Button
														color="primary"
														size="small">
														Documentation
														<NavigateNextIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
													</Button>
												</a>
												</Typography>

												<FormHelperText
													filled={!!updateWebhook.fn}>Cloud function</FormHelperText>

												<Editor
													value="function( homey_data, webhook_data ){"
													highlight={code => highlight(code, languages.js)}
													padding={2}
													style={{
														...webhookStyle,
														opacity: 0.7,
													}}
												/>
												<Editor
													value={updateWebhook.fn}
													highlight={code => highlight(code, languages.js)}
													style={{
														...webhookStyle,
														marginLeft: 20,
														width: 500,
													}}
												/>
												<Editor
													value="}"
													highlight={code => highlight(code, languages.js)}
													padding={2}
													style={{
														...webhookStyle,
														opacity: 0.7,
													}}
												/>
											</FormControl>
										}
									</RadioGroup>
								</FormControl>


							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleUpdateWebhookDialogClose}>
									Cancel
								</Button>
								<Button onClick={() => {
									const updateWebhook = this.state.updateWebhook;
									this.handleUpdateWebhook(updateWebhook);
								}} color="primary">
									Save
								</Button>
							</DialogActions>
						</React.Fragment>
					)}
					{!updateWebhook && <div />}
				</Dialog>

				{webhooks === null && (
					<center style={{
						marginTop: 100,
					}}>
						<CircularProgress />
					</center>
				)}

				{webhooks && webhooks.length > 0 && (
					<React.Fragment>
						<div style={{
							overflowX: 'auto'
						}}>
							<Table>
								<TableHead>
									<TableRow>
										<TablePagination
											count={webhooks.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={this.handleChangePage}
											onRowsPerPageChange={this.handleChangeRowsPerPage}
										/>
									</TableRow>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>URL</TableCell>
										<TableCell>Trigger count</TableCell>
										<TableCell>Last trigger</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows}
								</TableBody>
							</Table>
						</div>
						{newWebhook}
					</React.Fragment>
				)}

				{webhooks && webhooks.length === 0 && (
					<center style={{
						marginTop: 100,
					}}>
						<Typography variant="h6">{`Let's create your first webhook!`}</Typography>
						{newWebhook}
					</center>
				)}
			</Page>
		);
	}
}

export default withSnackbar(withMessages(withApi(withUser(withStyles(styles)(withRouter(PageWebhooks))))));
